//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'throttle-debounce';
// import ElInput from 'element-ui/packages/input';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import UserChooserSuggestions from './userchooser-suggestions.vue';
import Emitter from 'element-ui/src/mixins/emitter';
import Focus from 'element-ui/src/mixins/focus';
import { generateId } from 'element-ui/src/utils/util';

import { search, match, normalValue } from './query'
import { loadJs, getCaretEnd } from './dom'
import { trim } from './utils'

const choosers = {
  'users': 'http://static.sng.local/js/userchooser/users.js'
}
const datas = {};

export default {
  name: 'UserChooser',

  mixins: [Emitter, Focus('input')],

  componentName: 'UserChooser',

  components: {
    // ElInput,
    UserChooserSuggestions
  },

  directives: { Clickoutside },

  props: {
    src: String,
    name: String,
    size: String,
    value: String,
    label: String,
    placeholder: String,
    popperClass: String,
    disabled: {
      type: Boolean,
      default: false
    },
    singleton: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'users'
    },
    delimiter: {
      type: String,
      default: ';'
    },
    limit: {
      type: Number,
      default: 7
    },
    debounce: {
      type: Number,
      default: 100
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loaded: false,
      innerDisabled: false,
      activated: false,
      innerValue: this.value || '',
      suggestions: [],
      highlightedIndex: 0,
      isOnComposition: false
    };
  },
  computed: {
    suggestionVisible () {
      const suggestions = this.suggestions;
      const isValidData = Array.isArray(suggestions) && suggestions.length > 0;
      return isValidData && this.activated;
    },
    id () {
      return `el-autocomplete-${generateId()}`;
    }
  },
  watch: {
    suggestionVisible (val) {
      this.broadcast('UserChooserSuggestions', 'visible', [val, this.$refs.input.$refs.input.offsetWidth]);
    },
    value (val) {
      this.innerValue = val;
    },
    disabled (val) {
      this.innerDisabled = val;
    }
  },
  methods: {
    initInnerData () {
      this.innerDisabled = this.disabled;
      this.innerValue = this.value;
    },
    // 获得输入框 dom
    input () {
      return this.$refs.input.$refs.input;
    },
    // 关闭建议窗口
    close (e) {
      if (!this.suggestionVisible) {
        return;
      }
      this.emitInput();
      this.suggestions = [];
      this.highlightedIndex = 0;
    },
    // 获得建议内容
    getData (queryString) {
      const word = this.findActiveWord(queryString);
      if (word && word.length > 0) {
        this.suggestions = search(this.data, word, this.limit);
      } else {
        this.suggestions = [];
      }
    },
    handleComposition (event) {
      if (event.type === 'compositionend') {
        this.isOnComposition = false;
        this.handleChange(event.target.value);
      } else {
        this.isOnComposition = true;
      }
    },
    handleChange (value) {
      if (this.isOnComposition || !value) {
        this.suggestions = [];
        return;
      }
      this.debouncedGetData(value);
    },
    // 获取焦点事件, 加载一次数据
    handleFocus (event) {
      if (this.loaded) {
        this.activated = true;
        this.$emit('focus', event);
        return;
      }
      // 加载全局缓存, 没有找到再去取数据
      // 注意组织架构数据 (this.data) 避免 vue 对 data 进行监听(数据量太大)
      let data = datas[this.type];
      if (typeof data === 'undefined') {
        data = window['_arr' + this.type]; // 尝试从 window 里获取
      }
      if (typeof data !== 'undefined') {
        this.data = data;
        this.loaded = true; // 已加载
        this.activated = true; // 已激活
        this.$emit('focus', event);
        return;
      }
      // 保存输入框的值
      const value = this.innerValue;
      // 加点特效
      this.innerDisabled = true; // 禁用输入
      this.innerValue = 'loading...';
      // 加载数据
      this.$nextTick(() => {
        loadJs(this.src || choosers[this.type], document.body, () => {
          datas[this.type] = window['_arr' + this.type] || [];
          this.data = datas[this.type];
          this.loaded = true; // 已加载
          this.innerDisabled = false; // 已可用
          this.activated = true; // 已激活
          this.insert(value, true);
          this.$emit('focus', event);
        });
      });
    },
    handleBlur () {
      // 没激活 或 建议窗口还没关闭(用户点击 item, 此时, 先触发 blur 再关窗口)
      if (!this.activated || this.suggestionVisible) {
        return;
      }
      this.emitInput();
    },
    handleKeyEnter (e) {
      if (this.suggestionVisible && this.highlightedIndex >= 0 && this.highlightedIndex < this.suggestions.length) {
        e.preventDefault();
        this.select(this.suggestions[this.highlightedIndex]);
      }
    },
    // 选择 item
    select (item) {
      // 单选, 覆盖当前值即可
      if (this.singleton) {
        this.insert(item[1] + this.delimiter);
      } else {
        this.insert(this.join(this.parseActiveWord(item[1]), o => o));
      }
      this.$nextTick(_ => {
        this.suggestions = [];
        this.highlightedIndex = 0;
      });
    },
    insert (value = '', focus = true) {
      // 有些情况 value === this.innerValue, 造成 value 不会触发改变
      this.innerValue = null;
      this.$nextTick(() => {
        this.innerValue = value;
        this.emitUpdate(value);
        if (focus) {
          this.$nextTick(() => {
            this.focus();
          });
        }
      });
    },
    /**
         *  暴露innerValue
         *  过滤空数组
         */
    emitUpdate (values) {
      this.$emit('update', (values.split(this.delimiter).filter(value => {
        return value.length > 0
      })));
    },
    // 触发输入
    emitInput () {
      const input = this.input();
      const value = input.value;
      const words = value.split(this.delimiter);
      // 找出当前文本框内的单词的匹配项, 去重 + 移除空值
      // 单选的情况永远取第一个值
      const items = this.parseMatchItems(this.singleton ? words.slice(0, 1) : words);
      // 替换原值 + 触发 input 事件
      this.insert(this.join(items, o => o[1]), false);
      this.$emit('input', this.join(items, o => o[0]));
    },
    // 查找激活的词
    findActiveWord (queryString) {
      let point = getCaretEnd(this.input());
      let value = queryString;
      let words = value.split(this.delimiter);
      let len = 0;
      let idx = 0;
      for (let i = 0; i < words.length; i++) {
        if (point >= len && (point <= len + words[i].length)) {
          idx = i;
        }
        len += words[i].length + 1;
      }
      let word = words[idx];
      return trim(word)
    },
    // 替换光标位置的单词, 并返回按 delimiter 分割的文本数组
    parseActiveWord (word) {
      // 如果当前光标位置有效, 则使用此位置
      let input = this.input();
      let point = getCaretEnd(input);
      let value = input.value;
      let words = value.split(this.delimiter);
      let items = [];
      let length = 0;
      for (let i = 0; i < words.length; i++) {
        if (point >= length && (point <= length + words[i].length)) {
          items.push(word);
        } else {
          items.push(words[i]);
        }
        length += words[i].length + 1;
      }
      return items;
    },
    join (values, format) {
      let value = values.map(format).join(this.delimiter);
      // if (value) {
      //   value += this.delimiter;
      // }
      return value;
    },
    // 根据单词数组, 从备选列表里找出所有匹配的"item"
    // 去重 + 移除不存在的值
    parseMatchItems (words) {
      let data = this.data || [];
      let word;
      let item;
      let items = [];
      let cache = {};
      for (let i = 0; i < words.length; i++) {
        word = trim(words[i]);
        if (!word || word.length <= 0) {
          continue;
        }
        word = normalValue(word);
        if (cache[word] === 1) {
          continue;
        }
        cache[word] = 1;

        item = match(data, word);
        if (item) {
          items.push(item);
        }
      }
      cache = null; // 移除缓存
      return items;
    },
    highlight (index) {
      if (!this.suggestionVisible || !this.activated) {
        return;
      }
      if (index < 0) {
        this.highlightedIndex = 0;
        return;
      }
      if (index >= this.suggestions.length) {
        index = this.suggestions.length - 1;
      }
      const suggestion = this.$refs.suggestions.$el.querySelector('.el-autocomplete-suggestion__wrap');
      const suggestionList = suggestion.querySelectorAll('.el-autocomplete-suggestion__list li');

      let highlightItem = suggestionList[index];
      let scrollTop = suggestion.scrollTop;
      let offsetTop = highlightItem.offsetTop;

      if (offsetTop + highlightItem.scrollHeight > (scrollTop + suggestion.clientHeight)) {
        suggestion.scrollTop += highlightItem.scrollHeight;
      }
      if (offsetTop < scrollTop) {
        suggestion.scrollTop -= highlightItem.scrollHeight;
      }
      this.highlightedIndex = index;
      this.$el.querySelector('.el-input__inner').setAttribute('aria-activedescendant', `${this.id}-item-${this.highlightedIndex}`);
    }
  },
  mounted () {
    this.initInnerData();
    this.debouncedGetData = debounce(this.debounce, (val) => {
      this.getData(val);
    });
    let $input = this.$el.querySelector('.el-input__inner');
    $input.setAttribute('role', 'textbox');
    $input.setAttribute('aria-autocomplete', 'list');
    $input.setAttribute('aria-controls', 'id');
    $input.setAttribute('aria-activedescendant', `${this.id}-item-${this.highlightedIndex}`);
  },
  beforeDestroy () {
    this.data = [];
    this.$refs.suggestions.$destroy();
  }
};
