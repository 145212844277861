//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DateTimeRangeGroup from 'src/modules/components/date-time-range-group';
import moment from 'moment';
import { getURIParam } from 'src/modules/utils/uri';
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export default {
  name: 'template-form',
  components: {
    DateTimeRangeGroup
  },
  props: {
    isQuery: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    configs: {
      type: Array,
      default () {
        return [];
      }
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    labelWidth: {
      type: String,
      default: '120px'
    }
  },
  data () {
    return {
      extraParams: {}
    }
  },
  methods: {
    registerParams () {
      // 为所有没有复合的props的字段注册监听
      this.configs.filter(({ props }) => {
        return !props
      }).forEach(({ prop }) => {
        this.params[prop] || this.$set(this.params, prop, undefined);
      });
      // 注册复合props,并添加对应关系
      this.configs.filter(({ props }) => {
        return props;
      }).forEach(({ prop, props }) => {
        props.forEach((prop) => {
          this.params[prop] || this.$set(this.params, prop, undefined);
        });
      });
      this.updateExtraParams();
    },
    resetParams () {
      this.configs.forEach(({ prop, props = [], defaultValue }) => {
        let keys = [...props, prop];
        keys.forEach((prop, index) => {
          // 优先获取路径参数
          const uriValue = getURIParam(prop);
          // 复合条件时挨个赋值defaultValue
          const value = uriValue || (Array.isArray(defaultValue) && props.length > 0 ? defaultValue[index] : defaultValue);
          // 忽略undefined的深拷贝
          this.$set(this.params, prop, value ? JSON.parse(JSON.stringify(value)) : undefined);
        });
      });
      this.updateExtraParams();
      this.$emit('reset');
      return this.filterParams();
    },
    updateExtraParams () {
      this.configs.filter(({ props }) => {
        return props;
      }).forEach(({ prop, props }) => {
        const results = props.map((prop) => {
          return this.params[prop];
        });
        this.$set(this.extraParams, prop, results);
      });
    },
    filterParams () {
      const params = JSON.parse(JSON.stringify(this.params));
      // 移除空数组或空字符串参数
      Object.keys(params).filter((key) => {
        return params[key].length === 0;
      }).forEach((key) => {
        params[key] = undefined;
        delete params[key];
      });
      return params;
    },
    submit () {
      this.$emit('before-submit');
      const params = this.filterParams();
      // 自动提示信息
      const requireds = this.configs.filter(({ required, prop, props }) => {
        return !this.isQuery && required && (props ? props.some((prop) => !this.params[prop]) : !this.params[prop]);
      });
      if (requireds.length === 0) {
        this.$emit('submit', params);
        return params;
      } else {
        const messages = requireds.map(({ label }) => `<p>${label}必填</p>`).join('\n');
        this.$alert(messages, {
          title: '以下参数必填',
          type: 'error',
          dangerouslyUseHTMLString: true
        });
      }
    },
    handleDateTimeRangePick (timeKey, [startTimeKey, endTimeKey], range) {
      let [startTime, endTime] = range || [undefined, undefined];
      // 将date格式转为字符串
      startTime = startTime && moment(startTime).format(DATE_FORMAT);
      endTime = endTime && moment(endTime).format(DATE_FORMAT);
      this.$set(this.params, startTimeKey, startTime);
      this.$set(this.params, endTimeKey, endTime);
      this.$set(this.extraParams, timeKey, [startTime, endTime]);
    }
  },
  created () {
    this.registerParams();
  }
}
