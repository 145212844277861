var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          attrs: { inline: "", model: _vm.form },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品名称：", prop: "product_name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入产品名称" },
                model: {
                  value: _vm.form.product_name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "product_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.product_name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: {
                    click: function($event) {
                      return _vm.query()
                    }
                  }
                },
                [_vm._v("查 询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-search" },
                  on: {
                    click: function($event) {
                      return _vm.clear()
                    }
                  }
                },
                [_vm._v("重 置")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }