var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "title-bar" },
    [
      _c("div", { staticClass: "icon-left" }, [
        _c("span", {
          class: ["el-left-trapezia", _vm.statusFigureClass[_vm.info.status]]
        }),
        _vm._v(" "),
        _c("i", { class: [_vm.statusIconClass[_vm.info.status]] })
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "content-center" },
        [
          _c("el-col", { attrs: { span: 6 } }, [_vm._v(_vm._s(_vm.info.name))]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "light",
                    content: _vm.info.owner,
                    placement: "top"
                  }
                },
                [_c("span", [_vm._v(_vm._s(_vm.SplitStr(_vm.info.owner)))])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 3 } }, [
            _vm._v("处理人：" + _vm._s(_vm.info.creator))
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.info.finish_time))
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v("耗时：" + _vm._s(_vm.info.time_cost))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }