var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { attrs: { header: _vm.title } },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "数据文件", prop: "fileList" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "distant",
                      attrs: { type: "text" },
                      on: { click: _vm.handleDownloadTemplate }
                    },
                    [_vm._v("\n          [模版下载]\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "/deliver/tiantu/fileUpload",
                        limit: 1,
                        "file-list": _vm.ruleForm.fileList,
                        headers: { Accept: "application/json" },
                        accept: ".xlsx,.xls,.csv",
                        name: "uploadFile",
                        "on-success": _vm.handleFileSuccess
                      }
                    },
                    [
                      _c("el-button", { attrs: { size: "small" } }, [
                        _vm._v("文件上传")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.hideTapd
                ? _c(
                    "el-form-item",
                    { attrs: { label: "tapd链接单", prop: "tapdUrl" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.tapdUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "tapdUrl", $$v)
                          },
                          expression: "ruleForm.tapdUrl"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.needDate
                ? _c(
                    "el-form-item",
                    { attrs: { label: "日期", prop: "date" } },
                    [
                      _c("el-date-picker", {
                        attrs: { type: _vm.dateType, placeholder: "选择日期" },
                        model: {
                          value: _vm.ruleForm.date,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "date", $$v)
                          },
                          expression: "ruleForm.date"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("提交")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.resetForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "datafix-operator-panel",
          attrs: { header: "操作记录" }
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.logs } },
            [
              _c("el-table-column", {
                attrs: { label: "操作时间", prop: "op_time", width: "200" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作人", prop: "op_user_id", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(row.op_user_id) +
                              "(" +
                              _vm._s(row.op_user_name) +
                              ")"
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              !_vm.hideTapd
                ? _c("el-table-column", {
                    attrs: {
                      label: "tapd需求单",
                      prop: "tapd_url",
                      width: "200"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              row.tapd_url
                                ? _c("span", [_vm._v(_vm._s(row.tapd_url))])
                                : _c("span", [_vm._v("--")])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3184244055
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Excel链接",
                  prop: "upload_file_info",
                  width: "200"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.upload_file_info.file_url
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  href: row.upload_file_info.file_url,
                                  type: "primary"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(row.upload_file_info.realfile_name)
                                )
                              ]
                            )
                          : _c("span", [_vm._v("--")])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "执行详情", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleViewDetail(row)
                              }
                            }
                          },
                          [_vm._v("\n            查看详情\n          ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remarks", width: "200" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              layout: "total, sizes, prev, pager, next, jumper",
              "page-sizes": [15, 30, 50, 100],
              total: _vm.params.total,
              "page-size": _vm.params.limit,
              "current-page": _vm.params.pageNum
            },
            on: {
              "size-change": _vm.changeSize,
              "current-change": _vm.changPage
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }