"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty2 = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty2 = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/slicedToArray"));

var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _some = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/some"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/toConsumableArray"));

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _column = _interopRequireDefault(require("./column"));

var _extend = _interopRequireDefault(require("../methods/extend"));

var _staffSelect = _interopRequireDefault(require("src/modules/components/staff-select"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty2(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context28; _forEachInstanceProperty2(_context28 = ownKeys(Object(source), true)).call(_context28, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context29; _forEachInstanceProperty2(_context29 = ownKeys(Object(source))).call(_context29, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'ElTableMain',
  components: {
    StaffSelect: _staffSelect["default"]
  },
  props: {
    attributes: Object,
    tableAttributes: Object,
    tableColumnAttributes: Array,
    actionColumnAttributes: Object,
    paginationAttributes: Object,
    url: {
      type: Function
    }
  },
  data: function data() {
    var _this = this;

    var filterModels = function filterModels() {
      var _context;

      return (0, _map["default"])(_context = _this.attributes.filters).call(_context, function (_ref) {
        var components = _ref.components;

        if (components && (0, _isArray["default"])(components)) {
          return (0, _reduce["default"])(components).call(components, function (object, component) {
            object[component.prop] = null;
            return object;
          }, {});
        } else {
          return {};
        }
      });
    };

    return {
      loading: false,
      popover: {},
      filterModels: filterModels(),
      extraButtonGroups: [],
      defaultButtonGroups: [{
        name: 'edit',
        icon: 'el-icon-edit',
        title: '编辑',
        hidden: false,
        click: function click() {
          for (var _len = arguments.length, scope = new Array(_len), _key = 0; _key < _len; _key++) {
            scope[_key] = arguments[_key];
          }

          return _this.handleEdit(scope);
        }
      }, {
        name: 'delete',
        icon: 'el-icon-delete',
        title: '删除',
        tips: '您确定删除所选记录吗?',
        hidden: false,
        click: function click() {
          for (var _len2 = arguments.length, scope = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            scope[_key2] = arguments[_key2];
          }

          return _this.handleDelete(scope);
        }
      }, {
        name: 'view',
        icon: 'el-icon-info',
        title: '查看',
        hidden: false,
        click: function click() {
          for (var _len3 = arguments.length, scope = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
            scope[_key3] = arguments[_key3];
          }

          return _this.handleView(scope);
        }
      }],
      extraNavButtonGroups: [],
      defaultNavButtonGroups: [{
        name: 'add',
        icon: 'el-icon-plus',
        title: '新增',
        hidden: false,
        click: function click() {
          return _this.handleAdd();
        }
      }, {
        name: 'refresh',
        icon: 'el-icon-refresh',
        title: '刷新',
        hidden: false,
        click: function click() {
          return _this.handleRefresh();
        }
      }, {
        name: 'control',
        icon: 'el-icon-setting',
        title: '配置',
        hidden: false,
        click: function click() {
          return _this.handleControl();
        }
      }]
    };
  },
  computed: {
    buttonGroups: function buttonGroups() {
      var _context2;

      return (0, _concat["default"])(_context2 = []).call(_context2, (0, _toConsumableArray2["default"])(this.defaultButtonGroups), (0, _toConsumableArray2["default"])(this.extraButtonGroups));
    },
    navButtonGroups: function navButtonGroups() {
      var _context3;

      return (0, _concat["default"])(_context3 = []).call(_context3, (0, _toConsumableArray2["default"])(this.defaultNavButtonGroups), (0, _toConsumableArray2["default"])(this.extraNavButtonGroups));
    },
    isElThead: function isElThead() {
      var _context4;

      return (0, _some["default"])(_context4 = this.navButtonGroups).call(_context4, function (s) {
        return !s.hidden;
      });
    },
    isElPagination: function isElPagination() {
      var data = this.tableAttributes.data;
      return this.attributes.pagination && (0, _isArray["default"])(data) && data.length;
    }
  },
  methods: {
    executeOptions: function executeOptions() {
      var _context5,
          _this2 = this;

      (0, _forEach["default"])(_context5 = (0, _entries["default"])(this.attributes)).call(_context5, function (_ref2) {
        var _ref3 = (0, _slicedToArray2["default"])(_ref2, 2),
            key = _ref3[0],
            option = _ref3[1];

        return _this2.handleOptions(key, option);
      });
    },
    handleAdd: function handleAdd() {},
    handleButtonRender: function handleButtonRender(button, scope) {
      return typeof button.hidden === 'function' ? button.hidden(scope.row, scope.column, scope.$index, scope.store, scope._self) : !button.hidden;
    },
    handleDelete: function handleDelete(_ref4) {
      var _ref5 = (0, _slicedToArray2["default"])(_ref4, 5),
          row = _ref5[0],
          column = _ref5[1],
          index = _ref5[2],
          store = _ref5[3],
          _self = _ref5[4];

      console.log(row, column, index, store, _self);
    },
    handleEdit: function handleEdit(_ref6) {
      var _ref7 = (0, _slicedToArray2["default"])(_ref6, 5),
          row = _ref7[0],
          column = _ref7[1],
          index = _ref7[2],
          store = _ref7[3],
          _self = _ref7[4];

      console.log(row, column, index, store, _self);
    },
    handleRefresh: function handleRefresh() {
      this.handleHttpRequest();
    },
    handleControl: function handleControl() {
      var _context6,
          _context7,
          _this3 = this;

      var columnsUnique = {};
      var h = this.$createElement;
      var message = h('div', [h('el-alert', {
        props: {
          type: 'info',
          // effect: 'dark',
          showIcon: true,
          closable: false,
          title: '请选择您想显示的列表详细信息'
        }
      }), h('el-row', {
        style: {
          marginTop: '10px',
          padding: '0 10px'
        }
      }, (0, _map["default"])(_context6 = (0, _filter["default"])(_context7 = this.tableColumnAttributes).call(_context7, function (item) {
        return item.type !== 'expand';
      })).call(_context6, function (column) {
        var label = column.label,
            prop = column.prop,
            _column$hidden = column.hidden,
            hidden = _column$hidden === void 0 ? false : _column$hidden,
            _column$disabled = column.disabled,
            disabled = _column$disabled === void 0 ? false : _column$disabled;
        columnsUnique[prop] = hidden;
        return h('el-col', {
          props: {
            span: 8
          }
        }, [h('el-checkbox', {
          props: {
            label: label,
            disabled: disabled,
            checked: !hidden
          },
          style: {
            marginBottom: '10px'
          },
          on: {
            change: function change(e) {
              columnsUnique[prop] = !e;
            }
          }
        }, label)]);
      }))]);
      this.$msgbox({
        title: '自定义列表字段',
        message: message,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'control-wrapper set-max-height',
        beforeClose: function beforeClose(action, instance, done) {
          if (action === 'confirm') {
            _this3.$emit('updateColumns', columnsUnique);

            done();
          } else {
            done();
          }
        }
      }).then(function (action) {
        _this3.$message.success('设置成功');
      });
    },
    handleView: function handleView(_ref8) {
      var _ref9 = (0, _slicedToArray2["default"])(_ref8, 5),
          row = _ref9[0],
          column = _ref9[1],
          index = _ref9[2],
          store = _ref9[3],
          _self = _ref9[4];

      console.log(row, column, index, store, _self);
    },
    handleOptions: function handleOptions(key, option) {
      switch (key) {
        case 'actionColumn':
          this['handleOptionsActionColumn'](option);
          break;

        case 'filters':
          this['handleOptionsFilters'](option);
          break;

        case 'nav':
          this['handleOptionsNav'](option);
          break;
      }
    },
    handleOptionsActionColumn: function handleOptionsActionColumn(actionColumnOptions) {
      var _context8,
          _this4 = this;

      var extraButtons = actionColumnOptions.extraButtons;
      (0, _forEach["default"])(_context8 = this.defaultButtonGroups).call(_context8, function (item) {
        if (actionColumnOptions[item.name] === false) {
          var _context9;

          (0, _find["default"])(_context9 = _this4.defaultButtonGroups).call(_context9, function (tar) {
            return tar.name === item.name;
          }).hidden = true;
        }
      });

      if (extraButtons) {
        // @Override
        (0, _map["default"])(extraButtons).call(extraButtons, function (el) {
          var _context10;

          var defaultButton = (0, _find["default"])(_context10 = _this4.defaultButtonGroups).call(_context10, function (tar) {
            return tar.name === el.name;
          });

          if (defaultButton && defaultButton.name && defaultButton.name === el.name) {
            (0, _extend["default"])(true, defaultButton, el);
          } else {
            _this4.extraButtonGroups.push(el);
          }
        });
      } else {// todo ...
      }
    },
    handleOptionsNav: function handleOptionsNav(navOptions) {
      var _context11,
          _this5 = this;

      var extraButtons = navOptions.extraButtons;
      (0, _forEach["default"])(_context11 = this.defaultNavButtonGroups).call(_context11, function (item) {
        if (navOptions[item.name] === false) {
          var _context12;

          (0, _find["default"])(_context12 = _this5.defaultNavButtonGroups).call(_context12, function (tar) {
            return tar.name === item.name;
          }).hidden = true;
        }
      });

      if (extraButtons) {
        // @Override
        (0, _map["default"])(extraButtons).call(extraButtons, function (el) {
          var _context13;

          var defaultButton = (0, _find["default"])(_context13 = _this5.defaultNavButtonGroups).call(_context13, function (tar) {
            return tar.name === el.name;
          });

          if (defaultButton && defaultButton.name && defaultButton.name === el.name) {
            (0, _extend["default"])(true, defaultButton, el);
          } else {
            _this5.extraNavButtonGroups.push(el);
          }
        });
      } else {// todo ...
      }
    },
    handleOptionsFilters: function handleOptionsFilters(filters) {
      if ((0, _some["default"])(filters).call(filters, function (filter) {
        return filter.defaultRender;
      })) {
        this.handleHttpRequest();
      }
    },
    handleHttpRequest: function handleHttpRequest(onSearch, onSearchIndex) {
      var _this6 = this;

      var _this$attributes = this.attributes,
          extraParams = _this$attributes.extraParams,
          beforeRequest = _this$attributes.beforeRequest,
          loadComplete = _this$attributes.loadComplete,
          loading = _this$attributes.loading;
      var params = (0, _assign["default"])({}, extraParams, this.filterModels[onSearchIndex >>> 0]);

      if (onSearch) {
        this.$emit('paginationChange', {
          currentPage: 1
        });

        if (typeof onSearch === 'function') {
          params = onSearch((0, _assign["default"])({}, params)) || params;
        }
      }

      if (beforeRequest && typeof beforeRequest === 'function') {
        params = beforeRequest((0, _assign["default"])({}, params)) || params;
      }

      this.$nextTick(function (_) {
        var _Object$assign2, _context14;

        var _this6$paginationAttr = _this6.paginationAttributes,
            pageSize = _this6$paginationAttr.pageSize,
            currentPage = _this6$paginationAttr.currentPage,
            _this6$paginationAttr2 = _this6$paginationAttr.pageName,
            pageName = _this6$paginationAttr2 === void 0 ? 'page' : _this6$paginationAttr2,
            _this6$paginationAttr3 = _this6$paginationAttr.limitName,
            limitName = _this6$paginationAttr3 === void 0 ? 'limit' : _this6$paginationAttr3;
        params = (0, _assign["default"])({}, params, (_Object$assign2 = {}, (0, _defineProperty2["default"])(_Object$assign2, pageName, currentPage), (0, _defineProperty2["default"])(_Object$assign2, limitName, pageSize), _Object$assign2));
        params = (0, _reduce["default"])(_context14 = (0, _entries["default"])(params)).call(_context14, function (object, _ref10) {
          var _ref11 = (0, _slicedToArray2["default"])(_ref10, 2),
              key = _ref11[0],
              value = _ref11[1];

          value !== null && (object[key] = value);
          return object;
        }, {}); // params = type.toLocaleUpperCase() === 'GET' ? { params } : params;

        if (typeof _this6.url === 'function') {
          _this6.loading = loading;

          _this6.url({
            query: params
          }).then(function (response) {
            _this6.loading = false;
            loadComplete && loadComplete(response);
            var _response$data$data = response.data.data,
                _response$data$data$d = _response$data$data.data,
                data = _response$data$data$d === void 0 ? [] : _response$data$data$d,
                _response$data$data$t = _response$data$data.total,
                total = _response$data$data$t === void 0 ? 0 : _response$data$data$t;

            _this6.$emit('httpChange', {
              defaultTableOptions: {
                data: data
              },
              defaultPaginationOptions: {
                total: total
              }
            });
          });
        }
      });
    },
    handleSizeChange: function handleSizeChange(pageSize) {
      this.$emit('paginationChange', {
        pageSize: pageSize
      });
      this.handleHttpRequest();
    },
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.$emit('paginationChange', {
        currentPage: currentPage
      });
      this.handleHttpRequest();
    }
  },
  render: function render(h) {
    var _context15,
        _context16,
        _this7 = this,
        _context20,
        _context21;

    var ElSearchForm = (0, _map["default"])(_context15 = (0, _filter["default"])(_context16 = this.attributes.filters).call(_context16, function (_ref12) {
      var components = _ref12.components;
      return components && (0, _isArray["default"])(components) && components.length;
    })).call(_context15, function (filter, index) {
      var _context17, _context18;

      var canClearSearch = filter.canClearSearch,
          labelWidth = filter.labelWidth,
          labelPosition = filter.labelPosition,
          components = filter.components,
          searchButton = filter.searchButton;
      return h('el-form', {
        ref: 'filterPanel',
        refInFor: true,
        props: {
          model: _this7.filterModels[index],
          inline: true,
          labelWidth: labelWidth,
          labelPosition: labelPosition,
          size: 'mini'
        }
      }, (0, _concat["default"])(_context17 = (0, _map["default"])(_context18 = (0, _filter["default"])(components).call(components, function (f) {
        return f.prop && f.label;
      })).call(_context18, function (component) {
        var prop = component.prop,
            label = component.label,
            labelWidth = component.labelWidth,
            placeholder = component.placeholder,
            type = component.type,
            attributes = component.attributes,
            _component$optionAttr = component.optionAttributes,
            optionAttributes = _component$optionAttr === void 0 ? [] : _component$optionAttr;

        var defaultProps = _objectSpread({
          value: _this7.filterModels[index][prop],
          placeholder: placeholder
        }, attributes);

        var defaultStyle = {
          width: '200px'
        };
        return h('el-form-item', {
          props: {
            prop: prop,
            label: label,
            labelWidth: labelWidth
          }
        }, [function (_) {
          if (type === 'select') {
            var _context19;

            // 多选情况下 删除'全部'选项
            var _defaultProps$multipl = defaultProps.multiple,
                multiple = _defaultProps$multipl === void 0 ? false : _defaultProps$multipl;
            var selectAll = null;

            if (!multiple) {
              selectAll = h('el-option', {
                props: {
                  label: '全部',
                  value: null
                }
              });
            }

            return h('el-select', {
              style: defaultStyle,
              props: defaultProps,
              on: {
                input: function input(value) {
                  _this7.filterModels[index][prop] = value;
                }
              }
            }, (0, _concat["default"])(_context19 = [selectAll]).call(_context19, (0, _map["default"])(optionAttributes).call(optionAttributes, function (props, index) {
              return h('el-option', {
                props: props
              });
            })));
          } else if (type === 'datepicker') {
            return h('el-date-picker', {
              props: defaultProps,
              style: defaultStyle,
              on: {
                input: function input(value) {
                  _this7.filterModels[index][prop] = value;
                }
              }
            });
          } else if (type === 'cascader') {
            return h('el-cascader', {
              style: defaultStyle,
              props: _objectSpread({
                expandTrigger: 'hover',
                changeOnSelect: true,
                filterable: true
              }, defaultProps),
              on: {
                input: function input(value) {
                  _this7.filterModels[index][prop] = value;
                }
              }
            });
          } else if (type === 'staff') {
            return h('staff-select', {
              style: defaultStyle,
              props: defaultProps,
              on: {
                input: function input(value) {
                  _this7.filterModels[index][prop] = value;
                }
              }
            });
          } else {
            return h('el-input', {
              attrs: {
                placeholder: defaultProps.placeholder || label
              },
              style: defaultStyle,
              props: defaultProps,
              on: {
                input: function input(value) {
                  _this7.filterModels[index][prop] = value;
                }
              }
            });
          }
        }()]);
      })).call(_context17, [h('el-form-item', [h('el-button', {
        props: {
          disabled: _this7.loading,
          icon: 'el-icon-search',
          size: 'mini',
          type: 'primary'
        },
        on: {
          click: function click() {
            _this7.handleHttpRequest(searchButton.onSearch || true, index);
          }
        }
      }, searchButton.text), canClearSearch && h('el-button', {
        props: {
          disabled: _this7.loading,
          icon: 'el-icon-zoom-out',
          size: 'mini',
          type: 'primary'
        },
        on: {
          click: function click() {
            var filters = _this7.$refs['filterPanel'];
            var filterPanel = filters[index];
            filterPanel.resetFields();

            _this7.$message('查询条件已清除');
          }
        }
      }, '重置')])]));
    });
    var ElThead = h('el-form', {
      style: {
        height: '45px',
        padding: '0 10px',
        'margin-right': '2px',
        display: 'flex',
        'align-items': 'center',
        'box-sizing': 'border-box',
        border: '1px solid #ebeef5',
        'background-color': '#f7f7f7'
      },
      props: {
        size: 'mini',
        inline: true
      }
    }, (0, _map["default"])(_context20 = (0, _filter["default"])(_context21 = this.navButtonGroups).call(_context21, function (_ref13) {
      var hidden = _ref13.hidden;
      return !hidden;
    })).call(_context20, function (button, index) {
      var _button$attributes = button.attributes,
          attributes = _button$attributes === void 0 ? {} : _button$attributes,
          _button$hiddenTooltip = button.hiddenTooltip,
          hiddenTooltip = _button$hiddenTooltip === void 0 ? false : _button$hiddenTooltip;
      return h('el-form-item', {
        key: index,
        style: {
          'margin-bottom': '0'
        }
      }, [h('el-tooltip', {
        props: {
          content: button.title,
          placement: 'top',
          disabled: hiddenTooltip
        }
      }, [h('el-button', {
        props: _objectSpread({
          icon: button.icon
        }, attributes),
        style: {
          'border-style': 'dashed'
        },
        on: {
          click: function click() {
            var _context22, _context23;

            var extraParams = _this7.attributes.extraParams;
            var _this7$paginationAttr = _this7.paginationAttributes,
                pageSize = _this7$paginationAttr.pageSize,
                currentPage = _this7$paginationAttr.currentPage;
            var params = (0, _assign["default"])({}, extraParams);
            (0, _forEach["default"])(_context22 = _this7.filterModels).call(_context22, function (item) {
              (0, _assign["default"])(params, item);
            });
            params = (0, _reduce["default"])(_context23 = (0, _entries["default"])(params)).call(_context23, function (object, _ref14) {
              var _ref15 = (0, _slicedToArray2["default"])(_ref14, 2),
                  key = _ref15[0],
                  value = _ref15[1];

              value !== null && (object[key] = value);
              return object;
            }, {});
            button.click(params, {
              pageSize: pageSize,
              currentPage: currentPage
            });
          }
        }
      }, button.title)])]);
    }));
    var ElTable = h('el-table', {
      ref: 'table',
      props: this.tableAttributes,
      on: this.$listeners,
      style: {
        width: '100%',
        'margin-bottom': '20px'
      }
    }, [h(_column["default"], {
      props: {
        columns: this.tableColumnAttributes
      }
    }), !this.actionColumnAttributes.hidden && h('el-table-column', {
      props: this.actionColumnAttributes,
      scopedSlots: {
        "default": function _default(scope) {
          var _context24, _context25;

          return (0, _map["default"])(_context24 = (0, _filter["default"])(_context25 = _this7.buttonGroups).call(_context25, function (button) {
            return _this7.handleButtonRender(button, scope);
          })).call(_context24, function (_ref16, index) {
            var name = _ref16.name,
                _ref16$icon = _ref16.icon,
                icon = _ref16$icon === void 0 ? '' : _ref16$icon,
                title = _ref16.title,
                _click = _ref16.click,
                _ref16$tips = _ref16.tips,
                tips = _ref16$tips === void 0 ? '' : _ref16$tips,
                _ref16$attributes = _ref16.attributes,
                attributes = _ref16$attributes === void 0 ? {} : _ref16$attributes;
            var $index = scope.$index,
                column = scope.column,
                row = scope.row,
                store = scope.store,
                _self = scope._self;
            var buttonIndex = name + $index;
            var buttonStyle = {
              'margin-right': '10px'
            };

            if (typeof attributes === 'function') {
              attributes = attributes(row, column, $index, store, _self);
            }

            if (tips) {
              var _context26, _context27;

              return h('el-tooltip', {
                key: (0, _concat["default"])(_context26 = (0, _concat["default"])(_context27 = "".concat($index)).call(_context27, index)).call(_context26, name),
                props: {
                  content: title,
                  placement: 'top'
                }
              }, [h('el-popover', {
                props: {
                  value: _this7.popover[buttonIndex],
                  trigger: 'manual',
                  placement: 'top',
                  width: '180'
                }
              }, [h('p', tips), h('div', {
                style: {
                  margin: '0',
                  'text-align': 'right'
                }
              }, [h('el-button', {
                props: {
                  size: 'mini',
                  type: 'text'
                },
                on: {
                  click: function click() {
                    _this7.popover = {};
                  }
                }
              }, '取消'), h('el-button', {
                props: {
                  size: 'mini',
                  type: 'primary'
                },
                on: {
                  click: function click() {
                    _click(row, column, $index, store, _self);

                    _this7.popover = {};
                  }
                }
              }, '确定')]), h('el-button', {
                slot: 'reference',
                props: _objectSpread({
                  size: 'mini',
                  icon: icon,
                  type: 'text'
                }, attributes),
                style: buttonStyle,
                on: {
                  click: function click() {
                    _this7.popover = {};

                    _this7.$set(_this7.popover, buttonIndex, true);
                  }
                }
              }, title)])]);
            } else {
              return h('el-button', {
                props: _objectSpread({
                  size: 'mini',
                  icon: icon,
                  type: 'text'
                }, attributes),
                style: buttonStyle,
                on: {
                  click: function click() {
                    _click(row, column, $index, store, _self);
                  }
                }
              }, title);
            }
          });
        }
      }
    })]);
    var ElPagination = h('el-pagination', {
      "class": 'el-pagination--large',
      props: this.paginationAttributes,
      on: {
        'size-change': this.handleSizeChange,
        'current-change': this.handleCurrentChange
      }
    });
    return h(this.attributes.container ? this.attributes.container : 'div', {
      directives: [{
        name: 'loading',
        value: this.loading
      }]
    }, [ElSearchForm, this.isElThead && ElThead, ElTable, this.paginationAttributes.total !== 0 && this.isElPagination && ElPagination]);
  },
  created: function created() {
    this.executeOptions();
  }
};
exports["default"] = _default2;