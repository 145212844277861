var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.convert([_vm.result], false, true),
            border: "",
            stripe: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "_field", label: "字段名", "min-width": "200" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "_before", label: "变更前", "min-width": "250" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "_change", label: "变更后", "min-width": "250" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }