"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _default = {
  methods: {
    _flat: function _flat(arr) {
      var _this = this;

      return (0, _reduce["default"])(arr).call(arr, function (iter, item) {
        if (item.children) {
          iter = (0, _concat["default"])(iter).call(iter, _this._flat(item.children));
          delete item.children;
          iter.push(item);
          return iter;
        } else {
          iter.push(item);
          return iter;
        }
      }, []);
    },
    personFormatter: function personFormatter(params) {
      var status = params.status;
      var personone = params.approval_personone;
      var persontwo = params.approval_persontwo;
      var personthird = params.approval_personthird;
      var personfour = params.approval_personfour; // 改版之后不会有 400、500的状态了，这里保留是为了兼容老数据

      if (status === 200 || status === 201) {
        return personone;
      } else if (status === 300 || status === 301) {
        return persontwo;
      } else if (status === 400 || status === 401) {
        return personthird;
      } else if (status === 500) {
        return personfour;
      } else {
        return '';
      }
    },
    onlySelfCanApprove: function onlySelfCanApprove(row) {
      var _context;

      return (0, _includes["default"])(_context = this.personFormatter(row)).call(_context, this.$user.user_id);
    }
  }
};
exports["default"] = _default;