"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.postDataFormat = void 0;

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/slicedToArray"));

var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

// 接收参数1为数据 参数2为数组转字符串的形式
var postDataFormat = function postDataFormat(requestData) {
  var _context;

  var transformStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ',';
  var newPostData = (0, _reduce["default"])(_context = (0, _entries["default"])(requestData)).call(_context, function (obj, _ref) {
    var _context2;

    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    if ((0, _isArray["default"])(value)) {
      value = value.join(transformStr);
    }

    if (!(0, _includes["default"])(_context2 = [undefined, null, '']).call(_context2, value)) {
      obj[key] = value;
    }

    return obj;
  }, {});
  return newPostData;
};

exports.postDataFormat = postDataFormat;