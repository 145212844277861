"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _findIndex = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find-index"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/slicedToArray"));

var _entries = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/entries"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _flat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flat"));

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context6; _forEachInstanceProperty(_context6 = ownKeys(Object(source), true)).call(_context6, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context7; _forEachInstanceProperty(_context7 = ownKeys(Object(source))).call(_context7, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  data: function data() {
    return {
      loading: false,
      dataSource: [],
      count: 0,
      limit: 5,
      page: 1
    };
  },
  computed: {
    _query: function _query() {
      var o = _objectSpread(_objectSpread({}, this.params), {}, {
        page: this.page,
        limit: this.limit
      });

      var fn = function fn(v) {
        var _context;

        return (0, _isArray["default"])(v) ? v.length !== 0 : !(0, _includes["default"])(_context = ['', null, undefined]).call(_context, v);
      };

      return _lodash["default"].pickBy(o, fn);
    }
  },
  methods: {
    convert: function convert(arr) {
      var _context2;

      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var purify = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return (0, _flat["default"])(_context2 = (0, _map["default"])(arr).call(arr, function (item) {
        var _context5;

        var temp = {};

        try {
          var b = JSON.parse(_lodash["default"].get(item, 'before_content', '{}'));
          var c = JSON.parse(_lodash["default"].get(item, 'change_content', '{}'));

          for (var i in b) {
            var _context3;

            var value = b[i];

            if ((0, _includes["default"])(_context3 = ['订单入场时间', '订单离场时间']).call(_context3, i) && (0, _moment["default"])(value).isValid()) {
              value = (0, _moment["default"])(value).format('YYYY-MM-DD');
            }

            _lodash["default"].set(temp, "".concat(i, ".before"), value);
          }

          for (var _i in c) {
            var _context4;

            var _value = c[_i];

            if ((0, _includes["default"])(_context4 = ['订单入场时间', '订单离场时间']).call(_context4, _i) && (0, _moment["default"])(_value).isValid()) {
              _value = (0, _moment["default"])(_value).format('YYYY-MM-DD');
            }

            _lodash["default"].set(temp, "".concat(_i, ".change"), _value);
          }
        } catch (error) {
          console.log(error);
        }

        var fieldArr = (0, _map["default"])(_context5 = (0, _entries["default"])(temp)).call(_context5, function (_ref) {
          var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
              k = _ref2[0],
              v = _ref2[1];

          return _objectSpread(_objectSpread({}, item), {}, {
            _field: k,
            _before: v.before,
            _change: v.change
          });
        });
        return purify ? fieldArr : fieldArr.length ? limit ? (0, _slice["default"])(fieldArr).call(fieldArr, 0, 5) : fieldArr : item;
      })).call(_context2);
    },
    arraySpanMethod: function arraySpanMethod(_ref3) {
      var row = _ref3.row,
          column = _ref3.column,
          rowIndex = _ref3.rowIndex,
          columnIndex = _ref3.columnIndex;
      var s = (0, _findIndex["default"])(_lodash["default"]).call(_lodash["default"], this.dataSource, function (o) {
        return o.id === row.id;
      });

      var e = _lodash["default"].findLastIndex(this.dataSource, function (o) {
        return o.id === row.id;
      });

      if (!/^_/.test(column.property)) {
        if (rowIndex === s) {
          return {
            rowspan: e - s + 1,
            colspan: 1
          };
        }

        if (rowIndex <= e) {
          return {
            rowspan: 0,
            colspan: 1
          };
        }

        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    arraySpanMethodFn: function arraySpanMethodFn(lists) {
      return function (_ref4) {
        var row = _ref4.row,
            column = _ref4.column,
            rowIndex = _ref4.rowIndex,
            columnIndex = _ref4.columnIndex;
        var s = (0, _findIndex["default"])(_lodash["default"]).call(_lodash["default"], lists, function (o) {
          return o.id === row.id;
        });

        var e = _lodash["default"].findLastIndex(lists, function (o) {
          return o.id === row.id;
        });

        if (!/^_/.test(column.property)) {
          if (rowIndex === s) {
            return {
              rowspan: e - s + 1,
              colspan: 1
            };
          }

          if (rowIndex <= e) {
            return {
              rowspan: 0,
              colspan: 1
            };
          }

          return {
            rowspan: 1,
            colspan: 1
          };
        }
      };
    }
  }
};
exports["default"] = _default;