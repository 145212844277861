//
//
//
//
//
//
//
//
//
//

import TitleBar from './TitleBar'
import ContentTable from './ContentTable'
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    accordion: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TitleBar,
    ContentTable
  },
  mounted() {
    this.$on('action', (bool) => {
      if (bool) {
        // 防止打开全部之后点击单个关闭开启造成数据混乱 采用去重处理
        let beforedata = []
        this.data.forEach((el) => {
          beforedata.push(el.id)
        })
        this.openNames = [...new Set(beforedata)]
      } else {
        this.openNames = []
      }
    })
  },
  data() {
    return {
      openNames: []
    }
  },
  methods: {}
}
