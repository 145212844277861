var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-panel page-breadcrumb" },
    [
      _c(
        "el-breadcrumb",
        { attrs: { separator: "/" } },
        [
          _c("el-breadcrumb-item", [
            _c("i", { staticClass: "el-icon-location" })
          ]),
          _vm._v(" "),
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "el-breadcrumb-item",
              {
                key: index,
                class: item.route ? "route-link" : "",
                attrs: { to: _vm.isVueRouter && _vm.getRoute(item) },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.routeTo(item)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.displayText(item)) + "\n    ")]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("menu")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }