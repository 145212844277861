"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.formatErrors = formatErrors;
exports.responseHandle = responseHandle;

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

function formatErrors(errors) {
  var _context;

  return (0, _map["default"])(_context = (0, _keys["default"])(errors)).call(_context, function (field) {
    return '<p>' + field + ': ' + errors[field].join(';') + '</p>';
  }).join('\n');
}

function responseHandle(response) {
  return function () {
    return {
      data: {
        code: response ? response.status : -1
      }
    };
  };
}