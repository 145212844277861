"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.mapProductStage = exports.judianValidate = exports.cancleRelatedJudian = exports.addRelatedJudian = exports.relatedJudian = exports.getJudianList = exports.getAlreadySpentDate = exports.queryUrl = exports.queryOperationRecord = exports.queryLog = exports.getFileUrl = exports.queryList = exports.queryBackList = exports.actionCommit = exports.uploadPorjectFile = exports.uploadFile = exports.actionOperation = exports.queryOperationInfo = exports.queryTransferSteps = exports.queryTaskSteps = exports.queryTaskDetail = exports.timesheetPATCH = exports.timesheetDelete = exports.timesheetPost = exports.timesheetProjectList = exports.getFlowSearch = exports.timesheetOrderList = exports.timesheetMap = exports.timesheet = exports.getMyflow = exports.downloadExcel = exports.getReviewList = exports.getTaskList = exports.reportWorkhours = exports.getEOrderList = exports.queryMyTimeList = exports.queryTaskList = exports.planReject = exports.planPass = exports.planApproveListExport = exports.planApproveList = exports.uploadUrl = exports.planBillUpload = exports.planRealReport = exports.planJudge = exports.planConfirmList = exports.planSubmitList = exports.planDelete = exports.planSubmit = exports.planEdit = exports.getOrderInfo = exports.planApplyList = exports.getPlanHoliday = exports.planDetail = exports.orderBCD = exports.getOrderListA = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context8; _forEachInstanceProperty(_context8 = ownKeys(Object(source), true)).call(_context8, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context9; _forEachInstanceProperty(_context9 = ownKeys(Object(source))).call(_context9, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var url = ''; // 我的工作台 - 我的A类订单

var getOrderListA = function getOrderListA() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/my_workbench/a_order_list'
  }, config));
}; // 我的工作台 - 我的BCD类订单


exports.getOrderListA = getOrderListA;

var orderBCD = function orderBCD() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/my_workbench/order_list'
  }, config));
}; // 我的工作台 - 计划详情 - 通用接口


exports.orderBCD = orderBCD;

var planDetail = function planDetail() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$query = _ref3.query,
      query = _ref3$query === void 0 ? {} : _ref3$query,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/planDetail'
  }, config));
}; // 我的工作台 - 计划详情 - 获取节假日


exports.planDetail = planDetail;

var getPlanHoliday = function getPlanHoliday() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$query = _ref4.query,
      query = _ref4$query === void 0 ? {} : _ref4$query,
      _ref4$config = _ref4.config,
      config = _ref4$config === void 0 ? {
    loading: false
  } : _ref4$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/getPlanHoliday'
  }, config));
}; // 我的工作台 - 新增计划 - 计划申请列表


exports.getPlanHoliday = getPlanHoliday;

var planApplyList = function planApplyList() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$query = _ref5.query,
      query = _ref5$query === void 0 ? {} : _ref5$query,
      _ref5$config = _ref5.config,
      config = _ref5$config === void 0 ? {
    loading: false
  } : _ref5$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/planApplyList'
  }, config));
}; // 我的工作台 - 新增计划 - 获取订单编号


exports.planApplyList = planApplyList;

var getOrderInfo = function getOrderInfo() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$query = _ref6.query,
      query = _ref6$query === void 0 ? {} : _ref6$query,
      _ref6$config = _ref6.config,
      config = _ref6$config === void 0 ? {
    loading: false
  } : _ref6$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/getOrderInfo'
  }, config));
}; // 我的工作台 - 新增计划 - 新增|修改接口


exports.getOrderInfo = getOrderInfo;

var planEdit = function planEdit() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$query = _ref7.query,
      query = _ref7$query === void 0 ? {} : _ref7$query,
      _ref7$data = _ref7.data,
      data = _ref7$data === void 0 ? {} : _ref7$data,
      _ref7$config = _ref7.config,
      config = _ref7$config === void 0 ? {
    loading: false
  } : _ref7$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/planAdd'
  }, config));
}; // 我的工作台 - 新增计划 - 提交去审批


exports.planEdit = planEdit;

var planSubmit = function planSubmit() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref8$query = _ref8.query,
      query = _ref8$query === void 0 ? {} : _ref8$query,
      _ref8$data = _ref8.data,
      data = _ref8$data === void 0 ? {} : _ref8$data,
      _ref8$config = _ref8.config,
      config = _ref8$config === void 0 ? {
    loading: false
  } : _ref8$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/planSubmit'
  }, config));
}; // 我的工作台 - 新增计划 - 删除计划


exports.planSubmit = planSubmit;

var planDelete = function planDelete() {
  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref9$query = _ref9.query,
      query = _ref9$query === void 0 ? {} : _ref9$query,
      _ref9$data = _ref9.data,
      data = _ref9$data === void 0 ? {} : _ref9$data,
      _ref9$config = _ref9.config,
      config = _ref9$config === void 0 ? {
    loading: false
  } : _ref9$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/planDelete'
  }, config));
}; // 我的工作台 - 我提交的计划 - 列表


exports.planDelete = planDelete;

var planSubmitList = function planSubmitList() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref10$query = _ref10.query,
      query = _ref10$query === void 0 ? {} : _ref10$query,
      _ref10$config = _ref10.config,
      config = _ref10$config === void 0 ? {
    loading: false
  } : _ref10$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/planSubmitList'
  }, config));
}; // 我的工作台 - 计划确认 - 列表


exports.planSubmitList = planSubmitList;

var planConfirmList = function planConfirmList() {
  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref11$query = _ref11.query,
      query = _ref11$query === void 0 ? {} : _ref11$query,
      _ref11$config = _ref11.config,
      config = _ref11$config === void 0 ? {
    loading: false
  } : _ref11$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/planConfirmList'
  }, config));
}; // 我的工作台 - 取消确认计划


exports.planConfirmList = planConfirmList;

var planJudge = function planJudge() {
  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref12$query = _ref12.query,
      query = _ref12$query === void 0 ? {} : _ref12$query,
      _ref12$data = _ref12.data,
      data = _ref12$data === void 0 ? {} : _ref12$data,
      _ref12$config = _ref12.config,
      config = _ref12$config === void 0 ? {
    loading: false
  } : _ref12$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/planJudge'
  }, config));
}; // 我的工作台 - 实际提报


exports.planJudge = planJudge;

var planRealReport = function planRealReport() {
  var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref13$query = _ref13.query,
      query = _ref13$query === void 0 ? {} : _ref13$query,
      _ref13$data = _ref13.data,
      data = _ref13$data === void 0 ? {} : _ref13$data,
      _ref13$config = _ref13.config,
      config = _ref13$config === void 0 ? {
    loading: false
  } : _ref13$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/planRealReport'
  }, config));
}; // 我的工作台 - 票据上传


exports.planRealReport = planRealReport;

var planBillUpload = function planBillUpload() {
  var _ref14 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref14$query = _ref14.query,
      query = _ref14$query === void 0 ? {} : _ref14$query,
      _ref14$data = _ref14.data,
      data = _ref14$data === void 0 ? {} : _ref14$data,
      _ref14$config = _ref14.config,
      config = _ref14$config === void 0 ? {
    loading: false
  } : _ref14$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/planBillUpload'
  }, config));
}; // 我的工作台 - 计划确认 - 详情上传票据


exports.planBillUpload = planBillUpload;

var uploadUrl = function uploadUrl() {
  var _ref15 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref15$params = _ref15.params,
      params = _ref15$params === void 0 ? {} : _ref15$params,
      _ref15$query = _ref15.query,
      query = _ref15$query === void 0 ? {} : _ref15$query,
      _ref15$config = _ref15.config,
      config = _ref15$config === void 0 ? {
    loading: false
  } : _ref15$config;

  return url + '/deliver/tiantu/fileUpload';
}; // 我的工作台 - 我的审批 - 计划审批列表


exports.uploadUrl = uploadUrl;

var planApproveList = function planApproveList() {
  var _ref16 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref16$query = _ref16.query,
      query = _ref16$query === void 0 ? {} : _ref16$query,
      _ref16$config = _ref16.config,
      config = _ref16$config === void 0 ? {
    loading: false
  } : _ref16$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/planApproveList'
  }, config));
}; // 我的工作台 - 我的审批 - 计划审批列表--导出


exports.planApproveList = planApproveList;

var planApproveListExport = function planApproveListExport() {
  var _ref17 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref17$query = _ref17.query,
      query = _ref17$query === void 0 ? {} : _ref17$query,
      _ref17$config = _ref17.config,
      config = _ref17$config === void 0 ? {
    loading: false
  } : _ref17$config;

  return url + '/deliver/tiantu/planApproveList';
}; // 我的工作台 - 批量通过/单条通过 分号分隔


exports.planApproveListExport = planApproveListExport;

var planPass = function planPass() {
  var _ref18 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref18$query = _ref18.query,
      query = _ref18$query === void 0 ? {} : _ref18$query,
      _ref18$data = _ref18.data,
      data = _ref18$data === void 0 ? {} : _ref18$data,
      _ref18$config = _ref18.config,
      config = _ref18$config === void 0 ? {
    loading: false
  } : _ref18$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/planPass'
  }, config));
}; // 我的工作台 - 驳回接口


exports.planPass = planPass;

var planReject = function planReject() {
  var _ref19 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref19$query = _ref19.query,
      query = _ref19$query === void 0 ? {} : _ref19$query,
      _ref19$data = _ref19.data,
      data = _ref19$data === void 0 ? {} : _ref19$data,
      _ref19$config = _ref19.config,
      config = _ref19$config === void 0 ? {
    loading: false
  } : _ref19$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/planReject'
  }, config));
};
/**
 * 我的任务 - 我的任务列表
 */


exports.planReject = planReject;

var queryTaskList = function queryTaskList() {
  var _ref20 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref20$query = _ref20.query,
      query = _ref20$query === void 0 ? {} : _ref20$query,
      _ref20$config = _ref20.config,
      config = _ref20$config === void 0 ? {
    loading: false
  } : _ref20$config;

  return _promise["default"].resolve({
    data: {
      data: {
        data: [{
          order_id: 123,
          task_name: 789,
          server_name: 444
        }]
      }
    }
  }); // return Vue.ajax.request({
  //   params: query,
  //   method: 'get',
  //   url: url + '/deliver/tiantu/planReject',
  //   ...config
  // })
};
/**
 * 我的任务 - 我的工时列表
 */


exports.queryTaskList = queryTaskList;

var queryMyTimeList = function queryMyTimeList() {
  var _ref21 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref21$query = _ref21.query,
      query = _ref21$query === void 0 ? {} : _ref21$query,
      _ref21$config = _ref21.config,
      config = _ref21$config === void 0 ? {
    loading: false
  } : _ref21$config;

  return _promise["default"].resolve({
    data: {
      data: {
        data: [{
          order_id: 123,
          task_name: 789,
          server_name: 444
        }]
      }
    }
  });
}; // return Vue.ajax.request({
//   params: query,
//   method: 'get',
//   url: url + '/deliver/task/timesheet',
//   ...config
// })
// e类订单 - 订单列表


exports.queryMyTimeList = queryMyTimeList;

var getEOrderList = function getEOrderList() {
  var _ref22 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref22$query = _ref22.query,
      query = _ref22$query === void 0 ? {} : _ref22$query,
      _ref22$data = _ref22.data,
      data = _ref22$data === void 0 ? {} : _ref22$data,
      _ref22$config = _ref22.config,
      config = _ref22$config === void 0 ? {
    loading: false
  } : _ref22$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/my_workbench/e_order_filling_list'
  }, config));
}; // e类订单 - 工时上报


exports.getEOrderList = getEOrderList;

var reportWorkhours = function reportWorkhours() {
  var _ref23 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref23$query = _ref23.query,
      query = _ref23$query === void 0 ? {} : _ref23$query,
      _ref23$data = _ref23.data,
      data = _ref23$data === void 0 ? {} : _ref23$data,
      _ref23$config = _ref23.config,
      config = _ref23$config === void 0 ? {
    loading: false
  } : _ref23$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/my_workbench/e_order_filling_add'
  }, config));
}; // 获取任务列表


exports.reportWorkhours = reportWorkhours;

var getTaskList = function getTaskList() {
  var _ref24 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref24$query = _ref24.query,
      query = _ref24$query === void 0 ? {} : _ref24$query,
      _ref24$config = _ref24.config,
      config = _ref24$config === void 0 ? {
    loading: false
  } : _ref24$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/mytask'
  }, config));
}; // 获取评审列表


exports.getTaskList = getTaskList;

var getReviewList = function getReviewList() {
  var _ref25 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref25$query = _ref25.query,
      query = _ref25$query === void 0 ? {} : _ref25$query,
      _ref25$config = _ref25.config,
      config = _ref25$config === void 0 ? {
    loading: false
  } : _ref25$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/myreview'
  }, config));
}; // 导出表格


exports.getReviewList = getReviewList;

var downloadExcel = function downloadExcel() {
  var _ref26 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref26$params = _ref26.params,
      params = _ref26$params === void 0 ? {} : _ref26$params,
      _ref26$query = _ref26.query,
      query = _ref26$query === void 0 ? {} : _ref26$query,
      _ref26$config = _ref26.config,
      config = _ref26$config === void 0 ? {
    loading: false
  } : _ref26$config;

  return url + '/deliver/qflow/download_excel';
}; // 获取工作流


exports.downloadExcel = downloadExcel;

var getMyflow = function getMyflow() {
  var _ref27 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref27$query = _ref27.query,
      query = _ref27$query === void 0 ? {} : _ref27$query,
      _ref27$config = _ref27.config,
      config = _ref27$config === void 0 ? {
    loading: false
  } : _ref27$config;

  return _vue["default"].ajax.request({
    params: query,
    method: 'get',
    url: url + '/deliver/myflow'
  });
}; // 工时-列表


exports.getMyflow = getMyflow;

var timesheet = function timesheet() {
  var _ref28 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref28$query = _ref28.query,
      query = _ref28$query === void 0 ? {} : _ref28$query,
      _ref28$data = _ref28.data,
      data = _ref28$data === void 0 ? {} : _ref28$data,
      _ref28$config = _ref28.config,
      config = _ref28$config === void 0 ? {
    loading: false
  } : _ref28$config;

  if (query && query.spent_person) {
    query.spent_person = query.spent_person.replace(/\(.*?\)/g, '');
  }

  if (query && query.created_person) {
    query.created_person = query.created_person.replace(/\(.*?\)/g, '');
  }

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    data: data,
    url: url + '/deliver/timesheet'
  }, config));
}; // 工时-表单相关map


exports.timesheet = timesheet;

var timesheetMap = function timesheetMap() {
  var _ref29 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref29$query = _ref29.query,
      query = _ref29$query === void 0 ? {} : _ref29$query,
      _ref29$data = _ref29.data,
      data = _ref29$data === void 0 ? {} : _ref29$data,
      _ref29$config = _ref29.config,
      config = _ref29$config === void 0 ? {
    loading: false
  } : _ref29$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    data: data,
    url: url + '/deliver/timesheet/map'
  }, config));
}; // 工时-订单下列列表


exports.timesheetMap = timesheetMap;

var timesheetOrderList = function timesheetOrderList() {
  var _ref30 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref30$query = _ref30.query,
      query = _ref30$query === void 0 ? {} : _ref30$query,
      _ref30$data = _ref30.data,
      data = _ref30$data === void 0 ? {} : _ref30$data,
      _ref30$config = _ref30.config,
      config = _ref30$config === void 0 ? {
    loading: false
  } : _ref30$config;

  if (query && query.name) {
    query.name = query.name.replace(/\(.*?\)/g, '');
  }

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    data: data,
    url: url + '/deliver/timesheet/orderList'
  }, config));
}; // 获取工作流页面顶部配置筛选项


exports.timesheetOrderList = timesheetOrderList;

var getFlowSearch = function getFlowSearch() {
  var _ref31 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref31$query = _ref31.query,
      query = _ref31$query === void 0 ? {} : _ref31$query,
      _ref31$config = _ref31.config,
      config = _ref31$config === void 0 ? {
    loading: false
  } : _ref31$config;

  return _vue["default"].ajax.request({
    params: query,
    method: 'get',
    url: url + '/deliver/task/sum'
  });
}; // 工时-项目下拉项


exports.getFlowSearch = getFlowSearch;

var timesheetProjectList = function timesheetProjectList() {
  var _ref32 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref32$query = _ref32.query,
      query = _ref32$query === void 0 ? {} : _ref32$query,
      _ref32$data = _ref32.data,
      data = _ref32$data === void 0 ? {} : _ref32$data,
      _ref32$config = _ref32.config,
      config = _ref32$config === void 0 ? {
    loading: false
  } : _ref32$config;

  if (query && query.name) {
    query.name = query.name.replace(/\(.*?\)/g, '');
  }

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    data: data,
    url: url + '/deliver/timesheet/projectList'
  }, config));
}; // 工时-保存


exports.timesheetProjectList = timesheetProjectList;

var timesheetPost = function timesheetPost() {
  var _ref33 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref33$query = _ref33.query,
      query = _ref33$query === void 0 ? {} : _ref33$query,
      _ref33$data = _ref33.data,
      data = _ref33$data === void 0 ? {} : _ref33$data,
      _ref33$config = _ref33.config,
      config = _ref33$config === void 0 ? {
    loading: false
  } : _ref33$config;

  if (data && data.spent_person) {
    data.spent_person = data.spent_person.replace(/\(.*?\)/g, '');
  }

  if (data && data.data && (0, _isArray["default"])(data.data)) {
    var _context;

    data.data = (0, _map["default"])(_context = data.data).call(_context, function (item) {
      var obj = _objectSpread({}, item);

      if (obj.mark) {
        obj.mark = btoa(encodeURI(obj.mark));
      }

      return obj;
    });
  }

  data.mark_is_base64 = '1';
  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/timesheet'
  }, config));
}; // 工时-删除


exports.timesheetPost = timesheetPost;

var timesheetDelete = function timesheetDelete() {
  var _ref34 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      id = _ref34.id,
      _ref34$query = _ref34.query,
      query = _ref34$query === void 0 ? {} : _ref34$query,
      _ref34$data = _ref34.data,
      data = _ref34$data === void 0 ? {} : _ref34$data,
      _ref34$config = _ref34.config,
      config = _ref34$config === void 0 ? {
    loading: false
  } : _ref34$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'delete',
    url: url + "/deliver/timesheet/".concat(id)
  }, config));
}; // 工时-修改


exports.timesheetDelete = timesheetDelete;

var timesheetPATCH = function timesheetPATCH() {
  var _ref35 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      id = _ref35.id,
      _ref35$query = _ref35.query,
      query = _ref35$query === void 0 ? {} : _ref35$query,
      _ref35$data = _ref35.data,
      data = _ref35$data === void 0 ? {} : _ref35$data,
      _ref35$config = _ref35.config,
      config = _ref35$config === void 0 ? {
    loading: false
  } : _ref35$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'patch',
    url: url + "/deliver/timesheet/".concat(id)
  }, config));
};
/**
 * 交付作业---获取任务信息
 */


exports.timesheetPATCH = timesheetPATCH;

var queryTaskDetail = function queryTaskDetail() {
  var _ref36 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref36$params = _ref36.params,
      params = _ref36$params === void 0 ? {} : _ref36$params,
      _ref36$query = _ref36.query,
      query = _ref36$query === void 0 ? {} : _ref36$query,
      _ref36$config = _ref36.config,
      config = _ref36$config === void 0 ? {
    loading: false
  } : _ref36$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + "/deliver/panshi/".concat(params.taskId, "/detail")
  }, config));
};
/**
 * 交付作业---获取流程信息
 */


exports.queryTaskDetail = queryTaskDetail;

var queryTaskSteps = function queryTaskSteps() {
  var _ref37 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref37$params = _ref37.params,
      params = _ref37$params === void 0 ? {} : _ref37$params,
      _ref37$query = _ref37.query,
      query = _ref37$query === void 0 ? {} : _ref37$query,
      _ref37$config = _ref37.config,
      config = _ref37$config === void 0 ? {
    loading: false
  } : _ref37$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + "/deliver/panshi/".concat(params.taskId, "/view")
  }, config));
};
/**
 * 产品转维---获取产品转维信息
 */


exports.queryTaskSteps = queryTaskSteps;

var queryTransferSteps = function queryTransferSteps() {
  var _ref38 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref38$params = _ref38.params,
      params = _ref38$params === void 0 ? {} : _ref38$params,
      _ref38$query = _ref38.query,
      query = _ref38$query === void 0 ? {} : _ref38$query,
      _ref38$config = _ref38.config,
      config = _ref38$config === void 0 ? {
    loading: false
  } : _ref38$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + "/deliver/product/getDoingProductOperation?qflow_task_id=".concat(params.taskId)
  }, config));
};
/**
 * 交付作业---产品转维获取信息
 */


exports.queryTransferSteps = queryTransferSteps;

var queryOperationInfo = function queryOperationInfo() {
  var _ref39 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref39$params = _ref39.params,
      params = _ref39$params === void 0 ? {} : _ref39$params,
      _ref39$config = _ref39.config,
      config = _ref39$config === void 0 ? {
    loading: false
  } : _ref39$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: params,
    method: 'get',
    url: url + "/deliver/product/getOrderOperationInfo"
  }, config));
};
/**
 * 交付作业---发起产品转维
 */


exports.queryOperationInfo = queryOperationInfo;

var actionOperation = function actionOperation() {
  var _ref40 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref40$params = _ref40.params,
      params = _ref40$params === void 0 ? {} : _ref40$params,
      _ref40$config = _ref40.config,
      config = _ref40$config === void 0 ? {
    loading: false
  } : _ref40$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: params,
    method: 'get',
    url: url + "/deliver/product/addOrderOperation"
  }, config));
};
/**
 * 普通文件上传接口
 */


exports.actionOperation = actionOperation;

var uploadFile = function uploadFile() {
  return url + '/deliver/tiantu/fileUpload';
};
/**
 * 项目归档文件上传接口
 */


exports.uploadFile = uploadFile;

var uploadPorjectFile = function uploadPorjectFile() {
  return url + '/deliver/qflow/upload_config';
};
/**
 *审批提交接口
 */


exports.uploadPorjectFile = uploadPorjectFile;

var actionCommit = function actionCommit() {
  var _context2, _context3;

  var _ref41 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref41$params = _ref41.params,
      params = _ref41$params === void 0 ? {} : _ref41$params,
      _ref41$data = _ref41.data,
      data = _ref41$data === void 0 ? {} : _ref41$data,
      _ref41$config = _ref41.config,
      config = _ref41$config === void 0 ? {
    loading: false
  } : _ref41$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: params,
    data: data,
    method: 'post',
    url: url + (0, _concat["default"])(_context2 = (0, _concat["default"])(_context3 = "/deliver/panshi/".concat(params.approvalId, "/")).call(_context3, params.commitType, "/")).call(_context2, params.stepCode)
  }, config));
};
/**
 *获取打回步骤列表
 */


exports.actionCommit = actionCommit;

var queryBackList = function queryBackList() {
  var _context4;

  var _ref42 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref42$params = _ref42.params,
      params = _ref42$params === void 0 ? {} : _ref42$params,
      _ref42$data = _ref42.data,
      data = _ref42$data === void 0 ? {} : _ref42$data,
      _ref42$config = _ref42.config,
      config = _ref42$config === void 0 ? {
    loading: false
  } : _ref42$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    url: url + (0, _concat["default"])(_context4 = "/deliver/panshi/".concat(params.approvalId, "/")).call(_context4, params.stepCode, "/back")
  }, config));
};
/**
 *获取步骤列表
 */


exports.queryBackList = queryBackList;

var queryList = function queryList() {
  var _ref43 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref43$params = _ref43.params,
      params = _ref43$params === void 0 ? {} : _ref43$params,
      _ref43$data = _ref43.data,
      data = _ref43$data === void 0 ? {} : _ref43$data,
      _ref43$config = _ref43.config,
      config = _ref43$config === void 0 ? {
    loading: false
  } : _ref43$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    url: url + "/deliver/panshi/".concat(params.task_id, "/list")
  }, config));
}; // 项目归档  获取文件地址


exports.queryList = queryList;

var getFileUrl = function getFileUrl() {
  var _ref44 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref44$params = _ref44.params,
      params = _ref44$params === void 0 ? {} : _ref44$params,
      _ref44$query = _ref44.query,
      query = _ref44$query === void 0 ? {} : _ref44$query,
      _ref44$config = _ref44.config,
      config = _ref44$config === void 0 ? {
    loading: false
  } : _ref44$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/qflow/get_config_url'
  }, config));
};
/**
 *获取操作日志
 */


exports.getFileUrl = getFileUrl;

var queryLog = function queryLog() {
  var _context5;

  var _ref45 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref45$params = _ref45.params,
      params = _ref45$params === void 0 ? {} : _ref45$params,
      _ref45$data = _ref45.data,
      data = _ref45$data === void 0 ? {} : _ref45$data,
      _ref45$config = _ref45.config,
      config = _ref45$config === void 0 ? {
    loading: false
  } : _ref45$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    url: url + (0, _concat["default"])(_context5 = "/deliver/panshi/".concat(params.approvalId, "/")).call(_context5, params.stepCode, "/log")
  }, config));
};
/**
 *获取产品 转维记录
 */


exports.queryLog = queryLog;

var queryOperationRecord = function queryOperationRecord() {
  var _ref46 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref46$params = _ref46.params,
      params = _ref46$params === void 0 ? {} : _ref46$params,
      _ref46$config = _ref46.config,
      config = _ref46$config === void 0 ? {
    loading: false
  } : _ref46$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    params: params,
    url: url + "/deliver/product/getProductOperationRecord"
  }, config));
};
/**
 *获取真实下载地址
 */


exports.queryOperationRecord = queryOperationRecord;

var queryUrl = function queryUrl() {
  var _ref47 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref47$params = _ref47.params,
      params = _ref47$params === void 0 ? {} : _ref47$params,
      _ref47$data = _ref47.data,
      data = _ref47$data === void 0 ? {} : _ref47$data,
      _ref47$config = _ref47.config,
      config = _ref47$config === void 0 ? {
    loading: false
  } : _ref47$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: params,
    method: 'get',
    url: url + "/deliver/panshi/url"
  }, config));
};
/**
 *获取真实下载地址
 */


exports.queryUrl = queryUrl;

var getAlreadySpentDate = function getAlreadySpentDate() {
  var _ref48 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref48$params = _ref48.params,
      params = _ref48$params === void 0 ? {} : _ref48$params,
      _ref48$data = _ref48.data,
      data = _ref48$data === void 0 ? {} : _ref48$data,
      _ref48$config = _ref48.config,
      config = _ref48$config === void 0 ? {
    loading: false
  } : _ref48$config;

  if (params && params.spent_person) {
    params.spent_person = params.spent_person.replace(/\(.*?\)/g, '');
  }

  return _vue["default"].ajax.request(_objectSpread({
    params: params,
    method: 'get',
    url: url + "/deliver/timesheet/spentDate"
  }, config));
};
/**
 * 局点列表
 */


exports.getAlreadySpentDate = getAlreadySpentDate;

var getJudianList = function getJudianList() {
  var _ref49 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref49$query = _ref49.query,
      query = _ref49$query === void 0 ? {} : _ref49$query,
      _ref49$data = _ref49.data,
      data = _ref49$data === void 0 ? {} : _ref49$data,
      _ref49$config = _ref49.config,
      config = _ref49$config === void 0 ? {
    loading: false
  } : _ref49$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    url: url + "/deliver/judian/".concat(query.task_id)
  }, config));
};
/**
 * 关联局点
 */


exports.getJudianList = getJudianList;

var relatedJudian = function relatedJudian() {
  var _context6;

  var _ref50 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref50$query = _ref50.query,
      query = _ref50$query === void 0 ? {} : _ref50$query,
      _ref50$data = _ref50.data,
      data = _ref50$data === void 0 ? {} : _ref50$data,
      _ref50$config = _ref50.config,
      config = _ref50$config === void 0 ? {
    loading: false
  } : _ref50$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'post',
    url: url + (0, _concat["default"])(_context6 = "/deliver/judian/".concat(query.taskId, "/")).call(_context6, query.jId)
  }, config));
};
/**
 * 新增关联局点
 */


exports.relatedJudian = relatedJudian;

var addRelatedJudian = function addRelatedJudian() {
  var _ref51 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref51$query = _ref51.query,
      query = _ref51$query === void 0 ? {} : _ref51$query,
      _ref51$data = _ref51.data,
      data = _ref51$data === void 0 ? {} : _ref51$data,
      _ref51$config = _ref51.config,
      config = _ref51$config === void 0 ? {
    loading: false
  } : _ref51$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'post',
    data: data,
    url: url + "/deliver/judian/list"
  }, config));
};
/**
 * 取消关联局点
 */


exports.addRelatedJudian = addRelatedJudian;

var cancleRelatedJudian = function cancleRelatedJudian() {
  var _context7;

  var _ref52 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref52$query = _ref52.query,
      query = _ref52$query === void 0 ? {} : _ref52$query,
      _ref52$data = _ref52.data,
      data = _ref52$data === void 0 ? {} : _ref52$data,
      _ref52$config = _ref52.config,
      config = _ref52$config === void 0 ? {
    loading: false
  } : _ref52$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'put',
    url: url + (0, _concat["default"])(_context7 = "/deliver/judian/".concat(query.taskId, "/")).call(_context7, query.jId)
  }, config));
};
/**
 * 局点鉴权
 */


exports.cancleRelatedJudian = cancleRelatedJudian;

var judianValidate = function judianValidate() {
  var _ref53 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref53$query = _ref53.query,
      query = _ref53$query === void 0 ? {} : _ref53$query,
      _ref53$data = _ref53.data,
      data = _ref53$data === void 0 ? {} : _ref53$data,
      _ref53$config = _ref53.config,
      config = _ref53$config === void 0 ? {
    loading: false
  } : _ref53$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    url: url + "/deliver/judian/validate/".concat(query.taskId)
  }, config));
};
/*
 * 工时-产品对应交付阶段信息接口
 */


exports.judianValidate = judianValidate;

var mapProductStage = function mapProductStage() {
  var _ref54 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref54$params = _ref54.params,
      params = _ref54$params === void 0 ? {} : _ref54$params,
      _ref54$query = _ref54.query,
      query = _ref54$query === void 0 ? {} : _ref54$query,
      _ref54$data = _ref54.data,
      data = _ref54$data === void 0 ? {} : _ref54$data,
      _ref54$config = _ref54.config,
      config = _ref54$config === void 0 ? {
    loading: false
  } : _ref54$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + "/deliver/timesheet/map_product_stage"
  }, config));
};

exports.mapProductStage = mapProductStage;