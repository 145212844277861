//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logoImage from '../../img/svg/logo-bg-color.svg'

import UserPermissionMixin from 'src/modules/mixins/user-permission'

export default {
  props: {
    user: Object
  },
  mixins: [UserPermissionMixin],
  data() {
    return {
      logo: logoImage,
      // 顶部导航菜单
      navlist: [
        { label: '我的工作台', permission: 'DELIVER.*', redirectUrl: '/workbench' },
        { label: '账单管理', permission: 'DELIVER.BILL.*', redirectUrl: '/deliver' },
        { label: '项目管理', permission: 'DELIVER.PROJECT.*', redirectUrl: '/project' },
        { label: '产品管理', permission: 'DELIVER.PRODUCT_MANAGER.SEE_LIST', redirectUrl: '/product' },
        { label: '人力管理', permission: 'DELIVER.HUMAN.*', redirectUrl: '/human' },
        { label: '客户管理', permission: 'DELIVER.CUSTOMER.*', redirectUrl: '/customer' },
        { label: '订单管理', permission: 'DELIVER.ORDER.*', redirectUrl: '/oms' },
        { label: '系统管理', permission: 'BACKEND', redirectUrl: '/admin' }
      ]
    }
  },
  computed: {
    href() {
      return '/deliver/logout?next=' + location.href.replace(location.search, '')
    }
  },
  methods: {
    handleRedirect(path) {
      if (window.location.pathname !== path) {
        window.location.href = path
      }
    }
  }
}
