//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { queryLog } from 'src/pages/workbench/api'
import LogItem from './LogItem'
export default {
  components: {
    LogItem
  },
  data() {
    return {
      visible: false,
      loading: false,
      query: {},
      data: []
    }
  },
  mounted() {
    this.$on('open', (obj) => {
      this.visible = true
      this.query = obj
      this.init(obj)
    })
  },
  methods: {
    init(params) {
      this.loading = true
      queryLog({ params: params }).then(({ data }) => {
        this.loading = false
        this.data = data.data.data || []
      })
    },
    handleClose() {
      this.visible = false
    }
  }
}
