"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var REQUEST_NOT_ALLOW = 403; // 前端路由权限检测，config配置权限与后端返回权限列表无匹配时返回403错误

function hasRoutePermission() {
  var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _ref = response.config || {},
      config = _ref.config;

  if (config && config.permission) {
    var permission = config.permission;
    var data = response.data;

    if (data && data.code === 0 && data.data) {
      var permissions = data.data.permissions || [permission]; // 加载完用户验证, 检查用户是否拥有加载组件权限

      if (!(0, _includes["default"])(permissions).call(permissions, permission)) {
        // 抛出HTTP异常
        response.status = REQUEST_NOT_ALLOW; // 手动包装axios error，官方暂时没有提供方法

        var error = {
          config: config,
          response: response
        };
        return _promise["default"].reject(error);
      }
    }
  }

  return response;
}

var _default = {
  responseResolve: hasRoutePermission
};
exports["default"] = _default;