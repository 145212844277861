"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

_Object$defineProperty(exports, "TableAttributes", {
  enumerable: true,
  get: function get() {
    return _TableAttributes["default"];
  }
});

_Object$defineProperty(exports, "TableColumnAttributes", {
  enumerable: true,
  get: function get() {
    return _TableColumnAttributes["default"];
  }
});

var _TableAttributes = _interopRequireDefault(require("./TableAttributes"));

var _TableColumnAttributes = _interopRequireDefault(require("./TableColumnAttributes"));