//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SeriesesComponent from './Serieses';
import PermissionsComponent from './permission/Permissions';
import AllCheckBoxComponent from './AllCheckBox';
import { getTreeKey } from '../../utils/operation-permissions';

export default {
  components: {
    SeriesesComponent,
    PermissionsComponent,
    AllCheckBoxComponent
  },
  props: {
    module: {
      type: String,
      required: true
    }
  },
  inheritAttrs: false,
  methods: {
    updatePermission (module, series, value) {
      const key = getTreeKey(module, series);
      this.$emit('update-permissions', {
        key,
        value
      })
    }
  }
}
