var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-panel page-content",
      attrs: { flex: "dir:top", "flex-box": "1" }
    },
    [
      _vm.$slots.toolbar
        ? _c(
            "div",
            { staticClass: "page-panel-toolbar" },
            [_vm._t("toolbar")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-panel-main", attrs: { "flex-box": "1" } },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.$slots.footer
        ? _c("div", { staticClass: "page-panel-footer" }, [_vm._t("footer")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }