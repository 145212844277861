//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SeriesComponent from './series/Series';
import EditDialogComponent from 'src/modules/components/EditDialog';

export default {
  components: {
    SeriesComponent,
    EditDialogComponent
  },
  props: {
    modules: {
      type: Array,
      default () {
        return []
      }
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      activeName: ''
    }
  },
  methods: {
    updatePermissions ({ key, value }) {
      this.$set(this.value, key, value);
      this.$emit('input', this.value);
    },
    initActiveName () {
      const firstModule = this.modules[0] || {};
      this.activeName = firstModule.code;
    }
  },
  created () {
    this.initActiveName();
  }
}
