"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.loadJs = loadJs;
exports.getCaretStart = getCaretStart;
exports.getCaretEnd = getCaretEnd;
exports.setCaret = setCaret;

function loadJs(url, location, cb) {
  var scriptTag = document.createElement('script');
  scriptTag.src = url;
  scriptTag.onload = cb;
  scriptTag.onreadystatechange = cb;
  location.appendChild(scriptTag);
}

function getDocumentCaret(ele, location) {
  var p;
  var range = document.selection.createRange();

  try {
    p = range.duplicate();
    p.moveToElementText(ele);
  } catch (e) {
    p = ele.createTextRange();
  }

  p.setEndPoint(location, range);
  var len = p.text.length;

  if (len > ele.value.length) {
    return -1;
  }

  return len;
} // 获取光标开始位置(ele需有焦点)


function getCaretStart(ele) {
  if (typeof ele.selectionStart !== 'undefined') {
    return ele.selectionStart;
  } else if (document.selection && document.selection.createRange) {
    return getDocumentCaret(ele, 'EndToStart');
  }
} // 获取光标结束位置(ele需有焦点)


function getCaretEnd(ele) {
  if (typeof ele.selectionEnd !== 'undefined') {
    return ele.selectionEnd;
  } else if (document.selection && document.selection.createRange) {
    return getDocumentCaret(ele, 'EndToEnd');
  }
} // 设置光标位置


function setCaret(ele, l) {
  ele.focus();

  if (ele.setSelectionRange) {
    ele.setSelectionRange(l, l);
  } else if (ele.createTextRange) {
    var m = ele.createTextRange();
    m.moveStart('character', l);
    m.collapse();
    m.select();
  }
}