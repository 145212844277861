"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _default = function _default(key) {
  return {
    save: function save(value) {
      localStorage.setItem(key, value);
    },
    get: function get() {
      return localStorage.getItem(key);
    }
  };
};

exports["default"] = _default;