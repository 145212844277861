var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "el-zoom-in-top" }, on: { "after-leave": _vm.doDestroy } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPopper,
              expression: "showPopper"
            }
          ],
          staticClass: "el-autocomplete-suggestion el-popper",
          style: { width: _vm.dropdownWidth },
          attrs: { role: "region" }
        },
        [
          _c(
            "el-scrollbar",
            {
              attrs: {
                tag: "ul",
                "wrap-class": "el-autocomplete-suggestion__wrap",
                "view-class": "el-autocomplete-suggestion__list"
              }
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }