"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
// 打开新的标签页
var _default = {
  methods: {
    open: function open(url) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '_blank';
      window.open(url, title);
    }
  }
};
exports["default"] = _default;