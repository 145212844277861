"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.uindelete = exports.uinadd = exports.uinlist = exports.customerUpdate = exports.customerEdit = exports.customerAdd = exports.customerlist = exports.partDelete = exports.partEdit = exports.customerList = exports.partInfo = exports.partList = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context; _forEachInstanceProperty(_context = ownKeys(Object(source), true)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var url = ''; // 局点列表

var partList = function partList() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/bugfix/get_judian_list'
  }, config));
}; // 局点编辑初始化


exports.partList = partList;

var partInfo = function partInfo() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/bugfix/get_judian_info'
  }, config));
}; // 局点编辑页---客户下拉选择接口


exports.partInfo = partInfo;

var customerList = function customerList() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$query = _ref3.query,
      query = _ref3$query === void 0 ? {} : _ref3$query,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/bugfix/get_customer_list'
  }, config));
}; // 局点编辑保存


exports.customerList = customerList;

var partEdit = function partEdit() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$query = _ref4.query,
      query = _ref4$query === void 0 ? {} : _ref4$query,
      _ref4$config = _ref4.config,
      config = _ref4$config === void 0 ? {
    loading: false
  } : _ref4$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/bugfix/add_judian'
  }, config));
}; // 局点编辑删除


exports.partEdit = partEdit;

var partDelete = function partDelete() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$query = _ref5.query,
      query = _ref5$query === void 0 ? {} : _ref5$query,
      _ref5$config = _ref5.config,
      config = _ref5$config === void 0 ? {
    loading: false
  } : _ref5$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/bugfix/delete_judian'
  }, config));
}; // 客户查询


exports.partDelete = partDelete;

var customerlist = function customerlist() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$query = _ref6.query,
      query = _ref6$query === void 0 ? {} : _ref6$query,
      _ref6$config = _ref6.config,
      config = _ref6$config === void 0 ? {
    loading: false
  } : _ref6$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/customer/customer_list'
  }, config));
}; // 客户新增


exports.customerlist = customerlist;

var customerAdd = function customerAdd() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$query = _ref7.query,
      query = _ref7$query === void 0 ? {} : _ref7$query,
      _ref7$data = _ref7.data,
      data = _ref7$data === void 0 ? {} : _ref7$data,
      _ref7$config = _ref7.config,
      config = _ref7$config === void 0 ? {
    loading: false
  } : _ref7$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/customer/customer_add'
  }, config));
}; // 客户编辑初始化


exports.customerAdd = customerAdd;

var customerEdit = function customerEdit() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref8$query = _ref8.query,
      query = _ref8$query === void 0 ? {} : _ref8$query,
      _ref8$config = _ref8.config,
      config = _ref8$config === void 0 ? {
    loading: false
  } : _ref8$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/customer/customer_edit'
  }, config));
}; // 客户编辑updata


exports.customerEdit = customerEdit;

var customerUpdate = function customerUpdate() {
  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref9$query = _ref9.query,
      query = _ref9$query === void 0 ? {} : _ref9$query,
      _ref9$data = _ref9.data,
      data = _ref9$data === void 0 ? {} : _ref9$data,
      _ref9$config = _ref9.config,
      config = _ref9$config === void 0 ? {
    loading: false
  } : _ref9$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/customer/customer_update'
  }, config));
}; // 管理uin列表


exports.customerUpdate = customerUpdate;

var uinlist = function uinlist() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref10$query = _ref10.query,
      query = _ref10$query === void 0 ? {} : _ref10$query,
      _ref10$config = _ref10.config,
      config = _ref10$config === void 0 ? {
    loading: false
  } : _ref10$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/customer/uin_list'
  }, config));
}; // 管理uin 新增


exports.uinlist = uinlist;

var uinadd = function uinadd() {
  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref11$query = _ref11.query,
      query = _ref11$query === void 0 ? {} : _ref11$query,
      _ref11$config = _ref11.config,
      config = _ref11$config === void 0 ? {
    loading: false
  } : _ref11$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/customer/uin_add'
  }, config));
}; // 管理uin 删除


exports.uinadd = uinadd;

var uindelete = function uindelete() {
  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref12$query = _ref12.query,
      query = _ref12$query === void 0 ? {} : _ref12$query,
      _ref12$config = _ref12.config,
      config = _ref12$config === void 0 ? {
    loading: false
  } : _ref12$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/customer/uin_delete'
  }, config));
};

exports.uindelete = uindelete;