var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-autocomplete", {
    attrs: {
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
      "trigger-on-focus": false,
      "fetch-suggestions": _vm.queryUser,
      "value-key": "user_name"
    },
    on: {
      select: _vm.handleSelect,
      input: _vm.handleInput,
      blur: _vm.handleBlur
    },
    model: {
      value: _vm.queryString,
      callback: function($$v) {
        _vm.queryString = $$v
      },
      expression: "queryString"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }