"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: '1',
  // icon: 'el-icon-s-platform',
  name: '我的订单',
  opened: false,
  children: [{
    index: '1-1',
    // icon: 'el-icon-s-data',
    name: '我的A类订单',
    href: '/workbench/a',
    permission: '',
    description: '我提交的A类订单'
  }, // {
  //   index: '1-2',
  //   // icon: 'el-icon-menu',
  //   name: '我的BCD类订单',
  //   href: '/workbench/bcd',
  //   permission: '',
  //   description: '我提交的BCD类订单'
  // },
  {
    index: '1-3',
    // icon: 'el-icon-menu',
    name: '我的E类订单',
    href: '/workbench/e',
    permission: 'DELIVER.MY_WORKBENCH.SEE_E_FILLING',
    description: '我提交的E类订单'
  } // {
  //   index: '1-4',
  //   // icon: 'el-icon-menu',
  //   name: 'E类订单填报工作量',
  //   href: '/workbench/workhoursreport-e',
  //   permission: 'DELIVER.MY_WORKBENCH.SEE_E_FILLING',
  //   description: 'E类订单工时上报'
  // }
  ]
}, {
  index: '2',
  // icon: 'el-icon-s-promotion',
  name: '计划报备',
  opened: false,
  children: [{
    index: '2-1',
    // icon: 'el-icon-edit',
    name: '新建计划',
    href: '/workbench/newplan',
    permission: '',
    description: '新建计划信息并提交审批'
  }, {
    index: '2-2',
    // icon: 'el-icon-edit-outline',
    name: '我提交的计划',
    href: '/workbench/submitplan',
    permission: '',
    description: '我发起的计划审批单'
  }, {
    index: '2-3',
    // icon: 'el-icon-finished',
    name: '计划确认',
    href: '/workbench/reviewplan',
    permission: '',
    description: '查看我的计划单状态及补录'
  }]
}, {
  index: '3',
  // icon: 'el-icon-s-promotion',
  name: '我的审批',
  opened: false,
  children: [{
    index: '3-1',
    // icon: 'el-icon-edit',
    name: '计划申请审批',
    href: '/workbench/approvalplan',
    permission: '',
    description: '由我审批的计划申请信息'
  }, {
    index: '3-2',
    // icon: 'el-icon-edit-outline',
    name: '计划确认审批',
    href: '/workbench/overplan',
    permission: '',
    description: '由我审批的计划确认信息'
  }]
}, {
  index: '4',
  // icon: 'el-icon-s-promotion',
  name: '我的任务',
  opened: false,
  children: [{
    index: '4-1',
    // icon: 'el-icon-edit',
    name: '我的任务',
    href: '/workbench/task-list',
    permission: '',
    description: '我的任务列表'
  }, {
    index: '4-2',
    // icon: 'el-icon-edit-outline',
    name: '我的工作流',
    href: '/workbench/work-flow',
    permission: '',
    description: '我的工作流'
  }]
}, {
  index: '5',
  name: '需求评审',
  href: '/workbench/project-review',
  permission: '',
  description: '我的项目评审列表'
}, {
  index: '6',
  // icon: 'el-icon-tickets',
  name: '我的工时',
  href: '/workbench/workhoursreport-my',
  permission: '',
  description: '我的工时数据上报'
}, {
  index: '8',
  // icon: 'el-icon-s-tools',
  name: '我的工具',
  opened: false,
  children: [{
    index: '8-1',
    // icon: 'el-icon-edit',
    name: 'A类订单作废',
    href: '/workbench/tools/cancel/',
    permission: 'DELIVER.ORDER.TOOL.AORDERDELETE',
    description: 'A类订单作废'
  }, {
    index: '8-2',
    // icon: 'el-icon-edit-outline',
    name: 'A类订单变更',
    href: '/workbench/tools/change/',
    permission: 'DELIVER.ORDER.TOOL.AORDERCHANGE',
    description: 'A类订单变更'
  }, {
    index: '8-3',
    // icon: 'el-icon-edit-outline',
    name: 'BCD类账单冲红',
    href: '/workbench/tools/red/',
    permission: 'DELIVER.BILL.TOOL.BILLRED',
    description: 'BCD类账单冲红'
  }, {
    index: '8-4',
    // icon: 'el-icon-edit-outline',
    name: 'BCD类账单解封',
    href: '/workbench/tools/unblock/',
    permission: 'DELIVER.BILL.TOOL.BILLUNLOCK',
    description: 'BCD类账单解封'
  }, {
    index: '8-5',
    // icon: 'el-icon-edit-outline',
    name: '审批人变更',
    href: '/workbench/tools/approval-change/',
    permission: 'DELIVER.PROJECT.APPROVAL_PERSON_UPDATE',
    description: '审批人变更'
  }, {
    index: '8-6',
    // icon: 'el-icon-edit-outline',
    name: '账单推送',
    href: '/workbench/tools/report-bill/',
    permission: 'DELIVER.MY_WORKBENCH.BILL.MONTH_PUSH',
    description: '账单推送'
  }]
}];
var _default = items;
exports["default"] = _default;