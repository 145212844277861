var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        filterable: "",
        remote: "",
        multiple: _vm.multiple,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder,
        "multiple-limit": _vm.multipleLimit,
        "remote-method": _vm.requestSearchUser,
        loading: _vm.loading,
        clearable: _vm.clearable
      },
      on: { change: _vm.onChange },
      model: {
        value: _vm.svalue,
        callback: function($$v) {
          _vm.svalue = $$v
        },
        expression: "svalue"
      }
    },
    _vm._l(_vm.options, function(item) {
      return _c("el-option", {
        key: item.name,
        attrs: {
          label: item.name + "(" + item.nameZh + ")",
          value:
            _vm.valueFormat === "en" ? item.name : item.name + "-" + item.nameZh
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }