//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import options from 'src/modules/components/map'
import { planDetail, uploadUrl } from 'src/pages/workbench/api'

export default {
  data () {
    let checkPrice = (rule, value, callback) => {
      if (this.form.real_price === 0) {
        return callback(new Error('请输入实际交通费'))
      } else {
        callback()
      }
    }
    let checkFile = (rule, value, callback) => {
      if (this.filelist.length < 1) {
        return callback(new Error('请上传票据'))
      } else {
        callback()
      }
    }
    return {
      // type作为提交弹窗的显示类型判断 - 3：实际提报 4：票据上传 5-重新提交（和3一样）
      propInfo: { po_id: '', planType: '' },
      transportTypes: [{ label: '机票经济舱', value: '1' }, { label: '高铁二等座', value: '2' }, { label: '动车二等座', value: '3' }, { label: '火车票', value: '4' }, { label: '其他', value: '5' }],
      dialogVisible: false,
      uploadParams: {},
      form: { jtime: [], real_price: 0, real_reason: '', attachment_id: '', real_outset: [], real_destination: [], real_is_go_back: '', real_traffic_type: '' },
      fileSuccessIds: [],
      rules: {
        real_outset: [{ required: true, message: '请选择出发城市', trigger: 'change' }],
        real_destination: [{ required: true, message: '请选择目的城市', trigger: 'change' }],
        real_traffic_type: [{ required: true, message: '请选择计划交通类型', trigger: 'change' }],
        real_is_go_back: [{ required: true, message: '请选择去返程类型', trigger: 'change' }],
        jtime: [{ required: true, message: '请选择实际发生时间', trigger: 'change' }],
        real_price: [{ validator: checkPrice, trigger: 'change' }],
        real_reason: [{ required: true, message: '请输入发生原因', trigger: 'change' }],
        files: [{ validator: checkFile, trigger: 'change' }]
      },
      filelist: []
    }
  },
  computed: {
    // 文件上传地址
    uploadlink () {
      return uploadUrl()
    },
    options () {
      return options
    }
  },
  mounted () {
    this.$on('open', (val) => {
      this.dialogVisible = true
      this.propInfo = val
      if (val.postType === 'again') {
        this.init(val.po_id)
      }
      this.uploadParams = { po_id: val.po_id }
    })
    this.$on('hide', () => {
      this.closeAction('form')
    })
  },
  methods: {
    // 初始化
    init (pid) {
      planDetail({ query: { po_id: pid } }).then(({ data }) => {
        if (data && data.code === 0) {
          this.form = { ...data.data, jtime: [data.data.real_begin_time || '', data.data.real_end_time || ''] }
          if (this.propInfo.planType === 'transportation') {
            let fileArr = this.form.attachment || []
            this.filelist = fileArr.map((el) => {
              this.fileSuccessIds.push({
                id: el.id,
                file_name: el.file_name,
                realfile_name: el.realfile_name
              })
              return {
                name: el.realfile_name,
                url: el.file_url
              }
            })
          }
        }
      })
    },
    // 提交
    confrimAction (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            po_id: this.propInfo.po_id,
            real_price: this.form.real_price,
            real_reason: this.form.real_reason,
            real_outset: this.form.real_outset,
            real_destination: this.form.real_destination,
            real_is_go_back: this.form.real_is_go_back,
            real_traffic_type: this.form.real_traffic_type,
            real_begin_time: '',
            real_end_time: '',
            attachment_id: this.fileSuccessIds.map((el) => el.id).join(';')
          }
          if (Array.isArray(this.form.jtime)) {
            if (this.form.jtime.length === 2) {
              params.real_begin_time = this.form.jtime[0]
              params.real_end_time = this.form.jtime[1]
            } else {
              params.real_begin_time = ''
              params.real_end_time = ''
            }
          }
          if (Array.isArray(this.form.real_outset)) {
            if (this.form.real_outset.length === 2) {
              params.real_outset = this.form.real_outset[1]
            } else if (this.form.real_outset.length === 1) {
              params.real_outset = this.form.real_outset[0]
            } else {
              params.real_outset = ''
            }
          }
          if (Array.isArray(this.form.real_destination)) {
            if (this.form.real_destination.length === 2) {
              params.real_destination = this.form.real_destination[1]
            } else if (this.form.real_destination.length === 1) {
              params.real_destination = this.form.real_destination[0]
            } else {
              params.real_destination = ''
            }
          }
          this.$emit('confrim', params, this.propInfo.planType)
        } else {
          return false
        }
      })
    },
    //  根据计划类型判断详情信息是否显示
    isShowRules (sine) {
      /**
       *sine 作为出发目的城市标识
       */
      if (this.propInfo.planType !== '' && this.propInfo.planType !== 'transportation') {
        if (this.propInfo.planType !== 'subsidy') {
          return false
        } else {
          if (sine) return true
          return false
        }
      }
      return true
    },
    // 关闭事件清空输入条件
    closeAction (formName) {
      this.dialogVisible = false
      this.$refs[formName].resetFields()
      this.fileSuccessIds = []
    },
    // 上传成功之后的钩子
    handleSuccessUpload (res, file, filelist) {
      if (res && res.code === 0) {
        this.filelist = filelist
        this.$refs.uploadfile.clearValidate()
        this.fileSuccessIds.push(res.data)
        this.$message({ type: 'success', message: res.message || '上传成功' })
      } else {
        this.$message({ type: 'error', message: res.message || '上传失败' })
      }
    },
    // 移除文件之后的钩子
    handleRemove (file, filelist) {
      // 针对已上传成功的文件进行删除操作 - 由于未通过校验的文件上传会自动触发移除事件和移除之后的事件钩子
      if (file.status === 'success') {
        this.filelist = filelist
        this.fileSuccessIds = this.fileSuccessIds.filter((el) => {
          return el.realfile_name !== file.name
        })
      }
    },
    // 上传文件之前的钩子 若返回 false 或者返回 Promise 且被 reject 则停止上传
    handleBeforeUpload (file) {
      if (!file.type.startsWith('image')) {
        this.$message({ type: 'warning', message: '只能上传图片文件' })
        return false
      }
    },
    beforeRemove (file, filelist) {
      // 针对已上传成功的文件进行删除操作 - 由于未通过校验的文件上传会自动触发移除事件和移除之后的事件钩子
      if (file.status === 'success') return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 超出限制上传个数
    handleExceed (files, filelist) {
      this.$message.warning(`当前限制上传 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + filelist.length} 个文件`)
    }
  }
}
