//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      form: { product_name: '' }
    };
  },
  props: {
    queryField: String
  },
  created () {
    this.form.product_name = this.queryField
  },
  watch: {
    form: {
      handler () {
        this.$emit('input', Object.entries({
          ...this.form
        }).reduce((obj, [k, v]) => {
          v && (obj[k] = v);
          return obj
        }, {}));
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    query () {
      this.$emit('query');
    },
    clear () {
      this.$refs.queryForm.resetFields();
      this.form.product_name = ''
    }
  }
};
