//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserChooser from 'src/modules/components/ui-userchooser';
import CompanyUserChooser from 'src/modules/components/company-user-chooser';
export default {
  components: {
    UserChooser,
    CompanyUserChooser
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    isInputName: {
      type: Boolean,
      default: true
    },
    defaultCurrentStaff: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    singleton: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    $user () {
      if (this.defaultCurrentStaff) {
        this.setToCurrentStaff();
      }
    },
    disabled (val) {
      if (!val && this.defaultCurrentStaff) {
        this.setToCurrentStaff();
      }
    }
  },
  methods: {
    handleInput (val) {
      this.$emit('input', val);
    },
    setToCurrentStaff () {
      const value = this.$user.is_oa || !this.isInputName ? this.$user.user_id : this.$user.user_id;
      this.handleInput(value);
    }
  },
  created () {
    if (this.defaultCurrentStaff && !this.disabled) {
      this.setToCurrentStaff();
    }
  }
}
