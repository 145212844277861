var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticClass: "table-content",
      attrs: { data: _vm.tableData, border: "" }
    },
    [
      _c("el-table-column", { attrs: { prop: "name", label: "字段名" } }),
      _vm._v(" "),
      _c("el-table-column", { attrs: { prop: "description", label: "描述" } }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "input_value", label: "详情", "min-width": "300" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _vm.regExpFun(row.input_value)
                  ? _c(
                      "el-link",
                      {
                        attrs: {
                          href: _vm.getHref(row.input_value),
                          underline: false,
                          type: "primary",
                          target: "_blank"
                        }
                      },
                      [
                        _c("span", { staticClass: "content-pre" }, [
                          _vm._v(_vm._s(row.input_value))
                        ])
                      ]
                    )
                  : row.field_type === "radio" || row.field_type === "select"
                  ? _c("span", { staticClass: "content-pre" }, [
                      _vm._v(
                        _vm._s(_vm.mateOption(row.option_json, row.input_value))
                      )
                    ])
                  : row.field_type === "checkbox"
                  ? _c("span", { staticClass: "content-pre" }, [
                      _vm._v(
                        _vm._s(_vm.mateArray(row.option_json, row.input_value))
                      )
                    ])
                  : _c("span", { staticClass: "content-pre" }, [
                      _vm._v(_vm._s(row.input_value))
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", { attrs: { prop: "note", label: "备注" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }