"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.regExpFun = exports.getURIParams = exports.getURIParam = exports.hasURIParam = void 0;

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _queryString = _interopRequireDefault(require("query-string"));

var URIParams = {};
var hasURIParam = false // 立即执行并记录参数，只使用页面访问时的参数
;
exports.hasURIParam = hasURIParam;

(function () {
  var _query = location.search;
  _query = _query.replace('?', '');

  if (_query.length > 0) {
    exports.hasURIParam = hasURIParam = true;
  }

  URIParams = _queryString["default"].parse(location.search, {
    arrayFormat: 'bracket'
  });
  URIParams = JSON.parse((0, _stringify["default"])(URIParams, function (key, value) {
    if (typeof value === 'string' && !isNaN(value)) {
      return (0, _parseInt2["default"])(value);
    } else {
      return value;
    }
  }));
})(); // 获取URI参数


var getURIParam = function getURIParam(paramKey) {
  return URIParams[paramKey];
};

exports.getURIParam = getURIParam;

var getURIParams = function getURIParams() {
  return URIParams;
}; // 校验是否为链接


exports.getURIParams = getURIParams;

var regExpFun = function regExpFun(field) {
  var reg = /(http|ftp|https):\/\/([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+/;
  return reg.test(field);
};

exports.regExpFun = regExpFun;