//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GetEnumsValueMixin from 'src/modules/mixins/get-enums-value';
import UserPermissionMixin from 'src/modules/mixins/user-permission';
import nl2br from 'nl2br';
import { escape } from 'underscore';

export default {
  name: 'template-list',
  mixins: [GetEnumsValueMixin, UserPermissionMixin],
  // 过滤器，控制文本显示
  filters: {
    mapArray (val) {
      return Array.isArray(val) ? val.join(',') : val;
    },
    mapDefault (value) {
      return value || '--';
    }
  },
  // 动态传入配置和数据
  props: {
    configs: {
      type: Array,
      default () {
        return [];
      }
    },
    datas: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  computed: {
    hasExpandFiled () {
      const config = this.configs || [];
      return config.some(list => list.isExpand);
    }
  },
  methods: {
    showDetail (result = '') {
      return nl2br(escape(result));
    }
  }
}
