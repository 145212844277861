//
//
//
//
//
//

import ElColumn from 'src/modules/components/ElementTable/components/column';
export default {
  components: {
    ElColumn
  },
  props: {
    result: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    safeData() {
      return Array.isArray(this.result) ? this.result : [];
    }
  },
  data() {
    return {
      columns: [
        { prop: 'create_time', label: '审批时间' },
        { prop: 'operate', label: '审批人' },
        {
          prop: 'is_pass',
          label: '审批结果',
          render(h, it) {
            const ispass = it.is_pass;
            if (ispass === 0) {
              return h('el-tag', { props: { type: 'danger' } }, '未通过');
            }
            if (ispass === 1) {
              return h('el-tag', { props: { type: 'success' } }, '通过');
            }
            return ispass;
          }
        },
        { prop: 'approval_reason', label: '审批意见' }
      ]
    };
  }
};
