"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _vue = _interopRequireDefault(require("vue"));

var _http = _interopRequireDefault(require("src/libs/plugins/http"));

var _router = _interopRequireDefault(require("src/libs/plugins/router"));

var _user = _interopRequireDefault(require("src/libs/plugins/user.js"));

require("src/libs/theme.js");

var _uri = require("src/modules/utils/uri");

var _postFormat = require("src/modules/utils/post-format");

var _pageView = _interopRequireDefault(require("src/libs/pageView.js"));

var _aegisWebSdk = _interopRequireDefault(require("@tencent/aegis-web-sdk"));

var href = window.location.href;

if (new RegExp(/\/\/(redcs)|(tencent)\.oa\.com/).test(href)) {
  // 上报阿塔系统pvuv
  (0, _pageView["default"])();
} // 引入后进行初始化


var aegis = new _aegisWebSdk["default"]({
  id: 'htdfvvxjeucyNlCOXC',
  uin: '',
  reportApiSpeed: true,
  reportAssetSpeed: true
});

_vue["default"].use(_user["default"]);

_vue["default"].use(_http["default"]);

_vue["default"].use(_router["default"]);

_vue["default"].prototype.$ELEMENT = {
  size: 'small'
}; // Vue原型拓展 获取参数方法

_vue["default"].prototype.$getQuery = _uri.getURIParam;
_vue["default"].prototype.$regExpUrl = _uri.regExpFun; // Vue原型拓展 查询参数格式化---数组转字符串

_vue["default"].prototype.$formatData = _postFormat.postDataFormat;
_vue["default"].config.performance = true;

_vue["default"].config.errorHandler = function (err, vm, info) {
  var _context, _context2;

  aegis.error((0, _concat["default"])(_context = (0, _concat["default"])(_context2 = "Error: ".concat(err.toString(), "\nStack: ")).call(_context2, err.stack, "\nInfo: ")).call(_context, info));
};

var _default = _vue["default"];
exports["default"] = _default;