//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  refreshBill,
  turnBillStatus,
  returnStatus,
  billconfirm,
  billconfirmGet,
  deleteBill
} from 'src/pages/deliver/bill/config/api';

export default {
  props: {
    query: {
      type: Object,
      default: () => {}
    },
    multiples: Array,
    queryField: String,
    type: String,
    total: Number
  },
  data() {
    return {
      activeName: '',
      disabledEast: true,
      disabledWest: true,
      disabledSouth: true,
      disabledNorth: true
    };
  },
  computed: {
    query_field() {
      return JSON.stringify(this.multiples.map((i) => i[this.queryField]));
    }
  },
  mounted() {
    // 页面上已经根据权限显示tabs此处仅需默认给到有权限的第一个tab即可  操作日志不行
    if (this.buttonShow('DELIVER.BILL.SEE_STASTUS_ALL')) {
      this.activeName = '1';
    } else if (this.buttonShow('DELIVER.BILL.SEE_STASTUS_0')) {
      this.activeName = '2';
    } else if (this.buttonShow('DELIVER.BILL.TURN_GRAY')) {
      this.activeName = '-1';
    } else if (this.buttonShow('DELIVER.BILL.SEE_STASTUS_1')) {
      this.activeName = '3';
    } else if (this.buttonShow('DELIVER.BILL.SEE_STASTUS_2')) {
      this.activeName = '4';
    } else if (this.buttonShow('DELIVER.BILL.SEE_STASTUS_3')) {
      this.activeName = '5';
    } else {
      this.activeName = '6';
    }

    billconfirmGet({
      query: {
        order_type: this.type
      }
    }).then(({ data = {} }) => {
      const confirmArea = data && data.confirm_area;
      if (confirmArea) {
        this.disabledEast = Boolean(confirmArea.east);
        this.disabledWest = Boolean(confirmArea.west);
        this.disabledSouth = Boolean(confirmArea.south);
        this.disabledNorth = Boolean(confirmArea.north);
      }
    });
  },
  methods: {
    // tabs切换===>暂时无用
    handleClick() {
      this.$emit('tab-change', this.activeName);
    },
    // 按钮权限控制显示隐藏
    buttonShow(permissionCode) {
      return this.$user.permissions.includes(permissionCode);
    },
    buttonShowWithArea(area) {
      if (this.type === 'A') {
        if (area === 'east') {
          return this.buttonShow('DELIVER.BILL.CONFIRM_A_STATUS_0_EAST');
        }
        if (area === 'west') {
          return this.buttonShow('DELIVER.BILL.CONFIRM_A_STATUS_0_WEST');
        }
        if (area === 'south') {
          return this.buttonShow('DELIVER.BILL.CONFIRM_A_STATUS_0_SOUTH');
        }
        if (area === 'north') {
          return this.buttonShow('DELIVER.BILL.CONFIRM_A_STATUS_0_NORTH');
        }
      }
      if (this.type === 'BCD') {
        if (area === 'east') {
          return this.buttonShow('DELIVER.BILL.CONFIRM_BCD_STATUS_0_EAST');
        }
        if (area === 'west') {
          return this.buttonShow('DELIVER.BILL.CONFIRM_BCD_STATUS_0_WEST');
        }
        if (area === 'south') {
          return this.buttonShow('DELIVER.BILL.CONFIRM_BCD_STATUS_0_SOUTH');
        }
        if (area === 'north') {
          return this.buttonShow('DELIVER.BILL.CONFIRM_BCD_STATUS_0_NORTH');
        }
      }
      return false;
    },
    confirmEmail(area) {
      this.$confirm(`是否确认可发送周知邮件`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (area === 'east') {
          this.disabledEast = true;
        }
        if (area === 'west') {
          this.disabledWest = true;
        }
        if (area === 'south') {
          this.disabledSouth = true;
        }
        if (area === 'north') {
          this.disabledNorth = true;
        }
        billconfirm({
          data: {
            area,
            order_type: this.type
          }
        }).then(({ data }) => {
          if (data.code === 0) {
            this.$message.success('确认成功');
          }
        });
      });
    },
    // 重刷账单
    refreshAndReload() {
      this.$emit('refreshAndReload');
    },
    // 下载账单===>暂无使用
    downloadFile(blobUrl) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.download = '账单.csv';
      link.href = blobUrl;
      link.target = '_blank';
      link.click();
      URL.revokeObjectURL(link.href);
    },
    // 重刷账单
    refreshBill() {
      if (this.multiples.length < 1) {
        this.$message({
          message: '请选择要操作的数据行',
          type: 'warning',
          center: true
        });
        return;
      }
      this.$confirm('确认重刷所选的账单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: '账单刷新中,请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          const { type } = this.query;
          const query = {
            acceptance_report_number: this.query_field
          };
          type && (query['type'] = type);
          const { data = {} } = await refreshBill({ query });
          this.refreshAndReload();
          if (data && data.code === 0) {
            this.$message({
              message: '账单刷新成功',
              type: 'success'
            });
          }
          loading.close();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
    },
    // 扭转状态
    turnBillStatus(toStatus) {
      if (this.multiples.length < 1) {
        this.$message({
          message: '请选择要操作的数据行',
          type: 'warning',
          center: true
        });
        return;
      }
      let msg = '';
      switch (toStatus) {
        case 0:
          msg = '此操作将账单状态变更为 “初始账单”';
          break;
        case -1:
          msg = '此操作将账单状态变更为 “问题账单”';
          break;
        case 1:
          msg = '此操作将账单状态变更为 “已出账”';
          break;
        case 2:
          msg = '此操作将账单状态变更为 “确认结算”';
          break;
        case 3:
          msg = '此操作将账单状态变更为 “已提交”';
          break;
        case 4:
          msg = '此操作将账单状态变更为 “已结算”，且不可撤回';
          break;
      }
      this.$confirm(`${msg}，是否继续`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { type } = this.query;
          const query = {
            acceptance_report_number: this.query_field,
            to_status: toStatus
          };
          type && (query['type'] = type);
          const { data } = await turnBillStatus({ query });
          if (data && data.code === 0) {
            this.$message({
              message: '账单状态已变更',
              type: 'success'
            });
            this.refreshAndReload();
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
    },
    // 撤回
    changeStatus() {
      if (this.multiples.length < 1) {
        this.$message({
          message: '请选择要操作的数据行',
          type: 'warning',
          center: true
        });
        return;
      }
      this.$confirm('确认撤回所选的账单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: '撤回执行中,请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          const { type } = this.query;
          const query = { id: this.multiples.map((i) => i.id).join() };
          type && (query['type'] = type);
          returnStatus({ query }).then(({ data }) => {
            loading.close();
            if (data && data.code === 0) {
              this.$message({
                message: '账单状态已变更',
                type: 'success'
              });
              this.refreshAndReload();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
    },

    // 删除问题账单
    deleteBill() {
      if (this.multiples.length < 1) {
        this.$message({
          message: '请选择要操作的数据行',
          type: 'warning',
          center: true
        });
        return;
      }

      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteBill({
          data: {
            op_type: 203,
            acceptance_report_number: this.multiples.map((i) => i[this.queryField])
          }
        }).then(({ data }) => {
          if (data.code === 0) {
            this.$message.success('删除成功');
            this.refreshAndReload();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
  watch: {
    activeName(v) {
      this.$emit('tab-change', this.activeName);
    }
  }
};
