"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.planStatus = exports.trafficType = exports.isGoBack = exports.planType = void 0;

// 计划类型
var planType = function planType(v) {
  var msg = '';

  switch (v) {
    case 'overtime':
      msg = '加班';
      break;

    case 'leave':
      msg = '请假';
      break;

    case 'subsidy':
      msg = '出差';
      break;

    case 'transportation':
      msg = '差旅交通费';
      break;

    default:
      msg = '';
      break;
  }

  return msg;
}; // 去返程


exports.planType = planType;

var isGoBack = function isGoBack(v) {
  var msg = '';
  if (v !== '') v = Number(v);

  switch (v) {
    case 1:
      msg = '去程';
      break;

    case 2:
      msg = '返程';
      break;

    default:
      msg = '';
      break;
  }

  return msg;
}; // 交通类型


exports.isGoBack = isGoBack;

var trafficType = function trafficType(v) {
  var msg = '';
  if (v !== '') v = Number(v);

  switch (v) {
    case 1:
      msg = '机票经济舱';
      break;

    case 2:
      msg = '高铁二等座';
      break;

    case 3:
      msg = '动车二等座';
      break;

    case 4:
      msg = '火车票';
      break;

    case 5:
      msg = '其他';
      break;

    default:
      msg = '';
      break;
  }

  return msg;
}; // 计划单状态


exports.trafficType = trafficType;

var planStatus = function planStatus(v) {
  var msg = '';

  switch (v) {
    case 100:
      msg = '新建计划';
      break;

    case 101:
      msg = '计划申请-区技驳回';
      break;

    case 200:
      msg = '计划申请-待区技审批';
      break;

    case 201:
      msg = '计划申请-需求方驳回待区技处理';
      break;

    case 300:
      msg = '计划申请-待需求方审批';
      break;

    case 400:
      msg = '待确认';
      break;

    case 401:
      msg = '计划确认-区技驳回';
      break;

    case 402:
      msg = '取消计划';
      break;

    case 500:
      msg = '计划确认-待区技审批';
      break;

    case 501:
      msg = '计划确认-超预算-需求方驳回待区技处理';
      break;

    case 600:
      msg = '计划确认-超预算-待需求方审批';
      break;

    case 700:
      msg = '计划确认-审批通过';
      break;

    default:
      msg = '';
      break;
  }

  return msg;
};

exports.planStatus = planStatus;