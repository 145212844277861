var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      attrs: { "tab-position": "left", size: "medium" },
      model: {
        value: _vm.activeName,
        callback: function($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName"
      }
    },
    _vm._l(_vm.modules, function(ref) {
      var code = ref.code
      var name = ref.name
      var children = ref.children
      return _c(
        "el-tab-pane",
        { key: code, attrs: { label: name, lazy: true, name: code } },
        [
          _c("series-component", {
            attrs: {
              module: code,
              serieses: children,
              "my-tree-permissions": _vm.value
            },
            on: { "update-permissions": _vm.updatePermissions }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }