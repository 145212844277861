var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isShow,
        "close-on-click-modal": false,
        "close-on-press-escape": false
      },
      on: {
        "update:visible": function($event) {
          _vm.isShow = $event
        },
        close: function($event) {
          return _vm.closeDialog("form")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-title", attrs: { slot: "title" }, slot: "title" },
        [
          _vm.form.po_id !== 0
            ? _c("span", [_vm._v("修改计划")])
            : _c("div", [
                _c("span", [_vm._v("新增计划")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "des" }, [
                  _vm._v(
                    "温馨提示：仅支持B类、D3类订单提交提交法定节假日加班申请"
                  )
                ])
              ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "form",
          attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      class: [
                        "is-required",
                        _vm.orderInfo.split_task_name ? "no-mb" : ""
                      ],
                      attrs: { label: "订单编号", prop: "order_id" }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入订单编号" },
                        on: {
                          blur: function($event) {
                            return _vm.handleChangeOrder(_vm.form.order_id)
                          }
                        },
                        nativeOn: {
                          keypress: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleEnter($event)
                          }
                        },
                        model: {
                          value: _vm.form.order_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "order_id", $$v)
                          },
                          expression: "form.order_id"
                        }
                      }),
                      _vm._v(" "),
                      _vm.orderMembers.length > 0
                        ? _c("div", { staticClass: "txt-info" }, [
                            _vm._v(_vm._s(_vm.orderInfo.split_task_name))
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "applicant",
                      attrs: { label: "申请人", prop: "applicant" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: _vm.isMultiple,
                            placeholder: "请选择申请人"
                          },
                          model: {
                            value: _vm.form.applicant,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "applicant", $$v)
                            },
                            expression: "form.applicant"
                          }
                        },
                        _vm._l(_vm.orderMembers, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "计划类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择计划类型" },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        _vm._l(_vm.planTypes, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              disabled: _vm.typeDisabled(item),
                              label: item.label,
                              value: item.value
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始/结束时间", prop: "jtime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "prefix-icon": "el-icon-date",
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "-",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.form.jtime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "jtime", $$v)
                          },
                          expression: "form.jtime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请理由", prop: "apply_reason" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2 },
                          placeholder: "请输入内容",
                          maxlength: "200",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.apply_reason,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "apply_reason", $$v)
                          },
                          expression: "form.apply_reason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isShowRules(["outset", "destination"], 1)
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "计划出发城市", prop: "outset" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "show-all-levels": false,
                              filterable: "",
                              options: _vm.options
                            },
                            model: {
                              value: _vm.form.outset,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "outset", $$v)
                              },
                              expression: "form.outset"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "计划目的城市", prop: "destination" }
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "show-all-levels": false,
                              filterable: "",
                              options: _vm.options
                            },
                            model: {
                              value: _vm.form.destination,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "destination", $$v)
                              },
                              expression: "form.destination"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowRules(["is_go_back", "traffic_type"])
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "去/返程", prop: "is_go_back" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择去/返程类型" },
                              model: {
                                value: _vm.form.is_go_back,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "is_go_back", $$v)
                                },
                                expression: "form.is_go_back"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "去程", value: 1 }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "返程", value: 2 }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "计划交通类型", prop: "traffic_type" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择计划交通类型" },
                              model: {
                                value: _vm.form.traffic_type,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "traffic_type", $$v)
                                },
                                expression: "form.traffic_type"
                              }
                            },
                            _vm._l(_vm.transportTypes, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowRules(["predict_price"])
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "预计金额", prop: "predict_price" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "controls-position": "right",
                              precision: 2,
                              step: 0.01
                            },
                            model: {
                              value: _vm.form.predict_price,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "predict_price", $$v)
                              },
                              expression: "form.predict_price"
                            }
                          }),
                          _vm._v(" "),
                          _vm.isMultiple && _vm.form.applicant.length > 1
                            ? _c("div", { staticClass: "txt-info" }, [
                                _vm._v(
                                  "请输入单人预计金额，并确保多个申请人申请内容一致"
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.isShow = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.isSubing },
              on: {
                click: function($event) {
                  return _vm.confirmDialog("form")
                }
              }
            },
            [_vm._v(_vm._s(_vm.isSubing ? "正在提交" : "确 定"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }