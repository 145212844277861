var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$user.is_oa
        ? _c("user-chooser", {
            attrs: {
              value: _vm.value,
              disabled: _vm.disabled,
              singleton: _vm.singleton
            },
            on: { input: _vm.handleInput }
          })
        : _c("company-user-chooser", {
            attrs: {
              value: _vm.value,
              "is-input-name": _vm.isInputName,
              disabled: _vm.disabled,
              singleton: _vm.singleton
            },
            on: { input: _vm.handleInput }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }