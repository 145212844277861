var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.permission || _vm.hasPermission(_vm.permission)
    ? _c(
        "el-card",
        {
          staticClass: "card-container",
          attrs: {
            flex: "box:mean",
            shadow: "hover",
            "body-style": { padding: "5px 10px" }
          },
          nativeOn: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.redirect(_vm.href)
            }
          }
        },
        [
          _c("div", { attrs: { flex: "main:justify cross:center" } }, [
            _c("div", { attrs: { flex: "dir:top" } }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.name))
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.description))])
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "card-icon", class: _vm.icon })
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }