"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _vue = _interopRequireDefault(require("vue"));

var _elementUi = require("element-ui");

function openLoading() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (config.loading) {
    config.loading = _elementUi.Loading.service({
      fullscreen: true
    });
  }

  return config;
}

function closeLoading() {
  var response = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var config = response.config || {};

  if (config.loading && config.loading.close) {
    _vue["default"].nextTick(function () {
      config.loading.close();
      config.loading = undefined;
    });
  }
}

var _default = {
  requestResolve: openLoading,
  responseResolve: function responseResolve(response) {
    closeLoading(response);
    return response;
  },
  responseReject: function responseReject(error) {
    closeLoading(error);
    return _promise["default"].reject(error);
  }
};
exports["default"] = _default;