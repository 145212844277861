"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _expand = _interopRequireDefault(require("./expand"));

var _default2 = {
  name: 'ElTableColumn',
  functional: true,
  components: {
    Expand: _expand["default"]
  },
  props: {
    columns: Array
  },
  render: function render(h, context) {
    var _context, _context2;

    return (0, _map["default"])(_context = (0, _filter["default"])(_context2 = context.props.columns).call(_context2, function (_ref) {
      var hidden = _ref.hidden;
      return !hidden;
    })).call(_context, function (props, index) {
      var data = {
        props: props
      };

      if (props.render) {
        data.scopedSlots = {
          "default": function _default(scope) {
            return h(_expand["default"], {
              props: {
                render: props.render,
                scope: scope,
                index: index,
                key: scope.$index
              }
            });
          }
        };
      }

      return h('el-table-column', data);
    });
  }
};
exports["default"] = _default2;