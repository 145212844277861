"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _underscore = require("underscore");

// import * as _ from 'underscore';
// console.log({ ..._ });
// 处理重定向的mixins
var _default = {
  methods: {
    redirect: function redirect(location) {
      var $route = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _$route$params = $route.params,
          params = _$route$params === void 0 ? {} : _$route$params;

      if (window.location.pathname !== location) {
        if ((0, _underscore.isObject)(params) && !(0, _underscore.isEmpty)(params)) {
          window.sessionStorage.setItem('$route', (0, _stringify["default"])({
            path: location,
            params: params
          }));
        }

        window.location.href = location;
      }
    }
  }
};
exports["default"] = _default;