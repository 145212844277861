"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: '8',
  icon: 'el-icon-document',
  name: '产品管理',
  opened: true,
  children: [{
    index: '8-1',
    name: '产品查询',
    href: '/product/menu/views/list',
    permission: '',
    description: '产品查询列表'
  }, {
    index: '8-2',
    name: '产品物料',
    href: '/product/menu/views/supplies',
    permission: 'DELIVER.PRODUCT_MANAGER.MATERIALS_PACKETS',
    description: '产品物料包信息'
  }, {
    index: '8-3',
    name: '产品服务物料',
    href: '/product/menu/views/service',
    permission: 'DELIVER.PRODUCT_MANAGER.SEE_MATERIAL_LIST',
    description: '产品服务物料信息'
  }, {
    index: '8-4',
    name: '我的申请',
    href: '/product/menu/views/apply',
    permission: '',
    description: '产品物料下载申请信息'
  }]
}];
var _default = items;
exports["default"] = _default;