var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: {
          placeholder: "请输入" + _vm.title,
          value: _vm.inputValue,
          "suffix-icon": "el-icon-paperclip"
        },
        on: {
          focus: function($event) {
            _vm.dialogVisible = true
          }
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加多个" + _vm.title,
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("el-input", {
            attrs: {
              rows: 7,
              placeholder: "支持 Excel 粘贴",
              type: "textarea"
            },
            model: {
              value: _vm.textareaValue,
              callback: function($$v) {
                _vm.textareaValue = $$v
              },
              expression: "textareaValue"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "tips" }, [
            _vm._v(_vm._s("多个" + _vm.title + "请回车（换行）分隔"))
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }