//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: Array
  },
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    if (this.data) {
      this.tableData = this.data
    }
  },
  methods: {
    // 校验是否为链接
    regExpFun(field) {
      let reg = /(http|ftp|https):\/\/([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+/
      return reg.test(field)
    },
    // 获取真实链接
    getHref(field) {
      let url = ''
      if (field) {
        url = field.split('\n')[0]
      }
      return url
    },
    // checkbox匹配
    mateArray(option = [], itemValue = []) {
      let filterData = option.filter((el) => itemValue.includes(el.value))
      if (filterData.length > 0) {
        return filterData
          .map((el, idx) => {
            return `${idx}.${el.key}`
          })
          .join(';')
      }
      return ''
    },
    // 节点类型匹配
    mateOption(option = [], itemValue) {
      let filterData = option.filter((el) => el.value === itemValue)
      if (filterData.length > 0) {
        return filterData[0].key
      }
      return ''
    }
  }
}
