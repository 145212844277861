"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var _default = {
  props: {
    loaded: Boolean
  },
  watch: {
    loaded: function loaded() {
      if (!this.chart) {
        return;
      }

      if (this.loaded) {
        this.chart.hideLoading();
      }
    }
  }
};
exports["default"] = _default;