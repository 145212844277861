"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

_Object$defineProperty(exports, "PaginationAttributes", {
  enumerable: true,
  get: function get() {
    return _index["default"];
  }
});

var _index = _interopRequireDefault(require("./props/Attributes/index"));