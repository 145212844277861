"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _default = {
  methods: {
    getEnumsValue: function getEnumsValue() {
      var enums = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var _id = arguments.length > 1 ? arguments[1] : undefined;

      var match = (0, _find["default"])(enums).call(enums, function (_ref) {
        var id = _ref.id;
        return id === _id;
      }) || {};
      return match.value;
    }
  }
};
exports["default"] = _default;