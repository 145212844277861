//
//
//
//
//
//
//
//
//
//

import TableListMixin from 'src/pages/oms/modify/mixins/table-list';
export default {
  props: {
    result: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [TableListMixin]
};
