//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
  name: 'date-time-range-group',
  props: {
    value: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  computed: {
    startValue () {
      return this.value[0];
    },
    endValue () {
      return this.value[1];
    },
    startPickerOption () {
      const value = this.endValue;
      return {
        disabledDate (val) {
          return value && moment(val).startOf('day').isAfter(moment(value));
        }
      }
    },
    endPickerOption () {
      const value = this.startValue;
      return {
        disabledDate (val) {
          return value && moment(val).endOf('day').isBefore(moment(value));
        }
      }
    }
  },
  methods: {
    handleStartInput (val) {
      if (this.endValue && moment(val).isAfter(moment(this.endValue))) {
        return
      }
      if (val) {
        this.$emit('input', [val, this.endValue])
      } else {
        this.$emit('input', null)
      }
    },
    handleEndInput (val) {
      if (this.startValue && moment(this.startValue).isAfter(moment(val))) {
        return;
      }
      if (val) {
        this.$emit('input', [this.startValue, val])
      } else {
        this.$emit('input', null)
      }
    }
  }
}
