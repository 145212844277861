//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BatchOrderComponent from './BatchOrder'
import DownloadMixins from 'src/modules/mixins/download'
import { getTradeList, getDepartmentList, downloadBillData, markBill } from 'src/pages/deliver/bill/config/api'
export default {
  components: {
    BatchOrderComponent
  },
  mixins: [DownloadMixins],
  props: {
    type: String,
    activeTab: String,
    queryField: String,
    companyname: String,
    columns: Array
  },
  data () {
    const getFormField = () => {
      return {
        order_id: '',
        [this.queryField]: '',
        order_month_min: '',
        order_month_max: '',
        settlement_month_max: '',
        settlement_month_min: '',
        account_name: '',
        service_type: '',
        service_stage: '',
        story_team: '',
        [this.companyname]: '',
        order_status: '',
        order_type: '',
        project_name: '',
        project_trade: '',
        industry_name: '',
        product_name: '',
        is_return: ''
      }
    }
    return {
      dialogVisible: false,
      fullscreenLoading: false,
      isShowAdd: false,
      additionalForm: { order_id: '', month: '' },
      order_month: [],
      out_month: [],
      form: getFormField(),
      isDisabled: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '本月',
            onClick (picker) {
              picker.$emit('pick', [new Date(), new Date()])
            }
          },
          {
            text: '今年至今',
            onClick (picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近六个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 6)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      tradeList: [],
      departmentList: [],
      options_service_type: [
        { value: '产品专家咨询', label: '产品专家咨询' },
        { value: '开发技术咨询', label: '开发技术咨询' },
        { value: '迁云咨询', label: '迁云咨询' },
        { value: '交付实施', label: '交付实施' },
        { value: '专业测试', label: '专业测试' },
        { value: '培训服务', label: '培训服务' },
        { value: '定制开发', label: '定制开发' },
        { value: '项目管理', label: '项目管理' },
        { value: 'POC测试', label: 'POC测试' },
        { value: '维保服务', label: '维保服务' },
        { value: '运维服务', label: '运维服务' },
        { value: '专项保障服务', label: '专项保障服务' },
        { value: '服务项目经理（SPM）', label: '服务项目经理（SPM）' }
      ],
      columnsUnique: {}
    }
  },
  watch: {
    form: {
      handler () {
        this.$emit(
          'input',
          Object.entries({
            ...this.form,
            type: this.type
          }).reduce((obj, [key, value]) => {
            value && (obj[key] = value)
            return obj
          }, {})
        )
      },
      immediate: true,
      deep: true
    },
    columns: {
      handler () {
        const filterFome = () => {
          return this.columns
            .filter((item) => item.type !== 'expand')
            .reduce((obj, item) => {
              obj[item.prop] = !item.hidden
              return obj
            }, {})
        }
        this.columnsUnique = filterFome()
      },
      immediate: true,
      deep: true
    },
    // 账单月份
    order_month ([orderMonthMix = '', orderMonthMax = ''] = [], oldValue) {
      this.form['order_month_min'] = orderMonthMix
      this.form['order_month_max'] = orderMonthMax
    },
    // 结算月份
    out_month ([outMonthMix = '', outMonthMax = ''] = [], oldValue) {
      this.form['settlement_month_max'] = outMonthMax
      this.form['settlement_month_min'] = outMonthMix
    },
    // 监听tabs变化 修改订单状态的value 并且规定是否禁选v
    activeTab (v) {
      this.form.is_return = ''
      // this.clear()
      if (v === '0' || v === '1' || v === '2' || v === '3' || v === '-1') {
        this.form.order_status = v
        this.isDisabled = true
      } else if (v === '6') {
        this.clear()
      } else {
        this.form.order_status = ''
        this.isDisabled = false
      }
    }
  },
  created () {
    this.getTradeList()
    this.getDepartmentList()
  },
  methods: {
    settings () {
      this.dialogVisible = true
    },
    // 补出账单
    additional () {
      this.isShowAdd = true
    },
    // 取消补出
    cancelAction (formName) {
      this.$refs[formName].resetFields()
    },
    // 确认补出
    confirmAction (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true
          markBill({ query: { ...this.additionalForm, type: this.type } }).then(({ data }) => {
            this.fullscreenLoading = false
            if (data && data.code === 0) {
              this.$message({ type: 'success', message: data.message, duration: 5000 })
              this.isShowAdd = false
            }
          })
        } else {
          return false
        }
      })
    },
    // 按钮权限控制显示隐藏
    buttonShow (permissionCode) {
      return this.$user.permissions.includes(permissionCode)
    },
    getTradeList () {
      getTradeList().then(({ data }) => {
        if (data && data.code === 0) {
          this.tradeList = data.data || []
        }
      })
    },
    getDepartmentList () {
      getDepartmentList().then(({ data }) => {
        if (data && data.code === 0) {
          this.departmentList = data.data || []
        }
      })
    },
    // 下载账单
    async downloadBill () {
      this.form.type = this.type
      let newForm = Object.entries(this.form).reduce((obj, [k, v]) => {
        v && (obj[k] = v)
        return obj
      }, {})
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = downloadBillData() + this.formateObjToParamStr(newForm)
      link.target = '_blank'
      link.click()
    },
    query () {
      this.$emit('query')
    },
    clear () {
      let lastVal = JSON.parse(JSON.stringify(this.form.order_status))
      this.$refs.queryForm.resetFields()
      this.order_month = []
      this.out_month = []
      if (this.activeTab !== '' && this.activeTab !== '6') {
        this.form.order_status = lastVal
      }
    },
    handleAttrSetting () {
      let data = Object.entries(this.columnsUnique).reduce((obj, [key, value]) => {
        obj[key] = !value
        return obj
      }, {})
      this.$emit('topStrataColums', data)
      this.$emit('attrSetting', data)
      this.dialogVisible = false
    }
  }
}
