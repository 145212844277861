var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-collapse",
    {
      model: {
        value: _vm.activeCollapse,
        callback: function($$v) {
          _vm.activeCollapse = $$v
        },
        expression: "activeCollapse"
      }
    },
    _vm._l(_vm.serieses, function(ref) {
      var code = ref.code
      var name = ref.name
      var children = ref.children
      return _c(
        "el-collapse-item",
        { key: code, attrs: { name: code } },
        [
          _vm._t("title", null, {
            slot: "title",
            series: name,
            code: code,
            permissions: children,
            myPermissions: _vm.getMyPermissions(_vm.module, code)
          }),
          _vm._v(" "),
          _vm._t("default", null, {
            code: code,
            permissions: children,
            myPermissions: _vm.getMyPermissions(_vm.module, code)
          })
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }