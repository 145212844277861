"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.downloadRecruitExcel = exports.recruitmentEdit = exports.recruitmentDetail = exports.recruitmentList = exports.queryHumanOptions = exports.humanUpdateLabel = exports.hrTempDownload = exports.ManageLabelList = exports.hrLabelList = exports.demandStop = exports.demandModify = exports.demandApply = exports.demandField = exports.demandDetail = exports.demandList = exports.pocApproval = exports.pocInfolist = exports.pocSub = exports.poclist = exports.exportExcel = exports.getlist = exports.getMap = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context; _forEachInstanceProperty(_context = ownKeys(Object(source), true)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var url = '';

var getMap = function getMap() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/qflow/man_map'
  }, config));
}; // 人力信息  获取列表信息


exports.getMap = getMap;

var getlist = function getlist() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      data = _ref2.data,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/qflow/hr_information_list_hook'
  }, config));
}; // 导出人力信息


exports.getlist = getlist;

var exportExcel = function exportExcel() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$query = _ref3.query,
      query = _ref3$query === void 0 ? {} : _ref3$query,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;

  return url + '/deliver/qflow/export_hr_data';
}; // 人力信息  获取poc服务产品


exports.exportExcel = exportExcel;

var poclist = function poclist() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$query = _ref4.query,
      query = _ref4$query === void 0 ? {} : _ref4$query,
      _ref4$config = _ref4.config,
      config = _ref4$config === void 0 ? {
    loading: false
  } : _ref4$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/qflow/get_product_name'
  }, config));
}; // 人力信息  poc审核


exports.poclist = poclist;

var pocSub = function pocSub() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$query = _ref5.query,
      query = _ref5$query === void 0 ? {} : _ref5$query,
      data = _ref5.data,
      _ref5$config = _ref5.config,
      config = _ref5$config === void 0 ? {
    loading: false
  } : _ref5$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/qflow/hr_info_poc_examine'
  }, config));
}; // poc审核列表


exports.pocSub = pocSub;

var pocInfolist = function pocInfolist() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$query = _ref6.query,
      query = _ref6$query === void 0 ? {} : _ref6$query,
      data = _ref6.data,
      _ref6$config = _ref6.config,
      config = _ref6$config === void 0 ? {
    loading: false
  } : _ref6$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/qflow/hr_info_poc_list'
  }, config));
}; // poc审核列表


exports.pocInfolist = pocInfolist;

var pocApproval = function pocApproval() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$query = _ref7.query,
      query = _ref7$query === void 0 ? {} : _ref7$query,
      data = _ref7.data,
      _ref7$config = _ref7.config,
      config = _ref7$config === void 0 ? {
    loading: false
  } : _ref7$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/qflow/hr_info_poc_approval'
  }, config));
}; // 人力需求 - 列表


exports.pocApproval = pocApproval;

var demandList = function demandList() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref8$query = _ref8.query,
      query = _ref8$query === void 0 ? {} : _ref8$query,
      _ref8$config = _ref8.config,
      config = _ref8$config === void 0 ? {
    loading: false
  } : _ref8$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/manpower/demand/list'
  }, config));
}; // 人力需求 - 详情


exports.demandList = demandList;

var demandDetail = function demandDetail() {
  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref9$query = _ref9.query,
      query = _ref9$query === void 0 ? {} : _ref9$query,
      _ref9$config = _ref9.config,
      config = _ref9$config === void 0 ? {
    loading: false
  } : _ref9$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    url: url + "/deliver/manpower/demand/detail/".concat(query.tid)
  }, config));
}; // 人力需求 - 动态获取人力需求审批流程申请字段


exports.demandDetail = demandDetail;

var demandField = function demandField() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref10$query = _ref10.query,
      query = _ref10$query === void 0 ? {} : _ref10$query,
      _ref10$config = _ref10.config,
      config = _ref10$config === void 0 ? {
    loading: false
  } : _ref10$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/manpower/demand/field'
  }, config));
}; // 人力需求-提交创建审批


exports.demandField = demandField;

var demandApply = function demandApply() {
  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref11$query = _ref11.query,
      query = _ref11$query === void 0 ? {} : _ref11$query,
      data = _ref11.data,
      _ref11$config = _ref11.config,
      config = _ref11$config === void 0 ? {
    loading: false
  } : _ref11$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/manpower/demand/apply'
  }, config));
}; // 人力需求-需求变更


exports.demandApply = demandApply;

var demandModify = function demandModify() {
  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref12$query = _ref12.query,
      query = _ref12$query === void 0 ? {} : _ref12$query,
      data = _ref12.data,
      _ref12$config = _ref12.config,
      config = _ref12$config === void 0 ? {
    loading: false
  } : _ref12$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/manpower/demand/modify'
  }, config));
}; // 人力需求-需求终止


exports.demandModify = demandModify;

var demandStop = function demandStop() {
  var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref13$query = _ref13.query,
      query = _ref13$query === void 0 ? {} : _ref13$query,
      data = _ref13.data,
      _ref13$config = _ref13.config,
      config = _ref13$config === void 0 ? {
    loading: false
  } : _ref13$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/manpower/demand/stop'
  }, config));
};

exports.demandStop = demandStop;

var hrLabelList = function hrLabelList() {
  var _ref14 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref14$query = _ref14.query,
      query = _ref14$query === void 0 ? {} : _ref14$query,
      _ref14$config = _ref14.config,
      config = _ref14$config === void 0 ? {
    loading: false
  } : _ref14$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/qflow/hr_label_list'
  }, config));
};

exports.hrLabelList = hrLabelList;

var ManageLabelList = function ManageLabelList() {
  var _ref15 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref15$params = _ref15.params,
      params = _ref15$params === void 0 ? {} : _ref15$params,
      _ref15$config = _ref15.config,
      config = _ref15$config === void 0 ? {
    loading: false
  } : _ref15$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: params,
    method: 'get',
    url: url + '/deliver/qflow/management_label_list'
  }, config));
};

exports.ManageLabelList = ManageLabelList;

var hrTempDownload = function hrTempDownload() {
  var _ref16 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref16$params = _ref16.params,
      params = _ref16$params === void 0 ? {} : _ref16$params,
      _ref16$config = _ref16.config,
      config = _ref16$config === void 0 ? {
    loading: false
  } : _ref16$config;

  // return url + '/deliver/qflow/hr_label_download'
  return '//deliver-hexiaoyu-test-1258877907.cos.ap-guangzhou.myqcloud.com/plan/area_hr_label.xlsx';
}; // 人力信息  poc审核


exports.hrTempDownload = hrTempDownload;

var humanUpdateLabel = function humanUpdateLabel(uid) {
  var _ref17 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref17$query = _ref17.query,
      query = _ref17$query === void 0 ? {} : _ref17$query,
      data = _ref17.data,
      _ref17$config = _ref17.config,
      config = _ref17$config === void 0 ? {
    loading: false
  } : _ref17$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + "/deliver/qflow/human_update_label/".concat(uid)
  }, config));
}; // 人力需求-获取基本配置


exports.humanUpdateLabel = humanUpdateLabel;

var queryHumanOptions = function queryHumanOptions() {
  var _ref18 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref18$query = _ref18.query,
      query = _ref18$query === void 0 ? {} : _ref18$query,
      data = _ref18.data,
      _ref18$config = _ref18.config,
      config = _ref18$config === void 0 ? {
    loading: false
  } : _ref18$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'get',
    url: url + '/deliver/qflow/human_options'
  }, config));
}; // 人力招聘需求 - 列表


exports.queryHumanOptions = queryHumanOptions;

var recruitmentList = function recruitmentList() {
  var _ref19 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref19$query = _ref19.query,
      query = _ref19$query === void 0 ? {} : _ref19$query,
      _ref19$config = _ref19.config,
      config = _ref19$config === void 0 ? {
    loading: false
  } : _ref19$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/manpower/demand/recruitment_list'
  }, config));
}; // 人力招聘需求 - 详情


exports.recruitmentList = recruitmentList;

var recruitmentDetail = function recruitmentDetail() {
  var _ref20 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref20$query = _ref20.query,
      query = _ref20$query === void 0 ? {} : _ref20$query,
      _ref20$config = _ref20.config,
      config = _ref20$config === void 0 ? {
    loading: false
  } : _ref20$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'post',
    params: query,
    url: url + "/deliver/manpower/demand/recruitment_detail"
  }, config));
}; // 人力招聘需求 - 编辑


exports.recruitmentDetail = recruitmentDetail;

var recruitmentEdit = function recruitmentEdit() {
  var _ref21 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref21$params = _ref21.params,
      params = _ref21$params === void 0 ? {} : _ref21$params,
      _ref21$data = _ref21.data,
      data = _ref21$data === void 0 ? {} : _ref21$data,
      _ref21$config = _ref21.config,
      config = _ref21$config === void 0 ? {
    loading: false
  } : _ref21$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'post',
    params: params,
    data: data,
    url: url + "/deliver/manpower/demand/modifyRecruitmentDemand"
  }, config));
}; // 导出人力需求单


exports.recruitmentEdit = recruitmentEdit;

var downloadRecruitExcel = function downloadRecruitExcel() {
  var _ref22 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref22$params = _ref22.params,
      params = _ref22$params === void 0 ? {} : _ref22$params,
      _ref22$query = _ref22.query,
      query = _ref22$query === void 0 ? {} : _ref22$query,
      _ref22$config = _ref22.config,
      config = _ref22$config === void 0 ? {
    loading: false
  } : _ref22$config;

  return url + '/deliver/manpower/demand/recruitment_download';
};

exports.downloadRecruitExcel = downloadRecruitExcel;