"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

var _some = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/some"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

// 判断用户权限
var _default = {
  methods: {
    hasPermission: function hasPermission(permission) {
      var user = this.$user;

      try {
        var isFuzzyMatching = permission.match(/\.\*/);

        if ((0, _isArray["default"])(isFuzzyMatching)) {
          var _context;

          var keywords = (0, _slice["default"])(permission).call(permission, 0, isFuzzyMatching.index);
          return (0, _some["default"])(_context = user.permissions).call(_context, function (item) {
            return (0, _includes["default"])(item).call(item, keywords);
          });
        } else {
          var _context2;

          return (0, _includes["default"])(_context2 = user.permissions).call(_context2, permission);
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    }
  }
};
exports["default"] = _default;