"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.submitBillPush = exports.actionInfos = exports.queryInfos = exports.submitOrder = exports.submitBill = exports.getLogList = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context; _forEachInstanceProperty(_context = ownKeys(Object(source), true)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var url = ''; // 查询账单工具的操作记录

var getLogList = function getLogList() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/toolkit/aorder/index'
  }, config));
}; // 账单工具提交 BCD类账单冲红申请 BCD类账单解封申请


exports.getLogList = getLogList;

var submitBill = function submitBill() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$params = _ref2.params,
      params = _ref2$params === void 0 ? {} : _ref2$params,
      _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    url: url + '/deliver/toolkit/bill/store'
  }, config));
}; // A类【订单作废】| 【订单变更】操作提交接口


exports.submitBill = submitBill;

var submitOrder = function submitOrder() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$params = _ref3.params,
      params = _ref3$params === void 0 ? {} : _ref3$params,
      _ref3$data = _ref3.data,
      data = _ref3$data === void 0 ? {} : _ref3$data,
      _ref3$query = _ref3.query,
      query = _ref3$query === void 0 ? {} : _ref3$query,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + "/deliver/toolkit/aorder/store"
  }, config));
}; // 审批人变更---获取信息


exports.submitOrder = submitOrder;

var queryInfos = function queryInfos() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$query = _ref4.query,
      query = _ref4$query === void 0 ? {} : _ref4$query,
      _ref4$config = _ref4.config,
      config = _ref4$config === void 0 ? {
    loading: false
  } : _ref4$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/getApprovalPerson'
  }, config));
}; // 审批人变更---修改信息


exports.queryInfos = queryInfos;

var actionInfos = function actionInfos() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$data = _ref5.data,
      data = _ref5$data === void 0 ? {} : _ref5$data,
      _ref5$config = _ref5.config,
      config = _ref5$config === void 0 ? {
    loading: false
  } : _ref5$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/updateApprovalPerson'
  }, config));
}; // 账单推送操作提交接口


exports.actionInfos = actionInfos;

var submitBillPush = function submitBillPush() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$params = _ref6.params,
      params = _ref6$params === void 0 ? {} : _ref6$params,
      _ref6$data = _ref6.data,
      data = _ref6$data === void 0 ? {} : _ref6$data,
      _ref6$query = _ref6.query,
      query = _ref6$query === void 0 ? {} : _ref6$query,
      _ref6$config = _ref6.config,
      config = _ref6$config === void 0 ? {
    loading: false
  } : _ref6$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + "/deliver/toolkit/bill/push"
  }, config));
};

exports.submitBillPush = submitBillPush;