var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.propInfo.planType === "transportation" ? "上传票据" : "实际提报",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "700px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: function($event) {
          return _vm.closeAction("form")
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "110px",
            rules: _vm.rules,
            "hide-required-asterisk": ""
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "实际发生时间", prop: "jtime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          type: "datetimerange",
                          "prefix-icon": "el-icon-date",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.form.jtime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "jtime", $$v)
                          },
                          expression: "form.jtime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isShowRules(1)
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "计划出发城市", prop: "real_outset" }
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "show-all-levels": false,
                              filterable: "",
                              options: _vm.options
                            },
                            model: {
                              value: _vm.form.real_outset,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "real_outset", $$v)
                              },
                              expression: "form.real_outset"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "计划目的城市",
                            prop: "real_destination"
                          }
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "show-all-levels": false,
                              filterable: "",
                              options: _vm.options
                            },
                            model: {
                              value: _vm.form.real_destination,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "real_destination", $$v)
                              },
                              expression: "form.real_destination"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowRules()
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "去/返程", prop: "real_is_go_back" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择去/返程类型" },
                              model: {
                                value: _vm.form.real_is_go_back,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "real_is_go_back", $$v)
                                },
                                expression: "form.real_is_go_back"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "去程", value: "1" }
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "返程", value: "2" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "计划交通类型",
                            prop: "real_traffic_type"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择计划交通类型" },
                              model: {
                                value: _vm.form.real_traffic_type,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "real_traffic_type", $$v)
                                },
                                expression: "form.real_traffic_type"
                              }
                            },
                            _vm._l(_vm.transportTypes, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowRules()
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "实际交通费", prop: "real_price" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              step: 0.01,
                              controls: false
                            },
                            model: {
                              value: _vm.form.real_price,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "real_price", $$v)
                              },
                              expression: "form.real_price"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发生原因", prop: "real_reason" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", autosize: { minRows: 2 } },
                        model: {
                          value: _vm.form.real_reason,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "real_reason", $$v)
                          },
                          expression: "form.real_reason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isShowRules()
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "uploadfile",
                          attrs: { label: "上传凭证", prop: "files" }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: _vm.uploadlink,
                                data: _vm.uploadParams,
                                name: "uploadFile",
                                accept: "image/*",
                                "on-success": _vm.handleSuccessUpload,
                                "before-upload": _vm.handleBeforeUpload,
                                "before-remove": _vm.beforeRemove,
                                "on-remove": _vm.handleRemove,
                                limit: 5,
                                "on-exceed": _vm.handleExceed,
                                "file-list": _vm.filelist
                              }
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip"
                                },
                                [_vm._v("只能上传图片文件")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.confrimAction("form")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }