"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
// 通用的快速显示隐藏mixin
var _default = {
  data: function data() {
    return {
      isShow: false
    };
  },
  methods: {
    show: function show() {
      var _this = this;

      this.$nextTick(function () {
        _this.isShow = true;
      });
    },
    hide: function hide() {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.isShow = false;
      });
    }
  }
};
exports["default"] = _default;