"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _axios = _interopRequireDefault(require("axios"));

var _default = {
  responseReject: function responseReject(error) {
    if (_axios["default"].isCancel(error)) {
      return error;
    } else {
      return _promise["default"].reject(error);
    }
  }
};
exports["default"] = _default;