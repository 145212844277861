//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import options from 'src/modules/components/map'
import { planDetail, getOrderInfo, planEdit } from 'src/pages/workbench/api'

export default {
  props: {
    status: {
      type: Number,
      default: 100
    },
    holidayArr: {
      type: Array,
      default () {
        return [];
      }
    },
    validateDate: {
      type: Function
    }
  },
  data() {
    let validateOrderId = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入订单编号'))
      } else {
        if (this.orderMembers.length === 0) {
          callback(new Error('无效编号'))
        }
        if (!this.isPass) {
          callback(new Error('仅支持D3类订单'))
        }
        callback()
      }
    }
    return {
      isShow: false,
      pickerOptions: {
        disabledDate: (time) => {
          const date = moment(time).format('YYYY-MM-DD')
          return !this.holidayArr.includes(date);
        },
        onPick: ({ maxDate, minDate }) => {
          const flag = this.validateDate(minDate, maxDate)
          if (flag) {
            this.form.jtime[1] = minDate
            this.$alert('开始结束时间仅支持选择节假日', '提示');
          }
        }
      },
      planTypes: [{ label: '加班', value: 'overtime' }, { label: '请假', value: 'leave' }, { label: '出差', value: 'subsidy' }, { label: '差旅交通费', value: 'transportation' }],
      transportTypes: [{ label: '机票经济舱', value: 1 }, { label: '高铁二等座', value: 2 }, { label: '动车二等座', value: 3 }, { label: '火车票', value: 4 }, { label: '其他', value: 5 }],
      form: { po_id: null, order_id: '', applicant: [], type: 'overtime', apply_reason: '', jtime: [], outset: [], destination: [], is_go_back: '', traffic_type: '', predict_price: 0 },
      orderMembers: [],
      loading: false,
      orderInfo: {},
      isPass: true,
      isSubing: false,
      isMultiple: true,
      rules: {
        order_id: [{ validator: validateOrderId, trigger: 'blur' }],
        applicant: [{ required: true, message: '请选择申请人', trigger: 'change' }],
        type: [{ required: true, message: '请选择计划类型', trigger: 'change' }],
        jtime: [{ required: true, message: '请选择时间', trigger: 'change' }],
        apply_reason: [{ required: true, message: '请输入申请理由', trigger: 'blur' }],
        outset: [{ required: true, message: '请选择出发城市', trigger: 'change' }],
        destination: [{ required: true, message: '请选择目的城市', trigger: 'change' }],
        is_go_back: [{ required: true, message: '请选择去返程类型', trigger: 'change' }],
        traffic_type: [{ required: true, message: '请选择计划交通类型', trigger: 'change' }],
        predict_price: [{ required: true, message: '请输入预计金额', trigger: 'blur' }]
      }
    }
  },
  computed: {
    options() {
      return options
    }
  },
  mounted() {
    this.$on('open', (val) => {
      this.isShow = true
      this.form.po_id = val
      this.isMultiple = val === 0
      if (val !== 0) {
        this.$set(this.form, 'applicant', '')
        this.$nextTick(() => {
          this.init(val)
        })
      } else {
        this.$set(this.form, 'applicant', [])
        this.$nextTick(() => {
          this.$refs.form.clearValidate('applicant')
        })
      }
    })
    this.$on('hide', () => {
      this.isShow = false
    })
  },
  methods: {
    // 编辑初始化
    init(pid) {
      this.loading = true
      planDetail({ query: { po_id: pid } }).then(({ data }) => {
        this.loading = false
        if (data && data.code === 0) {
          let useData = data.data
          Object.keys(this.form).forEach((el) => {
            if (el === 'jtime') {
              this.form[el] = [useData.begin_time, useData.end_time]
            } else {
              this.form[el] = useData[el]
            }
          })
          this.handleChangeOrder(useData.order_id, true)
        }
      })
    },
    // 回车事件
    handleEnter($el) {
      $el.target.blur()
    },
    // 订单编号改变事件
    handleChangeOrder(val, sine) {
      if (!sine) {
        this.form.applicant = this.isMultiple ? [] : ''
      }
      if (val !== '') {
        this.loading = true
        getOrderInfo({ query: { order_id: val } }).then(({ data }) => {
          this.loading = false
          if (data && data.code === 0) {
            if (data.data.length > 0) {
              let useData = data.data[0]
              this.orderInfo = useData
              this.form.order_flag = useData.order_flag
              this.orderMembers = useData.member

              let msg = '不支持提交'
              this.isPass = false
              if (!['B类', 'D3类'].includes(useData.order_flag)) {
                this.isPass = false
                msg = '仅支持B类、D3类订单提交提交加班计划'
              } else {
                this.isPass = true
              }
              if (!this.isPass) {
                this.$alert(msg, '', {
                  confirmButtonText: '我知道了',
                  center: true,
                  showClose: false
                })
              }
            } else {
              this.orderMembers = []
              this.orderInfo = {}
              this.form.order_flag = ''
            }
            // 该校验需要访问data数据 需要手动触发校验
            this.$refs.form.validateField('order_id')
            this.$refs.form.clearValidate('applicant')
          }
        })
      } else {
        this.orderMembers = []
        this.orderInfo = {}
        this.form.order_flag = ''
      }
    },
    //   根据计划类型判断选项是否显示
    isShowRules(keys, sine) {
      /**
       *keys 作为form重置参数
       *当返回结果为false时重置已经输入的且隐藏的form对应值
       *sine 作为出发目的城市标识
       */
      if (this.form.type !== '' && this.form.type !== 'transportation') {
        if (this.form.type !== 'subsidy') {
          keys.forEach((el) => {
            this.form[el] = ''
          })
          return false
        } else {
          if (sine) return true
          keys.forEach((el) => {
            this.form[el] = ''
          })
          return false
        }
      }
      return true
    },
    // 关闭弹窗自动触发事件
    closeDialog(formName) {
      this.$refs[formName].resetFields()
      this.form.applicant = this.isMultiple ? [] : ''
      this.orderInfo = {}
    },
    //  确认提交
    confirmDialog(formName) {
      this.$refs[formName].validate((valid) => {
        /** 计划类型只能是加班、时间只能是节假日 */
        if (this.form.type !== 'overtime' || this.validateDate(this.form.jtime[0], this.form.jtime[1])) {
          this.$alert('仅允许提交节假日加班申请，请修改后重新提交。', '提示', {
            confirmButtonText: '我知道了',
            center: true,
            showClose: false
          })
          return false
        }
        if (valid) {
          if (Array.isArray(this.form.jtime)) {
            if (this.form.jtime.length === 2) {
              this.form.begin_time = this.form.jtime[0]
              this.form.end_time = this.form.jtime[1]
            } else {
              this.form.begin_time = ''
              this.form.end_time = ''
            }
          }
          if (Array.isArray(this.form.outset)) {
            if (this.form.outset.length === 2) {
              this.form.outset = this.form.outset[1]
            } else if (this.form.destination.length === 1) {
              this.form.outset = this.form.outset[0]
            } else {
              this.form.outset = []
            }
          }
          if (Array.isArray(this.form.destination)) {
            if (this.form.destination.length === 2) {
              this.form.destination = this.form.destination[1]
            } else if (this.form.destination.length === 1) {
              this.form.destination = this.form.destination[0]
            } else {
              this.form.destination = []
            }
          }

          let params = JSON.parse(JSON.stringify(this.form))
          delete params.jtime
          params.applicant = Array.isArray(params.applicant) ? params.applicant.join(',') : params.applicant
          params.status = this.status
          this.isSubing = true
          planEdit({ data: params }).then(({ data }) => {
            this.isSubing = false
            if (data && data.code === 0) {
              this.isShow = false
              this.$message({ type: 'success', message: data.message || '操作成功' })
              this.$emit('onOk')
            } else {
              this.$message({ type: 'error', message: data.message })
            }
            // else {
            //   // 检验不通过专属错误码
            //   if (data.code === 10030) {
            //     this.$alert(data.status, '', {
            //       confirmButtonText: '我知道了',
            //       center: true
            //     })
            //   } else {
            //     this.$message({ type: 'error', message: data.message })
            //   }
            // }
          })
        } else {
          return false
        }
      })
    },
    typeDisabled (item) {
      return item.label !== '加班'
      /** 仅允许提交加班申请 */
      // if (this.form.po_id !== 0) {
      //   return this.orderInfo.order_flag && this.orderInfo.order_flag !== 'A类' && item.label === '请假'
      // } else {
      //   return item.label !== '加班'
      // }
    }
  }
}
