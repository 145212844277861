var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "产品转维记录",
        visible: _vm.visible,
        width: "40%",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _vm.data.length > 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "dialog-content"
            },
            _vm._l(_vm.data, function(item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    "el-form",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "申请人：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.creater) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "申请时间：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.created_at) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "运维订单：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.operation_order) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "未选择运维订单原因：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.operation_reason) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品转维流程ID：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.pms_id) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "关联局点：" } },
                                [
                                  item.judian_list &&
                                  item.judian_list.length > 0
                                    ? _c(
                                        "el-tooltip",
                                        { attrs: { placement: "top" } },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass: "relate-judian-info",
                                              attrs: { slot: "content" },
                                              slot: "content"
                                            },
                                            _vm._l(item.judian_list, function(
                                              item
                                            ) {
                                              return _c(
                                                "li",
                                                { key: item.id },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.id) +
                                                      " " +
                                                      _vm._s(item.name) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            { attrs: { type: "text" } },
                                            [_vm._v("查看详情")]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("无")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "审批人：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.approver) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "审批时间：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.approver_time) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "审批状态：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.approver_status) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "审批意见：" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.approver_opinions) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  index < _vm.data.length - 1 ? _c("el-divider") : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "dialog-content"
            },
            [_vm._v("暂无数据")]
          ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v("关 闭")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }