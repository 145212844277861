"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: '7',
  icon: 'el-icon-document',
  name: '账单管理',
  opened: true,
  children: [{
    index: '7-1',
    name: 'A类月账单',
    href: '/deliver/bill/month/a',
    permission: 'DELIVER.BILL.SEE_BILL',
    description: '交付已出的 A类 月账单'
  }, {
    index: '7-2',
    name: 'BCD类月账单',
    href: '/deliver/bill/month/bcd',
    permission: 'DELIVER.BILL.SEE_BILL',
    description: '交付已出的 BCD类 月账单'
  }, {
    index: '7-3',
    name: 'E类月账单',
    href: '/deliver/bill/month/e',
    permission: 'DELIVER.BILL.SEE_BILL',
    description: '交付已出的 E类 出账明细'
  }, {
    index: '7-4',
    name: 'D3类出账明细',
    href: '/deliver/bill/month/d3',
    permission: 'DELIVER.BILL.SEE_BILL',
    description: '交付已出的 D3类 出账明细'
  }]
}];
var _default = items;
exports["default"] = _default;