var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "创建人：" } }, [
                _vm._v("\n        " + _vm._s(_vm.params.operator) + "\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "创建时间：" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.params.created_at) + "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        _vm._l(_vm.params.step_data, function(item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: item.name + "：" } },
                [
                  item.type === "info" && _vm.regExpFun(item.input_value)
                    ? _c(
                        "el-link",
                        {
                          attrs: {
                            href: item.input_value,
                            underline: false,
                            type: "primary",
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(item.input_value))]
                      )
                    : item.type === "radio" || item.type === "select"
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.mateOption(item.option_json, item.input_value)
                            ) +
                            "\n        "
                        )
                      ])
                    : item.type === "checkbox"
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.mateArray(item.option_json, item.input_value)
                            ) +
                            "\n        "
                        )
                      ])
                    : item.type === "file"
                    ? [
                        item.input_value !== ""
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  underline: false,
                                  type: "primary",
                                  href: item.input_value
                                }
                              },
                              [_vm._v("点击下载")]
                            )
                          : _c(
                              "el-link",
                              { attrs: { underline: false, type: "primary" } },
                              [_vm._v("暂无数据")]
                            )
                      ]
                    : item.type === "file_project"
                    ? [
                        item.input_value !== ""
                          ? _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.downloadField(item.input_value)
                                  }
                                }
                              },
                              [_vm._v("点击下载")]
                            )
                          : _c(
                              "el-link",
                              { attrs: { underline: false, type: "primary" } },
                              [_vm._v("暂无数据")]
                            )
                      ]
                    : item.type === "date" || item.type === "time"
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.timeFormat(item.input_value)) +
                            "\n        "
                        )
                      ])
                    : _c("span", [_vm._v(_vm._s(item.input_value))])
                ],
                2
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }