"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.logLevel = void 0;
var logLevel = [{
  id: 100,
  value: 'DEBUG'
}, {
  id: 200,
  value: 'INFO'
}, {
  id: 250,
  value: 'NOTICE'
}, {
  id: 300,
  value: 'WARNING'
}, {
  id: 400,
  value: 'ERROR'
}, {
  id: 500,
  value: 'CRITICAL'
}, {
  id: 550,
  value: 'ALERT'
}, {
  id: 600,
  value: 'EMERGENCY'
}];
exports.logLevel = logLevel;