//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormComponent from './form';

export default {
  name: 'template-query',
  components: {
    FormComponent
  },
  props: {
    configs: {
      type: Array,
      default () {
        return [];
      }
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    resetParams () {
      this.$emit('input', this.$refs.form.resetParams());
    },
    submit () {
      const params = this.$refs.form.submit();
      this.$emit('input', params);
      this.$emit('query', params);
    }
  }
}
