var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("计划详情")]),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { "label-width": "110px" } },
        [
          _vm.pageWhere === "approve"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "项目名称" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.ltc_name))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "项目编号" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.ltc_id))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "订单编号" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.order_id))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-form-item", { attrs: { label: "计划类型" } }, [
                    _vm._v(_vm._s(_vm._f("planType")(_vm.orderInfo.type)))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.pageWhere === "approve"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "申请人岗位" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.applicant_job))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "申请人职级" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.applicant_level))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "申请理由" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.apply_reason))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { staticClass: "high-content" }, [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("开始时间"),
                      _c("br"),
                      _vm._v("结束时间")
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.orderInfo.begin_time) +
                        " - " +
                        _vm._s(_vm.orderInfo.end_time) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isShowRules(1)
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "出发城市" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.outset) + " ")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "目的城市" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.destination) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowRules()
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "交通工具" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("trafficType")(_vm.orderInfo.traffic_type)
                            )
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "交通费类型" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("isGoBack")(_vm.orderInfo.is_go_back)
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowRules()
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "预计金额" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.predict_price))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "当前审批状态" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("planStatus")(_vm.orderInfo.status)) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }