"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

require("./handler");

var _axios = _interopRequireDefault(require("axios"));

// 为智能网关增加头部以正确返回401
_axios["default"].defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
var Http = {};

Http.install = function (Vue, options) {
  // 绑定axios
  Vue.ajax = Vue.prototype.$ajax = _axios["default"]; // 添加rest-api方法

  Vue.rest = Vue.prototype.$rest = {
    get: function get(path, config) {
      return _axios["default"].get(path, config);
    },
    post: function post(path, data, config) {
      return _axios["default"].post(path, data, config);
    },
    put: function put(path, data, config) {
      return _axios["default"].put(path, data, config);
    },
    "delete": function _delete(path, config) {
      return _axios["default"]["delete"](path, config);
    }
  };
};

var _default = Http;
exports["default"] = _default;