"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _every = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/every"));

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _operationPermissions = require("../utils/operation-permissions");

var _default2 = {
  props: {
    isEdit: {
      type: Boolean,
      "default": false
    },
    modules: {
      type: Array,
      "default": function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      lastPermissions: [],
      myPermissions: [],
      myTreePermissions: {}
    };
  },
  methods: {
    unionPermissions: function unionPermissions(templatePermissions) {
      var _context, _context2;

      var unionPermissions = (0, _concat["default"])(_context = (0, _filter["default"])(_context2 = this.myPermissions).call(_context2, function (_ref) {
        var permission = _ref.permission;
        return !(0, _find["default"])(templatePermissions).call(templatePermissions, function (_ref2) {
          var tPermission = _ref2.permission;
          return tPermission === permission;
        });
      })).call(_context, templatePermissions);
      this.myPermissions = unionPermissions;
      this.myTreePermissions = (0, _operationPermissions.initMyPermissions)(unionPermissions, this.modules);
    },
    closeTip: function closeTip(done) {
      if (!this.isEdit || this.unChanged()) {
        done();
      } else {
        this.$confirm("\u5C06\u4F1A\u4E22\u5931\u5DF2\u7ECF\u8FDB\u884C\u7684\u64CD\u4F5C\uFF0C\u662F\u5426\u786E\u8BA4?", '请确认').then(function () {
          done();
        });
      }
    },

    /**
     * 减少资源使用，不使用计算属性
     * 长度和每一个数据相同保证permission不变
     */
    unChanged: function unChanged() {
      var lastPermission = this.lastPermissions;
      var nowPermission = (0, _operationPermissions.getPostPermission)(this.myTreePermissions);
      return lastPermission.length === nowPermission.length && (0, _every["default"])(nowPermission).call(nowPermission, function (np) {
        return (0, _find["default"])(lastPermission).call(lastPermission, function (lp) {
          return lp.code === np.code;
        });
      });
    }
  }
};
exports["default"] = _default2;