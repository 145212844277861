"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.statusIconClass = exports.statusFigureClass = exports.data = void 0;
var data = {
  steps: [{
    fields: [{
      id: 2244553,
      name: 'LTC立项编号',
      submit_var_name: 'Numbering',
      input_value: '11111111',
      note: '',
      field_num: 0,
      link: 'http://panshi.oa.com/offlineProject/project/bench',
      description: '与LTC立项编号一致',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244554,
      name: 'LTC立项名称',
      submit_var_name: 'name1',
      input_value: 'LTC立项名称',
      note: '',
      field_num: 1,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'Y',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244555,
      name: '客户UIN',
      submit_var_name: 'uin',
      input_value: '11111111',
      note: '',
      field_num: 2,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244556,
      name: '子项目ID',
      submit_var_name: '',
      input_value: '子项目ID',
      note: '',
      field_num: 3,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244557,
      name: '子项目名称',
      submit_var_name: '',
      input_value: '子项目名称',
      note: '',
      field_num: 4,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244558,
      name: '项目阶段',
      submit_var_name: 'contract',
      input_value: '1',
      note: '',
      field_num: 5,
      link: '',
      description: '',
      field_type: 'radio',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [{
        key: '未中标',
        value: '1'
      }, {
        key: '已中标未签合同',
        value: '2'
      }, {
        key: '已签合同',
        value: '3'
      }],
      is_edit: false
    }, {
      id: 2244559,
      name: '项目归属部门',
      submit_var_name: 'industry_dept',
      input_value: '智慧零售部',
      note: '',
      field_num: 6,
      link: '',
      description: '',
      field_type: 'select',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'Y',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [{
        key: '智慧行业1部',
        value: '智慧行业1部'
      }, {
        key: '智慧行业2部',
        value: '智慧行业2部'
      }, {
        key: '智慧行业3部',
        value: '智慧行业3部'
      }, {
        key: '智慧行业4部',
        value: '智慧行业4部'
      }, {
        key: '智慧行业5部',
        value: '智慧行业5部'
      }, {
        key: '智慧零售部',
        value: '智慧零售部'
      }, {
        key: '智慧出行事业部',
        value: '智慧出行事业部'
      }, {
        key: '在线教育部',
        value: '在线教育部'
      }],
      is_edit: false
    }, {
      id: 2244560,
      name: '项目归属行业',
      submit_var_name: 'industry_name',
      input_value: '8',
      note: '',
      field_num: 7,
      link: '',
      description: '',
      field_type: 'select',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [{
        key: '游戏',
        value: 'deqzhang;jiayuanpeng;clairzhang'
      }, {
        key: '政府',
        value: 'billbzhang'
      }, {
        key: '金融',
        value: 'joffyzhang;shirleyshen;paladiyang'
      }, {
        key: '国际业务',
        value: 'deqzhang;clairzhang;'
      }, {
        key: '智慧零售',
        value: 'jackmu'
      }, {
        key: '教育',
        value: 'brantli;spraydong'
      }, {
        key: '税收项目',
        value: 'billbzhang;'
      }, {
        key: '工业',
        value: 'nelsonnli;paladiyang'
      }, {
        key: '电商',
        value: 'deqzhang;jiayuanpeng;clairzhang;;'
      }, {
        key: '房地产',
        value: 'jozhou;terry'
      }, {
        key: '广电',
        value: 'senawang;paladiyang'
      }, {
        key: '行业工具',
        value: 'deqzhang;jiayuanpeng;clairzhang;;;'
      }, {
        key: '能源',
        value: 'xqiaozhao;paladiyang'
      }, {
        key: '汽车',
        value: 'weichahuang'
      }, {
        key: '生活互联',
        value: 'deqzhang;jiayuanpeng;clairzhang;;;;'
      }, {
        key: '手机',
        value: 'rockycwang;paladiyang'
      }, {
        key: '文创',
        value: 'deqzhang;jiayuanpeng;clairzhang;;;;;'
      }, {
        key: '文旅',
        value: 'alexliangxu'
      }, {
        key: '医疗',
        value: 'guodong;spraydong'
      }, {
        key: '交通',
        value: 'clovercao;paladiyang'
      }, {
        key: '工业云',
        value: 'nelsonnli;paladiyang;'
      }, {
        key: '交通云',
        value: 'clovercao;paladiyang;'
      }],
      is_edit: false
    }, {
      id: 2244561,
      name: '项目背景',
      submit_var_name: 'Background of the project',
      input_value: '项目背景',
      note: '',
      field_num: 8,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244562,
      name: '行业FT项目PM',
      submit_var_name: 'FTPM',
      input_value: 'yarkizhang;',
      note: '',
      field_num: 9,
      link: '',
      description: '',
      field_type: 'rtx',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244563,
      name: '行业FT项目PM联系方式',
      submit_var_name: 'FTPM_phone',
      input_value: 'yarki',
      note: '',
      field_num: 10,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244564,
      name: '现场实施接口人',
      submit_var_name: 'impl_name',
      input_value: 'yarkizhang',
      note: '',
      field_num: 11,
      link: '',
      description: '',
      field_type: 'rtx',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244565,
      name: '接口人联系方式',
      submit_var_name: 'impl_phone',
      input_value: 'yarki',
      note: '',
      field_num: 12,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244566,
      name: '项目销售',
      submit_var_name: 'Project sales',
      input_value: '项目销售',
      note: '',
      field_num: 13,
      link: '',
      description: '',
      field_type: 'rtx',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244567,
      name: '项目售前（架构师）',
      submit_var_name: 'Pre-sales architect',
      input_value: 'yarkizhang',
      note: '',
      field_num: 14,
      link: '',
      description: '',
      field_type: 'rtx',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244568,
      name: '项目整体负责人',
      submit_var_name: 'project_owner',
      input_value: 'yarkizhang;',
      note: '',
      field_num: 15,
      link: '',
      description: '',
      field_type: 'rtx',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244569,
      name: '期望入场时间',
      submit_var_name: 'time1',
      input_value: '2020-03-19',
      note: '',
      field_num: 16,
      link: '',
      description: '\n',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244570,
      name: '期望离场时间',
      submit_var_name: 'time2',
      input_value: '2020-03-27',
      note: '',
      field_num: 17,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244571,
      name: '项目所属大区',
      submit_var_name: ' region',
      input_value: 'audreychen;looklu',
      note: '',
      field_num: 18,
      link: '',
      description: '',
      field_type: 'select',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [{
        key: '华东',
        value: 'yyyoungyang;neoni'
      }, {
        key: '华南',
        value: 'jakeji;kevinxjjia'
      }, {
        key: '西南',
        value: 'audreychen;looklu'
      }, {
        key: '华北',
        value: 'roberttan;allenxinli'
      }],
      is_edit: false
    }, {
      id: 2244572,
      name: '项目实施地点',
      submit_var_name: 'city',
      input_value: '深圳市',
      note: '',
      field_num: 19,
      link: '',
      description: 'XX省XX市 \n',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'Y',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244573,
      name: '子项目清单',
      submit_var_name: '',
      input_value: '子项目清单',
      note: '',
      field_num: 20,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244574,
      name: '客户CID',
      submit_var_name: 'client_cid',
      input_value: '客户CID',
      note: '',
      field_num: 21,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244575,
      name: '主体名称',
      submit_var_name: 'bodyname_name',
      input_value: '主体名称',
      note: '',
      field_num: 22,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244576,
      name: '运维方式',
      submit_var_name: 'yunwei_fangsi',
      input_value: '运维方式',
      note: '',
      field_num: 23,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244577,
      name: '客户名称',
      submit_var_name: 'client_name',
      input_value: '客户名称',
      note: '',
      field_num: 24,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700346,
    name: '创建新需求',
    submit_var_name: '',
    status: 'done',
    hold: false,
    owner: 'yarkizhang',
    creator: 'selinama',
    description: '',
    link: '',
    group_name: '',
    start_time: '2020-07-06 22:11:52',
    finish_time: '2020-07-06 22:57:12',
    time_cost: '45 分',
    step: null,
    shape_id: 'Task_38525',
    is_branch: false
  }, {
    fields: [{
      id: 2244578,
      name: '技术评估结论',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244579,
      name: '承接区技公司',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 1,
      link: '',
      description: '',
      field_type: 'select',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [{
        key: '南区-腾云悦智',
        value: '南区-腾云悦智'
      }, {
        key: '东区-腾云忆想',
        value: '东区-腾云忆想'
      }, {
        key: '西区-云腾未来',
        value: '西区-云腾未来'
      }, {
        key: '北区-腾云忆想',
        value: '北区-腾云忆想'
      }],
      is_edit: false
    }, {
      id: 2244580,
      name: '是否需要行业审批',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 2,
      link: '',
      description: '',
      field_type: 'radio',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [{
        key: '无需审批',
        value: '1'
      }, {
        key: '需要审批',
        value: '2'
      }],
      is_edit: false
    }, {
      id: 2244581,
      name: '交付中心审批意见',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 3,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244582,
      name: '项目服务包文件',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 4,
      link: '',
      description: '请下载最新计算器模板（更新时间2019.10.31）\nhttp://tapd.oa.com/quyu/markdown_wikis/#1020406392009243163',
      field_type: 'file',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700347,
    name: '需求评审',
    submit_var_name: '',
    status: 'doing',
    hold: false,
    owner: 'yarkizhang;',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '2020-07-06 22:57:12',
    finish_time: '',
    time_cost: '14 分',
    step: null,
    shape_id: 'Task_38530',
    is_branch: false
  }, {
    fields: [{
      id: 2244583,
      name: '是否同意',
      submit_var_name: '',
      input_value: '同意',
      note: '',
      field_num: 0,
      link: '',
      description: '如不同意，请直接打回至上一步',
      field_type: 'radio',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [{
        key: '同意',
        value: '1'
      }, {
        key: '无需审批',
        value: '2'
      }],
      is_edit: false
    }, {
      id: 2244584,
      name: '附件',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 1,
      link: '',
      description: '',
      field_type: 'file',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700348,
    name: '行业运营审批',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38533',
    is_branch: false
  }, {
    fields: [{
      id: 2244585,
      name: '序号',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244586,
      name: '订单类型',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 1,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244587,
      name: '服务类型',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 2,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244588,
      name: '产品简称',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 3,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244589,
      name: 'LTC产品ID',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 4,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244590,
      name: '服务名称',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 5,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244591,
      name: '服务人员数量',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 6,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244592,
      name: '订单金额',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 7,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244593,
      name: '费用备注',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 8,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244594,
      name: '预估入场时间',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 9,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244595,
      name: '预估离场时间',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 10,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244596,
      name: '入场条件说明',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 11,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244597,
      name: '服务内容',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 12,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244598,
      name: '验收标准',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 13,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244599,
      name: '二线支撑人员和联系方式',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 14,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244600,
      name: '订单编号',
      submit_var_name: 'field_var_name_order_type',
      input_value: '',
      note: '',
      field_num: 15,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244601,
      name: '订单链接',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 16,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244602,
      name: '单据类型',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 17,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244603,
      name: 'TAPD需求ID',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 18,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244604,
      name: 'TAPD需求链接',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 19,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244605,
      name: '订单预估工时',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 20,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244606,
      name: '单价',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 21,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244607,
      name: '父单链接地址',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 22,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244608,
      name: '子单链接地址',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 23,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244609,
      name: '订单变更地址',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 24,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244610,
      name: '组建团队',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 25,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244611,
      name: '项目归档链接地址',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 26,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700349,
    name: '服务包下单',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'UserTask_0m9h7mx',
    is_branch: false
  }, {
    fields: [{
      id: 2244612,
      name: '区技运维主负责人',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244613,
      name: '区技运维备负责人',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 1,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244614,
      name: '运维订单入场日期',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 2,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244615,
      name: '运维订单离场日期',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 3,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700350,
    name: '运维负责人接单',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38536',
    is_branch: false
  }, {
    fields: [{
      id: 2244616,
      name: '区技PM',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244617,
      name: '区技入场人员',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 1,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244618,
      name: '项目实施里程碑',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 2,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244619,
      name: '订单入场日期',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 3,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244620,
      name: '订单离场日期',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 4,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700351,
    name: '区技PM接口人接单',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38535',
    is_branch: false
  }, {
    fields: [{
      id: 2244621,
      name: '实际入场时间',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700352,
    name: '等待区技入场',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'UserTask_121k08w',
    is_branch: false
  }, {
    fields: [],
    id: 700353,
    name: '区技进场实施',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38539',
    is_branch: false
  }, {
    fields: [{
      id: 2244622,
      name: '验收报告链接',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244623,
      name: '验收申请链接',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 1,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244624,
      name: '特批加班(人天)',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 2,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244625,
      name: '特批请假(人天)',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 3,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244626,
      name: '特批差旅费用(元)',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 4,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244627,
      name: '特批交通费(元)',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 5,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244628,
      name: '其他特批项(元)',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 6,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244629,
      name: '特批调整项具体说明',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 7,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244630,
      name: '本次验收工作内容',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 8,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244631,
      name: '特批调整金额',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 9,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244632,
      name: '本次结算金额(元)',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 10,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'N',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700354,
    name: '区技完成自测',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38540',
    is_branch: false
  }, {
    fields: [{
      id: 2244633,
      name: '是否同意离场',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '如不同意请打回至上一步,点击右侧“步骤打回”按钮，选择上一步骤即可打回',
      field_type: 'radio',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [{
        key: '同意',
        value: '1'
      }],
      is_edit: false
    }, {
      id: 2244634,
      name: '离场意见',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 1,
      link: '',
      description: '',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244635,
      name: '对响应满意度评分',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 2,
      link: '',
      description: '请填写0—100之间的数字',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244636,
      name: '对服务满意度评分',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 3,
      link: '',
      description: '请填写0—100之间的数字',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244637,
      name: '对产品满意度评分',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 4,
      link: '',
      description: '请填写0—100之间的数字',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244638,
      name: '流程、规范性遵从得分',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 5,
      link: '',
      description: '请填写0—100之间的数字',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244639,
      name: '工作按照计划完成情况得分',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 6,
      link: '',
      description: '请填写0—100之间的数字',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244640,
      name: '沟通能力得分',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 7,
      link: '',
      description: '请填写0—100之间的数字',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244641,
      name: '文档提交得分',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 8,
      link: '',
      description: '请填写0—100之间的数字',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }, {
      id: 2244642,
      name: '关键事件得分',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 9,
      link: '',
      description: '请填-100—0之间的数字',
      field_type: 'input',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700355,
    name: '行业PM审批离场确认',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: 'yarkizhang;',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38541',
    is_branch: false
  }, {
    fields: [{
      id: 2244643,
      name: '实际离场时间',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '',
      field_type: 'date',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700356,
    name: '区技人员离场',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38543',
    is_branch: false
  }, {
    fields: [{
      id: 2244644,
      name: '项目总结',
      submit_var_name: '',
      input_value: '',
      note: '',
      field_num: 0,
      link: '',
      description: '',
      field_type: 'textarea',
      option: '',
      option_description: '',
      is_required: 'Y',
      is_on_task_top: 'N',
      is_pushed: 'N',
      is_task_name: 'N',
      is_encrypt: 'N',
      option_json: [],
      is_edit: false
    }],
    id: 700357,
    name: '发布项目总结邮件',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38544',
    is_branch: false
  }, {
    fields: [],
    id: 700358,
    name: '项目关单',
    submit_var_name: '',
    status: 'todo',
    hold: false,
    owner: '',
    creator: null,
    description: '',
    link: '',
    group_name: '',
    start_time: '',
    finish_time: '',
    time_cost: '',
    step: null,
    shape_id: 'Task_38545',
    is_branch: false
  }],
  sub_flow_msg: {},
  id: 82544,
  flow_id: 2146,
  name: 'yarkizhang 测试接入项目',
  creator: 'yarkizhang',
  status: 'doing',
  progress: '7.69%',
  memo: {},
  time_cost: '59 分',
  start_time: '2020-07-06 22:11:52',
  finish_time: '',
  rtx_group: 'N',
  personal_tip: 'N',
  interval_tip: 'N',
  description: null,
  notify_session_id: '',
  pop_step_list: [700355],
  view_owner: 'yarkizhang;',
  deployment_id: 6677
};
exports.data = data;
var statusFigureClass = {
  done: 'is-done',
  todo: 'is-todo',
  doing: 'is-doing'
};
exports.statusFigureClass = statusFigureClass;
var statusIconClass = {
  done: 'el-icon-circle-check is-done',
  todo: 'el-icon-time is-todo',
  doing: 'el-icon-video-pause is-doing'
};
exports.statusIconClass = statusIconClass;