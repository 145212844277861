//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showExport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      planTypes: [{ label: '加班', value: 'overtime' }, { label: '请假', value: 'leave' }, { label: '出差', value: 'subsidy' }, { label: '差旅交通费', value: 'transportation' }],
      belong_company: ['东区-腾云忆想', '南区-腾云悦智', '西区-云腾未来', '北区-腾云忆想'],
      form: { ltc_id: '', ltc_name: '', po_id: '', order_id: '', type: '', jtime1: [], jtime2: [], applicant: '', manager: '', belong_company: '' }
    }
  },
  methods: {
    //  提交查询
    onSubmit() {
      this.$emit('search')
    },
    // 导出表格
    handleExport() {
      this.$emit('export')
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$emit('reset')
    }
  },
  watch: {
    form: {
      handler() {
        this.$emit(
          'input',
          Object.entries(this.form).reduce((obj, [k, v]) => {
            if (Array.isArray(v)) {
              if (v.length > 0) {
                if (k === 'jtime1') {
                  obj.begin_time = v[0]
                  obj.end_time = v[1]
                } else {
                  obj.create_begin_time = v[0]
                  obj.create_end_time = v[1]
                }
              }
            } else {
              v && (obj[k] = v)
            }
            return obj
          }, {})
        )
      },
      immediate: true,
      deep: true
    }
  }
}
