//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WaterMarkComponent from 'src/modules/components/layout/WaterMark';

import ShowSwitchMixin from 'src/modules/mixins/page-event/show-switch';

export default {
  components: {
    WaterMarkComponent
  },
  mixins: [ShowSwitchMixin],
  inheritAttrs: false,
  props: {
    btnTitle: {
      type: String
    },
    width: {
      type: String,
      default: '35%'
    },
    isSubmit: {
      type: Boolean,
      default: true
    },
    submitText: {
      type: String,
      default: '提交'
    },
    icon: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    btnClass: {
      type: String
    },
    btnText: {
      type: [String, Number],
      default: ''
    },
    btnType: {
      type: String
    },
    btnStyle: {
      type: Object
    },
    btnSize: {
      type: String,
      default: 'small'
    },
    tips: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isRenderDialog: false
    }
  },
  methods: {
    open () {
      this.isRenderDialog = true;
      this.$nextTick(() => {
        this.$emit('open');
        this.show();
      });
    },
    submit () {
      this.$emit('submit', this.closeDialog);
    },
    close () {
      this.$emit('close');
      this.$nextTick(() => {
        this.isRenderDialog = false;
      })
    },
    closeDialog () {
      this.$refs.dialog.handleClose();
      this.close();
    },
    opened () {
      this.$nextTick(() => {
        this.$emit('opened')
      });
    }
  }
}
