"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));

// 添加一个全局 created 为当前 vue 实例查询当前用户属性
var _default = {
  computed: {
    user: function user() {
      return this.$user;
    }
  },
  methods: {
    hasPermission: function hasPermission(permission) {
      var _context;

      var user = this.$user;
      return user && user.permissions && (0, _includes["default"])(_context = user.permissions).call(_context, permission);
    }
  },
  created: function created() {
    var _this = this;

    // this.$rest.get('http://redicklu.deliver.oa.com/user', {
    this.$rest.get('/user', {
      params: {
        scope: this.scope
      },
      config: {
        permission: this.permission
      }
    }).then(function (_ref) {
      var data = _ref.data;

      if (data && data.code === 0 && (data = data.data)) {
        _this.$user = data;
        window.CDSC_RTX = data.user_id;
      }
    });
  }
};
exports["default"] = _default;