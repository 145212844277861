var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.safeData, stripe: "" } },
    [_c("el-column", { attrs: { columns: _vm.columns } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }