"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getApplyList = exports.addPermission = exports.getUserProductPermission = exports.getServiceType = exports.queryApprovalDetail = exports.recallApproval = exports.queryApprovalList = exports.submitDownloadApproval = exports.queryDownloadPermissionOptions = exports.certManage = exports.delServerPack = exports.buildServerPack = exports.initServerPack = exports.getServerPack = exports.modifyPro = exports.getlist = exports.newNotice = exports.getPatchURL = exports.getPatchInfo = exports.discardProductPatch = exports.addVersionPackage = exports.addPatchName = exports.getVersionTree = exports.getTreeInfo = exports.getPermissions = exports.getPermissionTree = exports.getAccounts = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context; _forEachInstanceProperty(_context = ownKeys(Object(source), true)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var url = '';

var getAccounts = function getAccounts() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/accounts'
  }, config));
};

exports.getAccounts = getAccounts;

var getPermissionTree = function getPermissionTree() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/permission-tree'
  }, config));
};

exports.getPermissionTree = getPermissionTree;

var getPermissions = function getPermissions() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$query = _ref3.query,
      query = _ref3$query === void 0 ? {} : _ref3$query,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/permissions'
  }, config));
}; // 获取物料包树形结构信息


exports.getPermissions = getPermissions;

var getTreeInfo = function getTreeInfo() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$query = _ref4.query,
      query = _ref4$query === void 0 ? {} : _ref4$query,
      _ref4$config = _ref4.config,
      config = _ref4$config === void 0 ? {
    loading: false
  } : _ref4$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/product/project_list'
  }, config));
}; // 获取版本树


exports.getTreeInfo = getTreeInfo;

var getVersionTree = function getVersionTree() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$query = _ref5.query,
      query = _ref5$query === void 0 ? {} : _ref5$query,
      _ref5$config = _ref5.config,
      config = _ref5$config === void 0 ? {
    loading: false
  } : _ref5$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/product/version/tree'
  }, config));
}; // 添加安装包名称


exports.getVersionTree = getVersionTree;

var addPatchName = function addPatchName() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$query = _ref6.query,
      query = _ref6$query === void 0 ? {} : _ref6$query,
      _ref6$data = _ref6.data,
      data = _ref6$data === void 0 ? {} : _ref6$data,
      _ref6$config = _ref6.config,
      config = _ref6$config === void 0 ? {
    loading: false
  } : _ref6$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/version'
  }, config));
}; // 添加(编辑)安装包


exports.addPatchName = addPatchName;

var addVersionPackage = function addVersionPackage() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$query = _ref7.query,
      query = _ref7$query === void 0 ? {} : _ref7$query,
      _ref7$data = _ref7.data,
      data = _ref7$data === void 0 ? {} : _ref7$data,
      _ref7$config = _ref7.config,
      config = _ref7$config === void 0 ? {
    loading: false
  } : _ref7$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/version/package'
  }, config));
}; // 安装包作废


exports.addVersionPackage = addVersionPackage;

var discardProductPatch = function discardProductPatch() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref8$query = _ref8.query,
      query = _ref8$query === void 0 ? {} : _ref8$query,
      _ref8$config = _ref8.config,
      config = _ref8$config === void 0 ? {
    loading: false
  } : _ref8$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'put',
    url: url + '/deliver/product/version/package'
  }, config));
}; // 获取软件包详情


exports.discardProductPatch = discardProductPatch;

var getPatchInfo = function getPatchInfo() {
  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref9$query = _ref9.query,
      query = _ref9$query === void 0 ? {} : _ref9$query,
      _ref9$config = _ref9.config,
      config = _ref9$config === void 0 ? {
    loading: false
  } : _ref9$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/product/version/package'
  }, config));
}; // 获取物料url


exports.getPatchInfo = getPatchInfo;

var getPatchURL = function getPatchURL() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref10$query = _ref10.query,
      query = _ref10$query === void 0 ? {} : _ref10$query,
      _ref10$config = _ref10.config,
      config = _ref10$config === void 0 ? {
    loading: false
  } : _ref10$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/product/version/package/url'
  }, config));
}; // 修改物料包公告信息


exports.getPatchURL = getPatchURL;

var newNotice = function newNotice() {
  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref11$query = _ref11.query,
      query = _ref11$query === void 0 ? {} : _ref11$query,
      _ref11$config = _ref11.config,
      config = _ref11$config === void 0 ? {
    loading: false
  } : _ref11$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/product/project_notice_add'
  }, config));
}; // 产品查询


exports.newNotice = newNotice;

var getlist = function getlist() {
  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref12$query = _ref12.query,
      query = _ref12$query === void 0 ? {} : _ref12$query,
      _ref12$config = _ref12.config,
      config = _ref12$config === void 0 ? {
    loading: false
  } : _ref12$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/product/query_product'
  }, config));
}; // 产品编辑


exports.getlist = getlist;

var modifyPro = function modifyPro() {
  var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref13$query = _ref13.query,
      query = _ref13$query === void 0 ? {} : _ref13$query,
      _ref13$data = _ref13.data,
      data = _ref13$data === void 0 ? {} : _ref13$data,
      _ref13$config = _ref13.config,
      config = _ref13$config === void 0 ? {
    loading: false
  } : _ref13$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'post',
    data: data,
    url: url + '/deliver/product/edit_product'
  }, config));
}; // 服务包列表查询


exports.modifyPro = modifyPro;

var getServerPack = function getServerPack() {
  var _ref14 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref14$query = _ref14.query,
      query = _ref14$query === void 0 ? {} : _ref14$query,
      _ref14$config = _ref14.config,
      config = _ref14$config === void 0 ? {
    loading: false
  } : _ref14$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/qflow/query_service_pack'
  }, config));
}; // 创建与编辑服务物料-初始化


exports.getServerPack = getServerPack;

var initServerPack = function initServerPack() {
  var _ref15 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref15$query = _ref15.query,
      query = _ref15$query === void 0 ? {} : _ref15$query,
      _ref15$config = _ref15.config,
      config = _ref15$config === void 0 ? {
    loading: false
  } : _ref15$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/qflow/create_update_service_pack'
  }, config));
}; // 创建与编辑服务物料-提交


exports.initServerPack = initServerPack;

var buildServerPack = function buildServerPack() {
  var _ref16 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref16$query = _ref16.query,
      query = _ref16$query === void 0 ? {} : _ref16$query,
      _ref16$data = _ref16.data,
      data = _ref16$data === void 0 ? {} : _ref16$data,
      _ref16$config = _ref16.config,
      config = _ref16$config === void 0 ? {
    loading: false
  } : _ref16$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    // url: url + '/deliver/qflow/submit_create_update_service_pack',
    url: url + '/deliver/product/service_pack'
  }, config));
}; // 服务包删除


exports.buildServerPack = buildServerPack;

var delServerPack = function delServerPack() {
  var _ref17 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref17$query = _ref17.query,
      query = _ref17$query === void 0 ? {} : _ref17$query,
      _ref17$config = _ref17.config,
      config = _ref17$config === void 0 ? {
    loading: false
  } : _ref17$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/qflow/delete_service_pack'
  }, config));
}; // 证书管理


exports.delServerPack = delServerPack;

var certManage = function certManage() {
  var _ref18 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref18$query = _ref18.query,
      query = _ref18$query === void 0 ? {} : _ref18$query,
      _ref18$data = _ref18.data,
      data = _ref18$data === void 0 ? {} : _ref18$data,
      _ref18$config = _ref18.config,
      config = _ref18$config === void 0 ? {
    loading: false
  } : _ref18$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/qflow/certificate_manage'
  }, config));
}; // 下载无聊权限申请表单-模糊搜索


exports.certManage = certManage;

var queryDownloadPermissionOptions = function queryDownloadPermissionOptions() {
  var _ref19 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref19$query = _ref19.query,
      query = _ref19$query === void 0 ? {} : _ref19$query,
      _ref19$data = _ref19.data,
      data = _ref19$data === void 0 ? {} : _ref19$data,
      _ref19$config = _ref19.config,
      config = _ref19$config === void 0 ? {
    loading: false
  } : _ref19$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/query_customer_judian_project_data'
  }, config));
}; // 下载权限申请提交


exports.queryDownloadPermissionOptions = queryDownloadPermissionOptions;

var submitDownloadApproval = function submitDownloadApproval() {
  var _ref20 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref20$query = _ref20.query,
      query = _ref20$query === void 0 ? {} : _ref20$query,
      _ref20$data = _ref20.data,
      data = _ref20$data === void 0 ? {} : _ref20$data,
      _ref20$config = _ref20.config,
      config = _ref20$config === void 0 ? {
    loading: true
  } : _ref20$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/submit_version_patch_package_approval'
  }, config));
}; // 查询产品下载审批列表


exports.submitDownloadApproval = submitDownloadApproval;

var queryApprovalList = function queryApprovalList() {
  var _ref21 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref21$query = _ref21.query,
      query = _ref21$query === void 0 ? {} : _ref21$query,
      _ref21$data = _ref21.data,
      data = _ref21$data === void 0 ? {} : _ref21$data,
      _ref21$config = _ref21.config,
      config = _ref21$config === void 0 ? {
    loading: false
  } : _ref21$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/get_approval_list'
  }, config));
}; // 撤回产品下载审批申请


exports.queryApprovalList = queryApprovalList;

var recallApproval = function recallApproval() {
  var _ref22 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref22$query = _ref22.query,
      query = _ref22$query === void 0 ? {} : _ref22$query,
      _ref22$data = _ref22.data,
      data = _ref22$data === void 0 ? {} : _ref22$data,
      _ref22$config = _ref22.config,
      config = _ref22$config === void 0 ? {
    loading: false
  } : _ref22$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/drop_approval_callback'
  }, config));
}; // 查询产品下载审批详情


exports.recallApproval = recallApproval;

var queryApprovalDetail = function queryApprovalDetail() {
  var _ref23 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref23$query = _ref23.query,
      query = _ref23$query === void 0 ? {} : _ref23$query,
      _ref23$data = _ref23.data,
      data = _ref23$data === void 0 ? {} : _ref23$data,
      _ref23$config = _ref23.config,
      config = _ref23$config === void 0 ? {
    loading: false
  } : _ref23$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/get_approval_detail'
  }, config));
}; // 获取服务大类子类


exports.queryApprovalDetail = queryApprovalDetail;

var getServiceType = function getServiceType() {
  var _ref24 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref24$params = _ref24.params,
      params = _ref24$params === void 0 ? {} : _ref24$params,
      _ref24$config = _ref24.config,
      config = _ref24$config === void 0 ? {
    loading: false
  } : _ref24$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: params,
    method: 'get',
    url: url + '/deliver/project/getServiceType'
  }, config));
}; // 废除补丁包
// export const discardProductPatch = (params = {}, config = { loading: false }) => {
//   console.log('🚀 ~ file: api.js ~ line 243 ~ discardProductPatch ~ params', params)
//   return Vue.ajax.request({
//     params,
//     method: 'get',
//     url: `/deliver/product/project_patch_invalid`,
//     ...config
//   })
// }
// 获取当前登录用户可以申请权限列表


exports.getServiceType = getServiceType;

var getUserProductPermission = function getUserProductPermission() {
  var _ref25 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref25$query = _ref25.query,
      query = _ref25$query === void 0 ? {} : _ref25$query,
      _ref25$data = _ref25.data,
      data = _ref25$data === void 0 ? {} : _ref25$data,
      _ref25$config = _ref25.config,
      config = _ref25$config === void 0 ? {
    loading: false
  } : _ref25$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/getUserProductPermission'
  }, config));
}; // 用户提交申请权限


exports.getUserProductPermission = getUserProductPermission;

var addPermission = function addPermission() {
  var _ref26 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref26$query = _ref26.query,
      query = _ref26$query === void 0 ? {} : _ref26$query,
      _ref26$data = _ref26.data,
      data = _ref26$data === void 0 ? {} : _ref26$data,
      _ref26$config = _ref26.config,
      config = _ref26$config === void 0 ? {
    loading: false
  } : _ref26$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/addPermission'
  }, config));
}; // 获取用户申请列表


exports.addPermission = addPermission;

var getApplyList = function getApplyList() {
  var _ref27 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref27$query = _ref27.query,
      query = _ref27$query === void 0 ? {} : _ref27$query,
      _ref27$data = _ref27.data,
      data = _ref27$data === void 0 ? {} : _ref27$data,
      _ref27$config = _ref27.config,
      config = _ref27$config === void 0 ? {
    loading: false
  } : _ref27$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/product/getApplyList'
  }, config));
};

exports.getApplyList = getApplyList;