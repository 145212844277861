var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "serieses-component",
    _vm._b(
      {
        attrs: { module: _vm.module },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(ref) {
              var code = ref.code
              var series = ref.series
              var permissions = ref.permissions
              var myPermissions = ref.myPermissions
              return [
                _c("all-check-box-component", {
                  attrs: {
                    name: series,
                    permissions: permissions,
                    "my-permissions": myPermissions
                  },
                  on: {
                    "select-all-true": function($event) {
                      return _vm.updatePermission(_vm.module, code, $event)
                    },
                    "select-all-false": function($event) {
                      return _vm.updatePermission(_vm.module, code, $event)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "default",
            fn: function(ref) {
              var code = ref.code
              var permissions = ref.permissions
              var myPermissions = ref.myPermissions
              return [
                _c(
                  "el-checkbox-group",
                  {
                    attrs: { value: myPermissions },
                    on: {
                      input: function($event) {
                        return _vm.updatePermission(_vm.module, code, $event)
                      }
                    }
                  },
                  [
                    _c("permissions-component", {
                      attrs: {
                        "my-permissions": myPermissions,
                        permissions: permissions
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      },
      "serieses-component",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }