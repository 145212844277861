var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          attrs: { inline: "", model: _vm.form, "label-width": "100px" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单编号：", prop: "order_id" } },
                [
                  _c("batch-order-component", {
                    attrs: { title: "订单编号" },
                    model: {
                      value: _vm.form.order_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "order_id", $$v)
                      },
                      expression: "form.order_id"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账单编号：", prop: _vm.queryField } },
                [
                  _c("batch-order-component", {
                    attrs: { title: "账单编号" },
                    model: {
                      value: _vm.form[_vm.queryField],
                      callback: function($$v) {
                        _vm.$set(_vm.form, _vm.queryField, $$v)
                      },
                      expression: "form[queryField]"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.type == "A"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "服务月份：", prop: "order_month" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM",
                          type: "monthrange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始月份",
                          "end-placeholder": "结束月份",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.order_month,
                          callback: function($$v) {
                            _vm.order_month = $$v
                          },
                          expression: "order_month"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "BCD"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "服务月份：", prop: "order_month" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyyMM",
                          type: "monthrange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始月份",
                          "end-placeholder": "结束月份",
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.order_month,
                          callback: function($$v) {
                            _vm.order_month = $$v
                          },
                          expression: "order_month"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "结算月份：", prop: "out_month" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyyMM",
                      type: "monthrange",
                      align: "right",
                      "unlink-panels": "",
                      "range-separator": "至",
                      "start-placeholder": "开始月份",
                      "end-placeholder": "结束月份",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.out_month,
                      callback: function($$v) {
                        _vm.out_month = $$v
                      },
                      expression: "out_month"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.activeTab == ""
                ? _c(
                    "el-form-item",
                    { attrs: { label: "区技公司：", prop: _vm.companyname } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择区技公司" },
                          model: {
                            value: _vm.form[_vm.companyname],
                            callback: function($$v) {
                              _vm.$set(_vm.form, _vm.companyname, $$v)
                            },
                            expression: "form[companyname]"
                          }
                        },
                        [
                          _vm.buttonShow("DELIVER.BILL.SEE_ALL") ||
                          _vm.buttonShow("DELIVER.BILL.SEE_EAST")
                            ? _c("el-option", {
                                attrs: {
                                  label: "东区-腾云忆想",
                                  value: "东区-腾云忆想"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.buttonShow("DELIVER.BILL.SEE_ALL") ||
                          _vm.buttonShow("DELIVER.BILL.SEE_SOUTH")
                            ? _c("el-option", {
                                attrs: {
                                  label: "南区-腾云悦智",
                                  value: "南区-腾云悦智"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.buttonShow("DELIVER.BILL.SEE_ALL") ||
                          _vm.buttonShow("DELIVER.BILL.SEE_WEST")
                            ? _c("el-option", {
                                attrs: {
                                  label: "西区-云腾未来",
                                  value: "西区-云腾未来"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.buttonShow("DELIVER.BILL.SEE_ALL") ||
                          _vm.buttonShow("DELIVER.BILL.SEE_NORTH")
                            ? _c("el-option", {
                                attrs: {
                                  label: "北区-腾云忆想",
                                  value: "北区-腾云忆想"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账单状态：", prop: "order_status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.isDisabled,
                        placeholder: "请选择账单状态"
                      },
                      model: {
                        value: _vm.form.order_status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "order_status", $$v)
                        },
                        expression: "form.order_status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "预出账-待行业PM验收", value: "0" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "已出账-待区技确认是否结算",
                          value: "1"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "区技已确认结算-待审批付款",
                          value: "2"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "已提交付款申请", value: "3" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "已结算完成", value: "4" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "历史数据原因-账单无需关注",
                          value: "101"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.activeTab !== "6" && _vm.type == "BCD"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "订单类型：", prop: "order_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择账单类型" },
                          model: {
                            value: _vm.form.order_type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "order_type", $$v)
                            },
                            expression: "form.order_type"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "B类", value: "B类" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "C类", value: "C类" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "D1类", value: "D1类" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "D2类", value: "D2类" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "D3类", value: "D3类" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "A"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "姓名：", prop: "account_name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.form.account_name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "account_name", $$v)
                          },
                          expression: "form.account_name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "A"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "服务类型：", prop: "service_type" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入服务类型：" },
                        model: {
                          value: _vm.form.service_type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "service_type", $$v)
                          },
                          expression: "form.service_type"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "A"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "需求方团队：", prop: "story_team" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入需求方团队" },
                        model: {
                          value: _vm.form.story_team,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "story_team", $$v)
                          },
                          expression: "form.story_team"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "A"
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "岗位名称：", prop: "required_post_name" }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入岗位名称" },
                        model: {
                          value: _vm.form.required_post_name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "required_post_name", $$v)
                          },
                          expression: "form.required_post_name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "BCD"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "服务大类：", prop: "service_stage" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择服务大类" },
                          model: {
                            value: _vm.form.service_stage,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "service_stage", $$v)
                            },
                            expression: "form.service_stage"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "售前咨询", value: "售前咨询" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "集成交付", value: "集成交付" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "售后服务", value: "售后服务" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "BCD"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "项目名称：", prop: "project_name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入项目名称" },
                        model: {
                          value: _vm.form.project_name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "project_name", $$v)
                          },
                          expression: "form.project_name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "BCD"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "行业：", prop: "project_trade" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择行业" },
                          model: {
                            value: _vm.form.project_trade,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "project_trade", $$v)
                            },
                            expression: "form.project_trade"
                          }
                        },
                        _vm._l(_vm.tradeList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "BCD"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "部门：", prop: "industry_name" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择部门" },
                          model: {
                            value: _vm.form.industry_name,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "industry_name", $$v)
                            },
                            expression: "form.industry_name"
                          }
                        },
                        _vm._l(_vm.departmentList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type == "BCD"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "产品简称：", prop: "product_name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入产品简称" },
                        model: {
                          value: _vm.form.product_name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "product_name", $$v)
                          },
                          expression: "form.product_name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.activeTab == "6"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "撤回状态：", prop: "is_return" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择撤回状态" },
                          model: {
                            value: _vm.form.is_return,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "is_return", $$v)
                            },
                            expression: "form.is_return"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "未撤回", value: "0" }
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "已撤回", value: "1" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.query()
                }
              }
            },
            [_vm._v("查 询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.clear()
                }
              }
            },
            [_vm._v("重 置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-setting" },
              on: { click: _vm.settings }
            },
            [_vm._v("配置")]
          ),
          _vm._v(" "),
          _vm.buttonShow("DELIVER.BILL.DOWNLOAD") && _vm.activeTab !== "6"
            ? _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.downloadBill } },
                [_vm._v("下载账单")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.buttonShow("DELIVER.BILL.MAKE_BILL")
            ? _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.additional } },
                [_vm._v("补出账单")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "自定义列表字段",
            visible: _vm.dialogVisible,
            width: "30%",
            "custom-class": "setting-attr"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("el-alert", { attrs: { "show-icon": "", type: "info" } }, [
                _vm._v("请选择您想显示的列表详细信息")
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                _vm._l(_vm.columns, function(item, index) {
                  return _c(
                    "el-col",
                    { key: index, attrs: { label: item.label, span: 8 } },
                    [
                      _c("el-checkbox", {
                        attrs: { label: item.label },
                        model: {
                          value: _vm.columnsUnique[item.prop],
                          callback: function($$v) {
                            _vm.$set(_vm.columnsUnique, item.prop, $$v)
                          },
                          expression: "columnsUnique[item.prop]"
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAttrSetting }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading"
            }
          ],
          attrs: {
            title: "补出账单",
            "custom-class": "additional-dialog",
            "hide-required-asterisk": "",
            visible: _vm.isShowAdd,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowAdd = $event
            },
            close: function($event) {
              return _vm.cancelAction("additionalForm")
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "additionalForm", attrs: { model: _vm.additionalForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "order_id",
                    rules: { required: true, message: "请输入订单编号" }
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      clearable: "",
                      autocomplete: "off",
                      placeholder: "请输入订单编号"
                    },
                    model: {
                      value: _vm.additionalForm.order_id,
                      callback: function($$v) {
                        _vm.$set(_vm.additionalForm, "order_id", $$v)
                      },
                      expression: "additionalForm.order_id"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "month",
                    rules: { required: true, message: "请选择月份" }
                  }
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "yyyyMM",
                      format: "yyyy-MM",
                      type: "month",
                      placeholder: "请选择月份"
                    },
                    model: {
                      value: _vm.additionalForm.month,
                      callback: function($$v) {
                        _vm.$set(_vm.additionalForm, "month", $$v)
                      },
                      expression: "additionalForm.month"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.isShowAdd = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.confirmAction("additionalForm")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }