//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RedirectMixin from '../../mixins/page-event/redirect'

export default {
  mixins: [RedirectMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    isVueRouter: {
      type: Boolean,
      default: false
    }
  },
  /**
   * 选项可以是对象或字符串
   * 如果是对象取对应值
   */
  methods: {
    displayText(item) {
      return item && item.text ? item.text : item || ''
    },
    routeTo(item) {
      const route = this.getRoute(item)
      if (route && !this.isVueRouter) {
        this.redirect(route)
      }
    },
    getRoute(item) {
      return item && item.route ? item.route : null
    }
  }
}
