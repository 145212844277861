"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: '1-2',
  icon: 'el-icon-search',
  name: '客户查询',
  href: '/customer/inquiry',
  permission: '',
  description: ''
}, {
  index: '1-1',
  icon: 'el-icon-receiving',
  name: '客户局点',
  href: '/customer/point',
  permission: 'DELIVER.CUSTOMER.JUDIAN.LIST',
  description: ''
}];
var _default = items;
exports["default"] = _default;