//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '单号'
    }
  },
  data () {
    return {
      textareaValue: '',
      dialogVisible: false
    };
  },
  computed: {
    inputValue () {
      const newValue = [...new Set(this.textareaValue.split(/[(\r\n)\r\n]+/)
        .filter(i => i))]
        .join(',')
      this.$emit('input', newValue)
      return newValue
    }
  },
  watch: {
    value (newValue) {
      !newValue && (this.textareaValue = '');
    }
  },
  methods: {
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    }
  }
};
