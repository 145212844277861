"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _axios = _interopRequireDefault(require("axios"));

var _code = _interopRequireDefault(require("./code"));

var _cancel = _interopRequireDefault(require("./cancel"));

var _loading = _interopRequireDefault(require("./loading"));

var _debounce = _interopRequireDefault(require("./debounce"));

var _routePermission = _interopRequireDefault(require("./route-permission"));

var _renewSession = _interopRequireDefault(require("./renewSession"));

var _reportHandler = _interopRequireDefault(require("./reportHandler"));

var handlers = [_reportHandler["default"], _debounce["default"], _loading["default"], _cancel["default"], _routePermission["default"], _code["default"], _renewSession["default"]];
/**
 * 如需继续处理需要返回config
 */

function normalResolve(config) {
  return config;
}
/**
 * 如需继续处理reject需要返回Promise.reject
 */


function normalReject(error) {
  return _promise["default"].reject(error);
}
/**
 * 单文件添加拦截器
 * resolve与reject函数格式参考以上两个函数
 */


(0, _forEach["default"])(handlers).call(handlers, function (_ref) {
  var _ref$requestResolve = _ref.requestResolve,
      requestResolve = _ref$requestResolve === void 0 ? normalResolve : _ref$requestResolve,
      _ref$requestReject = _ref.requestReject,
      requestReject = _ref$requestReject === void 0 ? normalReject : _ref$requestReject,
      _ref$responseResolve = _ref.responseResolve,
      responseResolve = _ref$responseResolve === void 0 ? normalResolve : _ref$responseResolve,
      _ref$responseReject = _ref.responseReject,
      responseReject = _ref$responseReject === void 0 ? normalReject : _ref$responseReject;

  _axios["default"].interceptors.request.use(requestResolve, requestReject);

  _axios["default"].interceptors.response.use(responseResolve, responseReject);
});