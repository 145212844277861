"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.submitOextra = exports.getExtra = exports.submitOperations = exports.getOperations = exports.queryTaskInfo = exports.createFlow = exports.uploadFile = exports.queryRejectSteps = exports.rejectTask = exports.saveField = exports.changeDutyOfficer = exports.getEventList = exports.getTaskInfo = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source), true)).call(_context2, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context3; _forEachInstanceProperty(_context3 = ownKeys(Object(source))).call(_context3, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var url = '';
/**
 * 获取审批信息
 */

var getTaskInfo = function getTaskInfo() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + "/deliver/task/".concat(params.taskId, "/view")
  }, config));
};
/**
 * 获取活动清单列表
 */


exports.getTaskInfo = getTaskInfo;

var getEventList = function getEventList() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    url: url + "/deliver/task/".concat(query.taskId, "/event")
  }, config));
};
/**
 * 切换责任人
 */


exports.getEventList = getEventList;

var changeDutyOfficer = function changeDutyOfficer() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$params = _ref3.params,
      params = _ref3$params === void 0 ? {} : _ref3$params,
      _ref3$data = _ref3.data,
      data = _ref3$data === void 0 ? {} : _ref3$data,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + "/deliver/task/".concat(params.taskId, "/changeOwner")
  }, config));
};
/**
 * 保存草稿
 */


exports.changeDutyOfficer = changeDutyOfficer;

var saveField = function saveField() {
  var _context;

  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$params = _ref4.params,
      params = _ref4$params === void 0 ? {} : _ref4$params,
      _ref4$data = _ref4.data,
      data = _ref4$data === void 0 ? {} : _ref4$data,
      _ref4$config = _ref4.config,
      config = _ref4$config === void 0 ? {
    loading: false
  } : _ref4$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + (0, _concat["default"])(_context = "/deliver/task/field/".concat(params.taskId, "/")).call(_context, params.stepId)
  }, config));
};
/**
 * 驳回操作
 */


exports.saveField = saveField;

var rejectTask = function rejectTask() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$params = _ref5.params,
      params = _ref5$params === void 0 ? {} : _ref5$params,
      _ref5$data = _ref5.data,
      data = _ref5$data === void 0 ? {} : _ref5$data,
      _ref5$config = _ref5.config,
      config = _ref5$config === void 0 ? {
    loading: false
  } : _ref5$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + "/deliver/task/".concat(params.taskId, "/back")
  }, config));
};
/**
 * 获取驳回步骤
 */


exports.rejectTask = rejectTask;

var queryRejectSteps = function queryRejectSteps() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$params = _ref6.params,
      params = _ref6$params === void 0 ? {} : _ref6$params,
      _ref6$config = _ref6.config,
      config = _ref6$config === void 0 ? {
    loading: false
  } : _ref6$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: {},
    method: 'get',
    url: url + "/deliver/task/".concat(params.taskId, "/back")
  }, config));
};
/**
 * 文件上传接口
 */


exports.queryRejectSteps = queryRejectSteps;

var uploadFile = function uploadFile() {
  return url + '/deliver/tiantu/fileUpload';
};
/**
 * 审批提交
 */


exports.uploadFile = uploadFile;

var createFlow = function createFlow() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$query = _ref7.query,
      query = _ref7$query === void 0 ? {} : _ref7$query,
      _ref7$data = _ref7.data,
      data = _ref7$data === void 0 ? {} : _ref7$data,
      _ref7$config = _ref7.config,
      config = _ref7$config === void 0 ? {
    loading: false
  } : _ref7$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + "/deliver/task/".concat(query.taskId, "/pass")
  }, config));
};
/**
 * 获取任务信息
 */


exports.createFlow = createFlow;

var queryTaskInfo = function queryTaskInfo() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref8$params = _ref8.params,
      params = _ref8$params === void 0 ? {} : _ref8$params,
      _ref8$config = _ref8.config,
      config = _ref8$config === void 0 ? {
    loading: false
  } : _ref8$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: {},
    method: 'get',
    url: url + "/deliver/task/".concat(params.taskId, "/abstract")
  }, config));
};
/**
 * 运维信息获取
 * src/pages/project/plan/operations/Context.vue
 */


exports.queryTaskInfo = queryTaskInfo;

var getOperations = function getOperations() {
  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref9$params = _ref9.params,
      params = _ref9$params === void 0 ? {} : _ref9$params,
      _ref9$data = _ref9.data,
      data = _ref9$data === void 0 ? {} : _ref9$data,
      _ref9$config = _ref9.config,
      config = _ref9$config === void 0 ? {
    loading: false
  } : _ref9$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'get',
    url: url + "/deliver/devops/".concat(params.taskId)
  }, config));
};
/**
 * 运维信息上报
 * src/pages/project/plan/operations/Context.vue
 */


exports.getOperations = getOperations;

var submitOperations = function submitOperations() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref10$params = _ref10.params,
      params = _ref10$params === void 0 ? {} : _ref10$params,
      _ref10$data = _ref10.data,
      data = _ref10$data === void 0 ? {} : _ref10$data,
      _ref10$config = _ref10.config,
      config = _ref10$config === void 0 ? {
    loading: false
  } : _ref10$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + "/deliver/devops/".concat(params.taskId)
  }, config));
};
/**
 * 服务包评审字段获取
 * src/pages/project/plan/operations/Context.vue
 */


exports.submitOperations = submitOperations;

var getExtra = function getExtra() {
  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref11$query = _ref11.query,
      query = _ref11$query === void 0 ? {} : _ref11$query,
      _ref11$params = _ref11.params,
      params = _ref11$params === void 0 ? {} : _ref11$params,
      _ref11$data = _ref11.data,
      data = _ref11$data === void 0 ? {} : _ref11$data,
      _ref11$config = _ref11.config,
      config = _ref11$config === void 0 ? {
    loading: false
  } : _ref11$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: params,
    method: 'get',
    url: url + "/deliver/task/".concat(query.taskId, "/extra")
  }, config));
};
/**
 * 服务包评审字段提交
 * src/pages/project/plan/operations/Context.vue
 */


exports.getExtra = getExtra;

var submitOextra = function submitOextra() {
  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref12$query = _ref12.query,
      query = _ref12$query === void 0 ? {} : _ref12$query,
      _ref12$params = _ref12.params,
      params = _ref12$params === void 0 ? {} : _ref12$params,
      _ref12$data = _ref12.data,
      data = _ref12$data === void 0 ? {} : _ref12$data,
      _ref12$config = _ref12.config,
      config = _ref12$config === void 0 ? {
    loading: false
  } : _ref12$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + "/deliver/task/".concat(query.taskId, "/extra")
  }, config));
};

exports.submitOextra = submitOextra;