"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.search = search;
exports.match = match;
exports.normalValue = normalValue;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/slicedToArray"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

/**
 * 分割
 */
function normalValue(value) {
  var i = (0, _indexOf["default"])(value).call(value, '(');
  var result = i === -1 ? value : value.substring(0, i);
  return result.toLowerCase();
}
/**
 * 获得名称
 */


function normalName(_ref) {
  var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      ename = _ref2[0],
      cname = _ref2[1];

  return normalValue(ename);
}
/**
 * 二分查找
 */


function binMatch(items, query) {
  var low = 0;
  var high = items.length - 1;
  var mid;
  var en1 = normalValue(query);

  while (low <= high) {
    if (normalName(items[low]) === en1) {
      return low;
    } else if (normalName(items[high]) === en1) {
      return high;
    }

    mid = Math.floor((low + high) / 2); // 判断 en1 的位置是在前半还是后半?

    var en2 = normalName(items[mid]);

    if (en1 === en2) {
      return mid;
    }

    if (en2 > en1) {
      high = mid - 1;
    } else {
      low = mid + 1;
    }
  }

  return -1;
} // 二分搜索(开头匹配)


function binSearch(items, query) {
  var low = 0;
  var high = items.length - 1;
  var mid;
  var en2;
  var en1 = normalValue(query);

  while (low <= high) {
    // 低位查找
    en2 = normalName(items[low]);

    if ((0, _indexOf["default"])(en2).call(en2, en1) === 0) {
      while (low >= 0 && (0, _indexOf["default"])(_context = normalName(items[low])).call(_context, en1) === 0) {
        var _context;

        low--;
      }

      return low + 1;
    } // 高位查找


    en2 = normalName(items[high]);

    if (high >= 0 && (0, _indexOf["default"])(en2).call(en2, en1) === 0) {
      while ((0, _indexOf["default"])(_context2 = normalName(items[high])).call(_context2, en1) === 0) {
        var _context2;

        high--;
      }

      return high + 1;
    }

    mid = Math.floor((low + high) / 2); // 判断 en1 的位置是在前半还是后半?

    en2 = normalName(items[mid]);

    if ((0, _indexOf["default"])(en2).call(en2, en1) === 0) {
      while (mid >= 0 && (0, _indexOf["default"])(_context3 = normalName(items[mid])).call(_context3, en1) === 0) {
        var _context3;

        mid--;
      }

      return mid + 1;
    }

    if (en2 > en1) {
      high = mid - 1;
    } else {
      low = mid + 1;
    }
  }

  return -1;
}

function search(items, query, limit) {
  var i, j;
  i = binSearch(items, query); // 没有找到直接返回空列表

  if (i === -1) {
    return [];
  }

  j = i + limit;
  j = j > items.length ? items.length : j;
  var results = (0, _slice["default"])(items).call(items, i, j); // 不返回过于模糊结果

  return (0, _filter["default"])(results).call(results, function (item) {
    var _context4;

    return item && item[0] && (0, _indexOf["default"])(_context4 = item[0]).call(_context4, query) !== -1;
  });
}

function match(items, query) {
  var i;
  i = binMatch(items, query);

  if (i === -1) {
    return null;
  }

  return items[i];
}