"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _elementUi = require("element-ui");

var _errors = require("../../../laravel/errors");

var _default = {
  requestResolve: function requestResolve() {
    var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    config.requestTime = new Date().getTime();
    return config;
  },
  responseResolve: function responseResolve(response) {
    var _response$data = response.data,
        data = _response$data === void 0 ? {} : _response$data,
        status = response.status,
        config = response.config;

    if (config && config.handleResError) {
      return response;
    }

    if (status === 200 && data.code && data.code !== 0 && data.message) {
      _elementUi.MessageBox.alert(data.message || '请求发生错误, 请稍后再试', {
        title: '业务错误',
        type: 'error'
      });
    }

    return response;
  },
  responseReject: function responseReject(error) {
    // 如果配置设置自己处理异常, 就直接 reject
    if (error.config && error.config.handleError) {
      return _promise["default"].reject(error);
    }

    var promise = _promise["default"].resolve();

    if (!error.response) {
      // 极端情况下
      promise = _elementUi.MessageBox.alert(error.message || '请求发生错误, 请稍后再试', {
        title: '请求错误',
        type: 'error'
      });
    } // const { status = 0, data = {}, headers = {} } = error.response


    var _error$response = error.response,
        _error$response$statu = _error$response.status,
        status = _error$response$statu === void 0 ? 0 : _error$response$statu,
        _error$response$data = _error$response.data,
        data = _error$response$data === void 0 ? {} : _error$response$data;

    if (status === 422) {
      // laravel 参数验证错误
      promise = _elementUi.MessageBox.alert((0, _errors.formatErrors)(data), {
        title: '参数错误',
        type: 'error',
        dangerouslyUseHTMLString: true
      });
    } else if (status === 401) {
      // const loginURL = '/deliver/login?next=' + location.href.replace(location.search, '')
      var loginURL = '/deliver/login?next=' + location.href;
      location.href = loginURL;
    } else if (status === 403) {
      var title = '鉴权失败: 您没有权限使用此功能';
      var content = "\u8054\u7CFB\u5C0FO\u52A9\u624B\uFF08\u4E1A\u52A1\u7ECF\u8425\u7BA1\u7406\uFF09\u5F00\u901A\u6743\u9650\uFF01";
      promise = _elementUi.MessageBox.alert(content, title, {
        type: 'error',
        dangerouslyUseHTMLString: true
      });
    } else if (status === 413) {
      promise = _elementUi.MessageBox.alert('发送内容太大，请尝试修改发送内容', {
        type: 'error'
      });
    } else if (status === 501) {
      promise = _elementUi.MessageBox.alert('请求被拒绝(501)', {
        type: 'error'
      });
    } else if (status >= 400) {
      // 其它错误
      promise = _elementUi.MessageBox.alert(data.message || (status === 404 ? '请求资源未找到' : '请求发生错误, 请稍后再试'), {
        title: '请求错误',
        type: 'error'
      });
    } else {
      promise = _promise["default"].resolve();
    } // else if (status === 401) {
    //   const authLocation = headers['x-auth-location'];
    //   // 必须有登录地址，才能直接跳转
    //   if (error.config.url === '/user' && authLocation) {
    //     window.location.href = authLocation;
    //   } else {
    //     promise = MessageBox.alert(`登录状态已失效，请点击 '确定' 重新登录`, {
    //       title: '登录状态已失效',
    //       type: 'error',
    //       showClose: false,
    //       showCancelButton: true,
    //       cancelButtonText: '通过新窗口重新登录'
    //     }).then(() => {
    //       // oa状态下不一定返回x-auth-location
    //       const location = window.location
    //       if (authLocation) {
    //         location.href = authLocation;
    //       } else {
    //         location.href = '/deliver/login?next=' + location.href.replace(location.search, '');
    //       }
    //     }).catch(() => {
    //       const redirectLocation = authLocation || window.location.href;
    //       window.open(redirectLocation, '_blank');
    //     });
    //   }
    // } else if (status === 403) {
    //   const title = '鉴权失败: 您没有权限使用此功能';
    //   const content = `<a href="//qflow.woa.com/v2/#/product/TSS/apply?id=480">申请权限</a>`;
    //   promise = MessageBox.alert(content, title, {
    //     type: 'error',
    //     dangerouslyUseHTMLString: true
    //   });
    // }


    return promise.then((0, _errors.responseHandle)(error.response));
  }
};
exports["default"] = _default;