//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Popper from 'element-ui/src/utils/vue-popper';
import Emitter from 'element-ui/src/mixins/emitter';
// import ElScrollbar from 'element-ui/lib/scrollbar';

export default {
  // components: { ElScrollbar },
  mixins: [Popper, Emitter],
  componentName: 'UserChooserSuggestions',
  data () {
    return {
      parent: this.$parent,
      dropdownWidth: ''
    };
  },
  props: {
    options: {
      default () {
        return {
          gpuAcceleration: false
        };
      }
    }
  },
  updated () {
    this.$nextTick(_ => {
      this.updatePopper();
    });
  },
  mounted () {
    this.$parent.popperElm = this.popperElm = this.$el;
    this.referenceElm = this.$parent.$refs.input.$refs.input;
    this.referenceList = this.$el.querySelector('.el-autocomplete-suggestion__list');
    this.referenceList.setAttribute('role', 'listbox');
  },
  created () {
    this.$on('visible', (val, inputWidth) => {
      this.dropdownWidth = inputWidth + 'px';
      this.showPopper = val;
    });
  }
};
