var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("实际提报")]),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { "label-width": "110px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "实际金额" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.orderInfo.real_price) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("发生时间")
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.orderInfo.real_begin_time) +
                        " - " +
                        _vm._s(_vm.orderInfo.real_end_time) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.orderInfo.type === "transportation"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "交通工具" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("trafficType")(
                              _vm.orderInfo.real_traffic_type
                            )
                          ) + " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "交通费类型" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("isGoBack")(_vm.orderInfo.real_is_go_back)
                          ) + " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.orderInfo.type === "transportation" ||
          _vm.orderInfo.type === "subsidy"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "出发城市" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.real_outset) + " ")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "目的城市" } }, [
                        _vm._v(_vm._s(_vm.orderInfo.real_destination) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "发生原因" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.orderInfo.real_reason) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.orderInfo.type === "transportation"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "凭证" } },
                        _vm._l(_vm.orderInfo.attachment, function(item, index) {
                          return _c("p", { key: index }, [
                            _c(
                              "a",
                              {
                                attrs: { href: item.file_url, target: "_blank" }
                              },
                              [_vm._v(_vm._s(item.realfile_name))]
                            )
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }