"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _ompServiceSdk = require("@tencent/omp-service-sdk");

var _sessionId = require("./sessionId");

var report = function report() {
  (0, _sessionId.renewSession)();
  var url = window.location.href.split('?')[0];
  (0, _ompServiceSdk.reportPageView)({
    route: url,
    sessionId: (0, _sessionId.getSessionId)()
  });
};

var _default = report;
exports["default"] = _default;