//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: [String, Array],
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    multipleLimit: {
      type: Number,
      default: 3
    },
    valueFormat: {
      type: String,
      default: 'en'
    }
  },
  mounted() {},
  data() {
    return {
      svalue: null,
      options: [],
      loading: false
    }
  },
  watch: {
    // 响应数据变化
    value: {
      handler(v) {
        if (v && v !== '' && v !== undefined) {
          if (this.multiple) {
            // 多选情况下默认赋值
            let newarr = v.split(';').reduce((arr, item, idx) => {
              item && (arr[idx] = item)
              return arr
            }, [])
            this.svalue = newarr
          } else {
            this.svalue = v
          }
        }
        // 当在查询条件中重置表单之后-数据为空则设置数据为空
        if (v === '' || v === undefined || v === null) {
          this.svalue = ''
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    onChange(value) {
      let iptVal = ''
      if (Array.isArray(value)) {
        iptVal = value.join(';')
      } else {
        iptVal = value
      }
      this.$emit('input', iptVal)
    },
    requestSearchUser(queryString) {
      if (queryString) {
        this.loading = true
        this.$rest
          .get('/deliver/searchUserList', {
            params: {
              name: queryString && queryString.trim()
            },
            handleError: true
          })
          .then(({ data }) => {
            this.loading = false
            if (data.code === 0) {
              this.options = data.data
            }
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.options = []
      }
    }
  }
}
