"use strict";

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var mixins = _interopRequireWildcard(require("./props"));

var _methods = _interopRequireDefault(require("./methods"));

var _extend = _interopRequireDefault(require("./methods/extend"));

var _interface = require("../ElementPagination/interface");

var _main = _interopRequireDefault(require("./components/main"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source), true)).call(_context2, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context3; _forEachInstanceProperty(_context3 = ownKeys(Object(source))).call(_context3, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  name: 'EleTable',
  mixins: [_methods["default"]],
  props: {
    options: Object,
    tableOptions: Object,
    paginationOptions: Object,
    columnOptions: {
      type: Array,
      required: true
    },
    url: {
      type: Function
    }
  },
  // watch: {
  //   options: {
  //     deep: true,
  //     handler (newValue, oldValue) {}
  //   },
  //   paginationOptions: {
  //     deep: true,
  //     handler (newValue, oldValue) {}
  //   }
  // },
  data: function data() {
    return {
      columnsUnique: {},
      defaultOptions: {
        type: 'GET',
        container: false,
        // 'el-card',
        extraParams: {},
        filters: [{
          defaultRender: true,
          canClearSearch: true,
          searchButton: {
            text: '查询'
          }
        }],
        actionColumn: {
          hidden: false,
          // fixed: 'right',
          minWidth: '150',
          label: '操作'
        },
        pagination: true,
        loading: true
      },
      defaultTableOptions: mixins.TableAttributes,
      defaultTableColumnOptions: mixins.TableColumnAttributes,
      defaultPaginationOptions: _interface.PaginationAttributes
    };
  },
  computed: {
    attributes: function attributes() {
      return (0, _extend["default"])(true, {}, this.defaultOptions, this.options);
    },
    tableAttributes: function tableAttributes() {
      return _objectSpread(_objectSpread({}, this.defaultTableOptions), this.tableOptions);
    },
    tableColumnAttributes: function tableColumnAttributes() {
      var _context,
          _this = this;

      return (0, _map["default"])(_context = this.columnOptions).call(_context, function (columnOption) {
        var prop = columnOption.prop,
            type = columnOption.type,
            formatter = columnOption.formatter,
            _columnOption$outputF = columnOption.outputFormat,
            outputFormat = _columnOption$outputF === void 0 ? 'YYYY-MM-DD HH:mm:ss' : _columnOption$outputF;

        if (type === 'date' && formatter === undefined) {
          columnOption['formatter'] = function (row, column, cellValue, index) {
            if (cellValue) {
              return _this.$moment(cellValue).format(outputFormat);
            } else {
              return '';
            }
          };
        }

        if (typeof _this.columnsUnique[prop] === 'boolean') {
          columnOption['hidden'] = _this.columnsUnique[prop];
        }

        return _objectSpread(_objectSpread({}, _this.defaultTableColumnOptions), columnOption);
      });
    },
    actionColumnAttributes: function actionColumnAttributes() {
      return _objectSpread(_objectSpread({}, this.defaultTableColumnOptions), this.attributes.actionColumn);
    },
    paginationAttributes: function paginationAttributes() {
      return _objectSpread(_objectSpread({}, this.defaultPaginationOptions), this.paginationOptions);
    }
  },
  methods: {
    attachEvents: function attachEvents() {
      var _this2 = this;

      this.$on('render', function () {
        return _this2.$main.handleHttpRequest();
      });
      this.$on('setGridParams', function (params) {
        (0, _extend["default"])(true, _this2.defaultOptions.extraParams, params);
      });
    },
    checkConfiguration: function checkConfiguration() {
      var _this3 = this;

      // const urlMissing = `ElementTable: 检查初始化配置, 缺失 URL`
      var columnMissing = "ElementTable: \u68C0\u67E5\u521D\u59CB\u5316\u914D\u7F6E, \u7F3A\u5931 columnOptions";
      return new _promise["default"](function (resolve, reject) {
        if (!_this3.columnOptions) {
          reject(columnMissing);
        } else {
          resolve();
        }
      });
    }
  },
  render: function render(h) {
    var _this4 = this;

    var attributes = this.attributes,
        tableAttributes = this.tableAttributes,
        tableColumnAttributes = this.tableColumnAttributes,
        actionColumnAttributes = this.actionColumnAttributes,
        paginationAttributes = this.paginationAttributes,
        $props = this.$props;
    return h(_main["default"], {
      ref: 'main',
      props: _objectSpread({
        attributes: attributes,
        tableAttributes: tableAttributes,
        tableColumnAttributes: tableColumnAttributes,
        actionColumnAttributes: actionColumnAttributes,
        paginationAttributes: paginationAttributes
      }, $props),
      on: _objectSpread(_objectSpread({}, this.$listeners), {}, {
        updateColumns: function updateColumns(columnsUnique) {
          _this4.columnsUnique = columnsUnique;

          _this4.$emit('topStrataColums', columnsUnique);
        },
        httpChange: function httpChange(_ref) {
          var defaultTableOptions = _ref.defaultTableOptions,
              defaultPaginationOptions = _ref.defaultPaginationOptions;
          _this4.defaultTableOptions = _objectSpread(_objectSpread({}, _this4.defaultTableOptions), defaultTableOptions);
          _this4.defaultPaginationOptions = _objectSpread(_objectSpread({}, _this4.defaultPaginationOptions), defaultPaginationOptions);
        },
        paginationChange: function paginationChange(defaultPaginationOptions) {
          _this4.defaultPaginationOptions = _objectSpread(_objectSpread({}, _this4.defaultPaginationOptions), defaultPaginationOptions);
        }
      })
    });
  },
  mounted: function mounted() {
    var _this5 = this;

    this.checkConfiguration().then(function (_) {
      _this5.$main = _this5.$refs['main'];

      _this5.attachEvents();
    })["catch"](function (error) {
      _this5.$message.error(error);
    });
  }
};
exports["default"] = _default;