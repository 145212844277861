"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty2 = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty2 = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty2(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context2; _forEachInstanceProperty2(_context2 = ownKeys(Object(source), true)).call(_context2, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context3; _forEachInstanceProperty2(_context3 = ownKeys(Object(source))).call(_context3, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

// 判断侧边栏权限
var _default = {
  watch: {
    user: function user(_user) {
      if (_user) {
        this.sidebar = this.resloveSidebarPermission();
      }
    }
  },
  methods: {
    resloveSidebarPermission: function resloveSidebarPermission() {
      var _this = this;

      var config = this.sidebarConfig || [];
      var sidebar = [];
      (0, _forEach["default"])(config).call(config, function (item) {
        // 子菜单筛选出有权限的 item
        if (item.children && item.children.length > 0) {
          var _context;

          var subItems = (0, _filter["default"])(_context = item.children).call(_context, function (item) {
            if (item.permission) {
              return _this.hasPermission(item.permission);
            }

            return true;
          }); // 只要有一个有权限的菜单, 加入 sidebar 中

          if (subItems.length > 0) {
            var menuItem = _objectSpread({}, item);

            menuItem['children'] = subItems; // 只留有权限的

            sidebar.push(menuItem);
          }
        } else if (!item.permission) {
          // 无配置项直接添加
          sidebar.push(_objectSpread({}, item));
        } else if (item.permission && _this.hasPermission(item.permission)) {
          // 普通菜单筛选原样处理
          sidebar.push(_objectSpread({}, item));
        }
      });
      return sidebar;
    }
  }
};
exports["default"] = _default;