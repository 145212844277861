"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: '0',
  icon: 'el-icon-document',
  name: '订单查询',
  opened: true,
  children: [{
    index: '1',
    name: 'A类订单',
    href: '/oms/a',
    permission: 'DELIVER.ORDER.A.LIST',
    description: 'A类订单查询列表信息'
  }, {
    index: '2',
    name: 'BCD类订单',
    href: '/oms/bcd',
    permission: 'DELIVER.ORDER.BCD.LIST',
    description: 'BCD类订单查询列表信息'
  }, {
    index: '3',
    name: 'E类订单',
    href: '/oms/e',
    permission: 'DELIVER.ORDER.E.LIST',
    description: 'E类订单查询列表信息'
  }]
}, {
  index: '7',
  icon: 'el-icon-help',
  name: '订单变更',
  opened: true,
  children: [{
    index: '7-1',
    name: '我的变更',
    href: '/oms/modify/my',
    permission: '',
    description: 'A类订单查询列表信息'
  }, {
    index: '7-2',
    name: '变更审批',
    href: '/oms/modify/approval',
    permission: '',
    description: 'BCD类订单查询列表信息'
  }, {
    index: '7-3',
    name: '变更日志',
    href: '/oms/modify/log',
    permission: '',
    description: 'BCD类订单查询列表信息'
  }]
} // {
//   index: '8',
//   name: '审批人变更',
//   icon: 'el-icon-edit-outline',
//   href: '/oms/approval-change',
//   permission: 'DELIVER.PROJECT.APPROVAL_PERSON_UPDATE',
//   description: '审批人变更工具'
// },
// {
//   index: '10',
//   name: '订单工具',
//   icon: 'el-icon-s-tools',
//   children: [
//     {
//       index: '10-1',
//       name: 'A类订单作废',
//       href: '/oms/tools/cancel',
//       permission: 'DELIVER.ORDER.TOOL.AORDERDELETE',
//       description: 'A类订单作废'
//     },
//     {
//       index: '10-2',
//       name: 'A类订单变更',
//       href: '/oms/tools/change',
//       permission: 'DELIVER.ORDER.TOOL.AORDERCHANGE',
//       description: 'A类订单变更'
//     }
//   ]
// }
];
var _default = items;
exports["default"] = _default;