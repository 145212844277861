"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _lodash = _interopRequireDefault(require("lodash"));

var _api = require("src/pages/workbench/api");

// 1=内网局点信息页面 ：http://redcs.oa.com/workbench/point-info/?task_id=******
// 2=内网交付作业页面 ：http://redcs.oa.com/workbench/delivery-work/?task_id=******
// 3=外网局点信息页面 ：https://redcs.tencent.com/workbench/point-info/?task_id=******
// 4=外网局点信息页面 ：https://redcs.tencent.com/workbench/delivery-work/?task_id=******
var _default = {
  data: function data() {
    return {
      hasEditPermission: false,
      hasRelatePermission: false,
      _roleCode: '',
      _userId: ''
    };
  },
  computed: {
    isOA: function isOA() {
      return location.hostname === 'redcs.oa.com';
    },
    isTencent: function isTencent() {
      return location.hostname === 'redcs.tencent.com';
    },
    isTest: function isTest() {
      return !(this.isOA || this.isTencent);
    }
  },
  methods: {
    jumpBeforeValidate: function jumpBeforeValidate() {
      var _this = this;

      return new _promise["default"](function (resolve, reject) {
        if (!_this._userId) {
          _this.$message.error('请联系区技公司HR开通P账号');

          reject(new Error('请联系区技公司HR开通P账号'));
        } else {
          resolve();
        }
      });
    },
    addJudian: function addJudian(_ref) {
      var _this2 = this;

      var ltc = _ref.ltc,
          orderId = _ref.orderId,
          taskId = _ref.taskId,
          pageId = _ref.pageId;
      this.jumpBeforeValidate().then(function () {
        var urlSearch = ["ltc=".concat(ltc), "orderid=".concat(orderId), "task_id=".concat(taskId), "Role=".concat(_this2._roleCode), "StaffId=".concat(_this2._userId), "Pageid=".concat(pageId)].join('&');

        if (!ltc || !orderId || !taskId || !pageId) {
          console.log(urlSearch);
        }

        if (_this2.isOA) {
          window.open("//andon.woa.com/base/itom/project-info/edit-gamepoint?".concat(urlSearch));
          return;
        }

        if (_this2.isTencent) {
          window.open("//andon.cloud.tencent.com/base/itom/project-info/edit-gamepoint?".concat(urlSearch));
          return;
        }

        window.open("//t1.andon.woa.com/base/itom/project-info/edit-gamepoint?".concat(urlSearch));
      });
    },
    changeJudian: function changeJudian(_ref2) {
      var _this3 = this;

      var ltc = _ref2.ltc,
          jid = _ref2.jid,
          taskId = _ref2.taskId,
          pageId = _ref2.pageId;
      this.jumpBeforeValidate().then(function () {
        var urlSearch = ["ltc=".concat(ltc), "jid=".concat(jid), "task_id=".concat(taskId), "Role=".concat(_this3._roleCode), "StaffId=".concat(_this3._userId), "Pageid=".concat(pageId)].join('&');

        if (!ltc || !jid || !taskId || !pageId) {
          console.log(urlSearch);
        }

        if (_this3.isOA) {
          window.open("//andon.woa.com/base/itom/project-info/edit-gamepoint??".concat(urlSearch));
          return;
        }

        if (_this3.isTencent) {
          window.open("//andon.cloud.tencent.com/base/itom/project-info/edit-gamepoint?".concat(urlSearch));
          return;
        }

        window.open("//t1.andon.woa.com/base/itom/project-info/edit-gamepoint?".concat(urlSearch));
      });
    }
  },
  created: function created() {
    var _this4 = this;

    (0, _api.judianValidate)({
      query: {
        taskId: this.taskId
      }
    }).then(function (resp) {
      if (_lodash["default"].get(resp, 'data.data.is_team') === 0 && !_this4.isRelatePointAdmin) {
        _this4.$alert(_lodash["default"].get(resp, 'data.message'), '提示', {
          confirmButtonText: '我知道了'
        });
      }

      if (_lodash["default"].get(resp, 'data.data.is_team') === 1) {
        _this4.hasRelatePermission = true;
        _this4.hasEditPermission = true;
        _this4._roleCode = _lodash["default"].get(resp, 'data.data.role_code');
        _this4._userId = _lodash["default"].get(resp, 'data.data.user_id');
      }

      if (_lodash["default"].get(resp, 'data.data.is_team') === 2) {
        _this4.hasRelatePermission = true;
      }
    });
  }
};
exports["default"] = _default;