//
//
//
//
//
//
//

export default {
  props: {
    img: {
      type: String,
      default: '/watermark'
    },
    opacity: {
      type: Number,
      default: 0.1
    }
  },
  computed: {
    watermarkStyle: function () {

    }
  }
}
