"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: '7',
  icon: 'el-icon-help',
  name: '权限控制',
  opened: true,
  children: [{
    index: '7-1',
    name: '客服权限',
    href: '/admin/permission/manage',
    permission: 'BACKEND.PERMISSION.MANAGE',
    description: '控制网站和登录权限'
  } // {
  //   index: '7-2',
  //   name: '权限模版',
  //   href: '/admin/permission/template',
  //   permission: 'BACKEND.PERMISSION.MANAGE',
  //   description: '权限快速管理模版'
  // }
  ]
}, {
  index: '8',
  icon: 'el-icon-warning',
  name: '监控管理',
  children: [{
    index: '8-1',
    name: '脚本监控',
    href: '/admin/monitor',
    permission: 'BACKEND.MONITOR.SCRIPT',
    description: '脚本监控'
  } // {
  //   index: '7-2',
  //   name: '权限模版',
  //   href: '/admin/permission/template',
  //   permission: 'BACKEND.PERMISSION.MANAGE',
  //   description: '权限快速管理模版'
  // }
  ]
} // {
//   index: '6',
//   icon: 'el-icon-service',
//   name: '客服管理',
//   href: '/admin/staff',
//   permission: 'BACKEND.ACCOUNT.VIEW',
//   description: '管理客服基础信息'
// },
// {
//   index: '5',
//   icon: 'el-icon-edit',
//   name: '工作岗位',
//   href: '/admin/group',
//   permission: 'BACKEND.ACCOUNT_GROUP.VIEW',
//   description: '维护客服工作岗位'
// }
];
var _default = items;
exports["default"] = _default;