"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _sessionId = require("src/libs/sessionId");

/**
 * uvpv session续期功能 30 分钟
 * @author ddaleli
 */
var _default = {
  requestResolve: function requestResolve(response) {
    // 续期session
    (0, _sessionId.renewSession)();
    return response;
  }
};
exports["default"] = _default;