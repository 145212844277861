var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-checkbox",
    {
      attrs: { value: _vm.isAllChecked, indeterminate: _vm.indeterminate },
      on: { change: _vm.change }
    },
    [_vm._v("\n  " + _vm._s(_vm.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }