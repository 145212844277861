var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-header" }, [
    _c(
      "div",
      {
        staticClass: "el-menu-item page-logo",
        on: {
          click: function($event) {
            return _vm.handleRedirect("/")
          }
        }
      },
      [_c("img", { attrs: { src: _vm.logo } })]
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "page-menu el-menu el-menu--horizontal",
        attrs: { role: "menubar" }
      },
      [
        _vm.user
          ? [
              _vm._l(_vm.navlist, function(item, index) {
                return [
                  _vm.hasPermission(item.permission)
                    ? _c(
                        "li",
                        {
                          key: index,
                          staticClass: "el-menu-item",
                          attrs: { role: "menuitem", tabindex: index },
                          on: {
                            click: function($event) {
                              return _vm.handleRedirect(item.redirectUrl)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    : _vm._e()
                ]
              })
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("li", { staticClass: "el-menu-item", attrs: { role: "menuitem" } })
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "user-item" }, [
      _vm._v("\n    " + _vm._s(_vm.user.user_name) + "\n    "),
      _vm.user.company
        ? _c("span", [
            _vm._v("( " + _vm._s(_vm.user.company.short_name) + " )")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("a", { attrs: { href: _vm.href } }, [_vm._v("退出")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }