//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  props: {
    permissions: {
      type: Array,
      default () {
        return []
      }
    },
    myPermissions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    /**
     * 先从已分配权限myPermission中查找对应的permission对象
     * 如果没有分配权限则从permissions中拿出permission对象
     */
    getPermission (permission = this.permission) {
      return this.myPermissions
        .concat(this.permissions)
        .find(({ code }) => {
          return code === permission;
        });
    }
  }
}
