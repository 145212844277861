//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storage from '../../utils/storage';

const { save: savePanelStatus, get: getPanelStatus } = storage('aside-panel');
export default {
  props: {
    title: {
      type: String
    },
    items: {
      type: Array
    },
    active: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      menuItems: {},
      opened: [],
      visible: true
    }
  },
  computed: {
    defaultAsideWidth () {
      return 160;
    },
    switchBtnLeftDistance () {
      return {
        marginLeft: this.visible ? `${this.defaultAsideWidth - 16}px` : '0'
      };
    },
    switchBtnIcon () {
      return this.visible ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right';
    },
    asideWidth () {
      return this.visible ? `${this.defaultAsideWidth}px` : '0px'
    }
  },
  watch: {
    items () {
      this.initMenuItem();
    }
  },
  methods: {
    changeVisible () {
      let visible = this.visible;
      this.$emit('before-change', visible);
      visible = !visible;
      savePanelStatus(visible)
      this.visible = visible;
      this.$emit('after-change', visible);
    },
    hasChildrenItem (children) {
      return Array.isArray(children) && children.length > 0;
    },
    handleSelectMenuItem (index) {
      this.$emit('select-menu', this.menuItems[index]);
    },
    initMenuItem () {
      let opened = [];
      let menuItems = {};
      this.items.forEach((item) => {
        menuItems[item.index] = item;
        if (item.children && item.children.length > 0) {
          if (item.opened) {
            opened.push(item.index);
          }
          item.children.forEach((item) => {
            menuItems[item.index] = item;
          });
        }
      });
      this.opened = opened;
      this.menuItems = menuItems;
    }
  },
  /**
   * toString()对比'false'以修复firefox在localStorage不能存储布尔值
   * 默认展开设定为true
   */
  created () {
    const status = getPanelStatus() || true;
    const visible = status.toString() !== 'false';
    this.visible = visible;
    this.initMenuItem();
  }
}
