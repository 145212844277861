//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getOrderBCDInfo } from 'src/pages/oms/api';
import _ from 'lodash';

export default {
  props: {
    orderid: {
      type: String,
      default: ''
    },
    result: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      collapse: true,
      result2: {}
    };
  },
  computed: {
    detail() {
      return { ...this.result2, ...this.result };
    }
  },
  methods: {
    load() {
      const orderid = this.orderid;
      if (orderid) {
        getOrderBCDInfo({ query: { order_id: orderid } })
          .then(({ data }) => {
            if (data && data.code === 0) {
              this.result2 = data.data;
            } else {
              if (_.get(data, 'message')) {
                this.$message.error(_.get(data, 'message'));
              }
            }
          })
          .catch(() => {});
      }
    }
  },
  created() {
    this.load();
  }
};
