"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _highcharts = _interopRequireDefault(require("./highcharts/highcharts.js"));

var Highcharts = _highcharts["default"];
Highcharts.setOptions({
  global: {
    useUTC: false
  },
  credits: {
    enabled: false
  }
});
var _default = Highcharts;
exports["default"] = _default;