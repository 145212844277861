//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { planType, isGoBack, trafficType, planStatus } from './filter'
export default {
  props: {
    tableData: Array
  },
  filters: {
    planType: function(val) {
      return planType(val)
    },
    isGoBack: function(val) {
      return isGoBack(val)
    },
    trafficType: function(val) {
      return trafficType(val)
    },
    planStatus: function(val) {
      return planStatus(val)
    }
  }
}
