var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form" },
    [
      _c(
        "el-row",
        {
          staticClass: "collapse-container",
          class: { collapse: _vm.collapse },
          attrs: { gutter: 20 }
        },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-form-item", { attrs: { label: "PO单ID:" } }, [
                _vm._v(_vm._s(_vm.detail.po_id))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "子项目id:" } }, [
                _vm._v(_vm._s(_vm.detail.sub_project_id))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单开始日期:" } }, [
                _vm._v(_vm._s(_vm.detail.order_enter_date))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "LTC立项编号:" } }, [
                _vm._v(_vm._s(_vm.detail.ltc_id))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "项目实施地:" } }, [
                _vm._v(_vm._s(_vm.detail.project_region))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单类型:" } }, [
                _vm._v(_vm._s(_vm.detail.order_type))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "项目行业:" } }, [
                _vm._v(_vm._s(_vm.detail.project_trade))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "LTC产品ID:" } }, [
                _vm._v(_vm._s(_vm.detail.ltc_product_id))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "产品简称:" } }, [
                _vm._v(_vm._s(_vm.detail.product_name))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "产品版本:" } }, [
                _vm._v(_vm._s(_vm.detail.deliver_version))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "服务大类:" } }, [
                _vm._v(_vm._s(_vm.detail.service_stage))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单派发月份:" } }, [
                _vm._v(_vm._s(_vm.detail.dispatch_month))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单状态简称:" } }, [
                _vm._v(_vm._s(_vm.detail.order_status))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单预估工时:" } }, [
                _vm._v(_vm._s(_vm.detail.spent_time))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单金额(元):" } }, [
                _vm._v(_vm._s(_vm.detail.order_price))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "累计基础服务费:" } }, [
                _vm._v(_vm._s(_vm.detail.base_order_account))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "入场条件说明:" } }, [
                _vm._v(_vm._s(_vm.detail.enter_condition_desc))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "服务实施地点:" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      "" +
                        (_vm.detail.pjplace_province || "") +
                        (_vm.detail.pjplace_province ? "-" : "") +
                        (_vm.detail.pjplace_city || "")
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-form-item", { attrs: { label: "qflow任务ID:" } }, [
                _vm._v(_vm._s(_vm.detail.qflow_task_id))
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "cn订单链接:" } },
                [
                  _vm.detail.cn_url
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            trigger: "hover",
                            content: _vm.detail.cn_url
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "el-button--text",
                              attrs: {
                                slot: "reference",
                                target: "_blank",
                                href: _vm.detail.cn_url
                              },
                              slot: "reference"
                            },
                            [_vm._v("点击查看")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "下单日期:" } }, [
                _vm._v(_vm._s(_vm.detail.order_date))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单结束日期:" } }, [
                _vm._v(_vm._s(_vm.detail.order_leave_date))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "项目名称:" } }, [
                _vm._v(_vm._s(_vm.detail.project_name))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "服务提供方:" } }, [
                _vm._v(_vm._s(_vm.detail.server_provider))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单号:" } }, [
                _vm._v(_vm._s(_vm.detail.order_id))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "部门:" } }, [
                _vm._v(_vm._s(_vm.detail.department))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "服务名称:" } }, [
                _vm._v(_vm._s(_vm.detail.server_name))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "子产品:" } }, [
                _vm._v(_vm._s(_vm.detail.son_product))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "局点:" } }, [
                _vm._v(_vm._s(_vm.detail.game_point))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "服务子类:" } }, [
                _vm._v(_vm._s(_vm.detail.service_type))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "服务人员数量:" } }, [
                _vm._v(_vm._s(_vm.detail.server_num))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "单价:" } }, [
                _vm._v(_vm._s(_vm.detail.price))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "初始订单金额(元):" } }, [
                _vm._v(_vm._s(_vm.detail.order_price_original))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "累计已出账金额(元):" } }, [
                _vm._v(_vm._s(_vm.detail.total_order_account))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "剩余可结算工时:" } }, [
                _vm._v(_vm._s(_vm.detail.last_order_hours))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-form-item", { attrs: { label: "服务内容:" } }, [
                _vm._v(_vm._s(_vm.detail.server_content))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "费用评估人:" } }, [
                _vm._v(_vm._s(_vm.detail.fee_pg_name))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "区技交付PM:" } }, [
                _vm._v(_vm._s(_vm.detail.region_pm))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "运维负责人:" } }, [
                _vm._v(_vm._s(_vm.detail.operate_pm))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "下单人:" } }, [
                _vm._v(_vm._s(_vm.detail.story_audit_owner))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "成本中心:" } }, [
                _vm._v(_vm._s(_vm.detail.obs_center))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "需求方运营:" } }, [
                _vm._v(_vm._s(_vm.detail.operator))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "工时系数:" } }, [
                _vm._v(_vm._s(_vm.detail.hour_ratio))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "行业FT项目PM:" } }, [
                _vm._v(_vm._s(_vm.detail.tencent_pm))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "二线支撑人员:" } }, [
                _vm._v(_vm._s(_vm.detail.suport_name))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "区技入场人员:" } }, [
                _vm._v(_vm._s(_vm.detail.region_enter_name))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "区域运维人员:" } }, [
                _vm._v(_vm._s(_vm.detail.operate_name))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "金额备注:" } }, [
                _vm._v(_vm._s(_vm.detail.price_remark))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "OBS规划产品:" } }, [
                _vm._v(_vm._s(_vm.detail.plan_product))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "需求方FTO:" } }, [
                _vm._v(_vm._s(_vm.detail.fto))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "梯度系数:" } }, [
                _vm._v(_vm._s(_vm.detail.gradient))
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "验收标准:" } }, [
                _vm._v(_vm._s(_vm.detail.check_standard))
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "collapse-btn" },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: _vm.collapse ? "el-icon-arrow-down" : "el-icon-arrow-up"
              },
              on: {
                click: function($event) {
                  _vm.collapse = !_vm.collapse
                }
              }
            },
            [_vm._v(_vm._s(_vm.collapse ? " 显示更多" : " 收回"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }