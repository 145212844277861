var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    { attrs: { value: _vm.value }, on: { input: _vm.handleInput } },
    [
      _c("el-option", { attrs: { label: "全部", value: "" } }),
      _vm._v(" "),
      _vm._l(_vm.groups, function(ref) {
        var id = ref.id
        var name = ref.name
        return _c("el-option", { key: id, attrs: { label: name, value: id } })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }