"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _defineProperties = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-properties"));

// import permissionDircetive from 'src/libs/directives/permission-directive.js';
// import oaDirective from 'src/libs/directives/oa-directive.js';
// import notOADirective from 'src/libs/directives/not-oa-directive.js';
var _default = {
  install: function install(Vue) {
    /* eslint-disable no-new */
    var vm = new Vue({
      data: {
        user: null
      }
    });
    (0, _defineProperties["default"])(Vue.prototype, {
      $user: {
        set: function set(user) {
          sessionStorage.setItem('staffname', user.user_id);
          vm.user = user;
        },
        get: function get() {
          return vm.user;
        }
      },
      $userId: {
        get: function get() {
          if (vm.user) {
            // 统一转字符串, 因为工单记录全是字符串
            return vm.user.is_oa ? vm.user.user_id : '' + vm.user.id;
          }

          return null;
        }
      },
      $companyId: {
        get: function get() {
          if (vm.user && vm.user.company) {
            return vm.user.company.id;
          }

          return null;
        }
      },
      $isOa: {
        get: function get() {
          if (!vm.user) {
            return true; // 默认是OA比较好
          }

          return vm.user.is_oa;
        }
      }
    }); // Vue.directive('oa', oaDirective);
    // Vue.directive('not-oa', notOADirective);
    // Vue.directive('has', permissionDircetive);
  }
};
exports["default"] = _default;