//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenMixin from 'src/modules/mixins/page-event/open.js'
export default {
  mixins: [OpenMixin],
  props: {
    // 模版下载地址
    templateUrl: {
      type: String,
      require: true
    },
    // 页面标题
    title: {
      type: String,
      default() {
        return ''
      }
    },
    // 日志集
    logs: {
      type: Array,
      default() {
        return []
      }
    },
    // 操作记录总条数
    total: {
      type: Number,
      default() {
        return 0
      }
    },
    // 一页显示数量
    limit: {
      type: Number,
      default() {
        return 15
      }
    },
    // 当前页
    pageNum: {
      type: Number,
      default() {
        return 1
      }
    },
    // 是否隐藏tapd链接
    hideTapd: {
      type: Boolean,
      default() {
        return false
      }
    },
    // 是否需要日期
    needDate: {
      type: Boolean,
      default() {
        return false
      }
    },
    // 日期类型
    dateType: {
      type: String,
      defailt() {
        return 'date'
      }
    }
  },
  computed: {
    params () {
      return {
        total: this.total,
        pageNum: this.pageNum,
        limit: this.limit
      }
    }
  },
  data () {
    return {
      ruleForm: {
        fileList: [],
        tapdUrl: '',
        remark: '',
        date: ''
      },
      rules: {
        fileList: [
          { required: true, message: '请上传文件', trigger: 'blur' }
        ],
        date: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleDownloadTemplate() {
      open(this.templateUrl)
    },
    handleViewDetail(row) {
      this.$alert(`<p style="white-space: pre-line">${row.db_dml_detail}</p>`, '详细信息', {
        dangerouslyUseHTMLString: true
      })
    },
    handleFileSuccess ({ code, message, data = {} }) {
      if (code !== 0) {
        this.$message(message || '上传失败, 请稍后再试!');
      }
      this.ruleForm.fileList[0] = data
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let values = { ...this.ruleForm }
          if (!this.needDate) {
            delete values.date
          }
          this.$emit('submit', values)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeSize(limit) {
      this.$emit('pageChange', {
        total: this.total,
        pageNum: this.pageNum,
        limit
      })
    },
    changPage(pageNum) {
      this.$emit('pageChange', {
        total: this.total,
        pageNum,
        limit: this.limit
      })
    }
  }
}
