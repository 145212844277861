"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _defineProperties = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-properties"));

var _default = {
  install: function install(Vue) {
    (0, _defineProperties["default"])(Vue.prototype, {
      $route: {
        get: function get() {
          var $route = window.sessionStorage.getItem('$route');
          return JSON.parse($route);
        }
      }
    });
  }
};
exports["default"] = _default;