"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getTreeKey = getTreeKey;
exports.existsInMyModules = existsInMyModules;
exports.initMyPermissions = initMyPermissions;
exports.getPostPermission = getPostPermission;

var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/values"));

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _some = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/some"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

function getTreeKey(module, series) {
  var _context;

  return (0, _concat["default"])(_context = "".concat(module, ".")).call(_context, series);
}
/**
 * 需保证权限在已分配给供应商的权限中找到
 */


function existsInMyModules(module, series, permission) {
  var modules = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  return (0, _some["default"])(modules).call(modules, function (_ref) {
    var code = _ref.code,
        children = _ref.children;

    if (code === module) {
      return (0, _some["default"])(children).call(children, function (_ref2) {
        var code = _ref2.code,
            children = _ref2.children;

        if (code === series) {
          return (0, _some["default"])(children).call(children, function (_ref3) {
            var code = _ref3.code;
            return code === permission;
          });
        }
      });
    }
  });
}

;
/**
 * 使用对象代替数组的形式，提高查找速度
 * 需保证权限在已分配给供应商的权限中找到
 * 保证所有series都初始化为数组，否则el-checkbox-group会修改为bool
 */

function initMyPermissions() {
  var myPermissions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var modules = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var myTreePermissions = (0, _reduce["default"])(modules).call(modules, function (myTreePermissions, _ref4) {
    var children = _ref4.children;
    (0, _reduce["default"])(children).call(children, function (permissions, _ref5) {
      var code = _ref5.code,
          module = _ref5.module;
      var key = getTreeKey(module, code);
      permissions[key] = [];
      return permissions;
    }, myTreePermissions);
    return myTreePermissions;
  }, {});
  (0, _reduce["default"])(myPermissions).call(myPermissions, function (myTreePermissions, mySeriesPermission) {
    var module = mySeriesPermission.module,
        series = mySeriesPermission.series,
        code = mySeriesPermission.code;
    var key = getTreeKey(module, series);

    if (myTreePermissions[key] && existsInMyModules(module, series, code, modules)) {
      myTreePermissions[key].push(mySeriesPermission);
    }

    return myTreePermissions;
  }, myTreePermissions);
  return myTreePermissions;
}
/**
 * 转换permission对象为提交所用的格式
 */


function getPostPermission(treePermissions) {
  var _context2;

  return (0, _reduce["default"])(_context2 = (0, _values["default"])(treePermissions)).call(_context2, function (myPermissions, myTreePermissions) {
    return (0, _concat["default"])(myPermissions).call(myPermissions, myTreePermissions);
  }, []);
}