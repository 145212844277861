"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _default = {
  methods: {
    filter: function filter(str) {
      // 特殊字符转义
      str += ''; // 隐式转换

      str = str.replace(/%/g, '%25');
      str = str.replace(/\+/g, '%2B');
      str = str.replace(/ /g, '%20');
      str = str.replace(/\//g, '%2F');
      str = str.replace(/\?/g, '%3F');
      str = str.replace(/&/g, '%26');
      str = str.replace(/=/g, '%3D');
      str = str.replace(/#/g, '%23');
      return str;
    },
    formateObjToParamStr: function formateObjToParamStr(paramObj) {
      var sdata = [];

      for (var attr in paramObj) {
        var _context, _context2;

        sdata.push((0, _concat["default"])(_context = "".concat(attr, "=")).call(_context, (0, _filter["default"])(_context2 = this).call(_context2, paramObj[attr])));
      }

      var urlParams = sdata.join('&');
      return urlParams ? '?' + urlParams : urlParams;
    }
  }
};
exports["default"] = _default;