"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.risk = exports.authMessage = exports.authorize = exports.ability = exports.status = exports.input = exports.authMethod = exports.accessMethod = exports.className = exports.action = exports.wait = exports.code = exports.httpMethod = exports.host = exports.serverIp = exports.clientIp = exports.traceback = exports.httpMessage = exports.errorMessage = exports.user = exports.url = exports.channel = exports.level = exports.time = exports.timeEnd = exports.timeStart = exports.feature = exports.id = void 0;

var _enums = require("./enums");

var _moment = _interopRequireDefault(require("moment"));

var id = {
  prop: 'id',
  label: '日志id'
};
exports.id = id;
var feature = {
  prop: 'feature',
  label: 'feature'
};
exports.feature = feature;
var timeStart = {
  prop: 'time_start',
  label: '开始时间'
};
exports.timeStart = timeStart;
var timeEnd = {
  prop: 'time_end',
  label: '结束时间'
};
exports.timeEnd = timeEnd;
var time = {
  prop: 'time',
  props: [timeStart.prop, timeEnd.prop],
  type: 'datetimerange',
  width: 200,
  defaultValue: [(0, _moment["default"])().startOf('day').format(), (0, _moment["default"])().endOf('day').format()],
  pickerOptions: {
    disabledDate: function disabledDate(time) {
      return time.getTime() > (0, _moment["default"])().valueOf();
    }
  },
  label: '操作时间'
};
exports.time = time;
var level = {
  prop: 'level',
  label: '日志等级',
  type: 'multiple-select',
  enums: _enums.logLevel,
  defaultValue: []
};
exports.level = level;
var channel = {
  prop: 'channel',
  label: '错误类型'
};
exports.channel = channel;
var url = {
  prop: 'url',
  width: 350,
  label: '请求地址'
};
exports.url = url;
var user = {
  prop: 'user',
  label: '操作用户',
  type: 'user'
};
exports.user = user;
var errorMessage = {
  prop: 'message',
  width: 550,
  label: '信息简介'
};
exports.errorMessage = errorMessage;
var httpMessage = {
  prop: 'message',
  isExpand: true,
  isHtml: true
};
exports.httpMessage = httpMessage;
var traceback = {
  prop: 'traceback',
  isExpand: true,
  isHtml: true
};
exports.traceback = traceback;
var clientIp = {
  prop: 'client_ip',
  label: '用户ip'
};
exports.clientIp = clientIp;
var serverIp = {
  prop: 'server_ip',
  label: '服务器ip'
};
exports.serverIp = serverIp;
var host = {
  prop: 'host',
  label: '主机地址'
};
exports.host = host;
var httpMethod = {
  prop: 'method',
  label: '请求类型'
};
exports.httpMethod = httpMethod;
var code = {
  prop: 'code',
  label: '状态码'
};
exports.code = code;
var wait = {
  prop: 'waiting',
  label: '耗时(ms)'
};
exports.wait = wait;
var action = {
  prop: 'action',
  label: '用户行为'
};
exports.action = action;
var className = {
  prop: 'class',
  label: '授权类名'
};
exports.className = className;
var accessMethod = {
  prop: 'method',
  label: '请求函数'
};
exports.accessMethod = accessMethod;
var authMethod = {
  prop: 'method',
  label: '授权函数名'
};
exports.authMethod = authMethod;
var input = {
  prop: 'input',
  label: '输入参数'
};
exports.input = input;
var status = {
  prop: 'status',
  label: '状态码'
};
exports.status = status;
var ability = {
  prop: 'ability',
  label: '授权动作名'
};
exports.ability = ability;
var authorize = {
  prop: 'authorize',
  label: '授权结果'
};
exports.authorize = authorize;
var authMessage = {
  prop: 'message',
  label: '授权消息'
};
exports.authMessage = authMessage;
var risk = {
  prop: 'risk',
  label: '风险等级'
};
exports.risk = risk;