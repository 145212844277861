var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticStyle: { "margin-left": "25px" } },
    _vm._l(_vm.permissions, function(ref) {
      var code = ref.code
      var name = ref.name
      return _c(
        "el-col",
        { key: code, attrs: { span: 6, gutter: 20 } },
        [
          _c("el-checkbox", { attrs: { label: _vm.getPermission(code) } }, [
            _vm._v(_vm._s(name))
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }