var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.close,
          expression: "close"
        }
      ],
      staticClass: "el-autocomplete",
      attrs: {
        "aria-haspopup": "listbox",
        role: "combobox",
        "aria-expanded": _vm.suggestionVisible,
        "aria-owns": _vm.id
      }
    },
    [
      _c("el-input", {
        ref: "input",
        attrs: {
          name: _vm.name,
          size: _vm.size,
          disabled: _vm.readonly || _vm.innerDisabled,
          value: _vm.innerValue,
          placeholder: _vm.placeholder,
          label: _vm.label
        },
        on: {
          input: _vm.handleChange,
          focus: _vm.handleFocus,
          blur: _vm.handleBlur
        },
        nativeOn: {
          compositionstart: function($event) {
            return _vm.handleComposition($event)
          },
          compositionupdate: function($event) {
            return _vm.handleComposition($event)
          },
          compositionend: function($event) {
            return _vm.handleComposition($event)
          },
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.highlight(_vm.highlightedIndex - 1)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.highlight(_vm.highlightedIndex + 1)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.handleKeyEnter($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
              ) {
                return null
              }
              return _vm.handleKeyEnter($event)
            }
          ]
        }
      }),
      _vm._v(" "),
      _c(
        "user-chooser-suggestions",
        {
          ref: "suggestions",
          class: [_vm.popperClass ? _vm.popperClass : ""],
          attrs: { "visible-arrow": "", placement: "bottom-start", id: _vm.id }
        },
        _vm._l(_vm.suggestions, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              class: { highlighted: _vm.highlightedIndex === index },
              attrs: {
                id: _vm.id + "-item-" + index,
                role: "option",
                "aria-selected": _vm.highlightedIndex === index
              },
              on: {
                click: function($event) {
                  return _vm.select(item)
                }
              }
            },
            [
              _vm._t(
                "default",
                [
                  _vm._v(
                    "\n                " + _vm._s(item[1]) + "\n            "
                  )
                ],
                { item: item }
              )
            ],
            2
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }