//
//
//
//
//
//
//

import { getAccountGroups } from '../../api';

export default {
  // 可通过inject传入全局唯一数据
  inject: ['globalGroups'],
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      groups: []
    }
  },
  methods: {
    handleInput (val) {
      this.$emit('input', val);
    },
    async fetchGroups () {
      const { data } = await getAccountGroups({
        query: {
          limit: -1
        }
      });
      if (data && data.code === 0 && data.data) {
        this.groups = data.data
      }
    }
  },
  created () {
    if (!this.globalGroups) {
      this.fetchGroups();
    } else {
      this.groups = this.globalGroups.state;
      this.$watch('globalGroups.state', (val) => {
        this.groups = val
      })
    }
  }
}
