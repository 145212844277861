"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
// 配合element-ui的v-loading进行局部加载，可以通过覆盖beforeLoading和afterLoading处理加载前后回调
var _default = {
  data: function data() {
    return {
      isLoading: false
    };
  },
  methods: {
    beforeLoading: function beforeLoading() {},
    afterLoading: function afterLoading() {},
    loading: function loading() {
      this.beforeLoading();
      this.isLoading = true;
    },
    loaded: function loaded() {
      this.afterLoading();
      this.isLoading = false;
    }
  }
};
exports["default"] = _default;