"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));

var _is = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/is"));

var _default2 = {
  props: {
    data: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      params: {}
    };
  },
  watch: {
    data: function data(_data) {
      // 需要有严格变化才会触发更新
      if (!(0, _is["default"])(this.params, _data)) {
        this.updateParams();
      }
    }
  },
  methods: {
    beforeSubmit: function beforeSubmit(params) {},
    submit: function submit(callback) {
      this.beforeSubmit(this.params);
      this.$emit('submit', {
        params: this.params,
        callback: callback
      });
    },
    beforeUpdateParams: function beforeUpdateParams(params) {},
    afterUpdateParams: function afterUpdateParams(params) {},
    updateParams: function updateParams() {
      var _context,
          _this = this;

      this.beforeUpdateParams(this.params); // 增加深拷贝，防止引用到内部属性

      var data = JSON.parse((0, _stringify["default"])(this.data));
      (0, _forEach["default"])(_context = (0, _keys["default"])(data)).call(_context, function (key) {
        return _this.$set(_this.params, key, data[key] === undefined ? _this.params[key] : data[key]);
      });
      this.afterUpdateParams(this.params);
    },
    resetParams: function resetParams() {
      this.beforeUpdateParams(this.params);
      var data = JSON.parse((0, _stringify["default"])(this.data));
      this.params = data;
      this.afterUpdateParams(data);
    }
  },
  mounted: function mounted() {
    this.updateParams();
  }
};
exports["default"] = _default2;