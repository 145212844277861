"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.group = exports.leader = exports.groupManager = exports.groupName = void 0;

var _userChooser = _interopRequireDefault(require("src/modules/components/user-chooser"));

var _groupsSelector = _interopRequireDefault(require("./components/groups-selector"));

var groupName = {
  prop: 'name',
  label: '工作岗位',
  required: true
};
/**
 * 后端配置leader为队列负责人
 */

exports.groupName = groupName;
var groupManager = {
  prop: 'leader',
  label: '负责人',
  editComponent: _userChooser["default"]
};
exports.groupManager = groupManager;
var leader = {
  prop: 'manager',
  label: 'leader',
  editComponent: _userChooser["default"]
};
exports.leader = leader;
var group = {
  prop: 'group',
  label: '工作岗位',
  editComponent: _groupsSelector["default"]
};
exports.group = group;