//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { queryUrl } from 'src/pages/workbench/api'

export default {
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    // 点击下载
    downloadField(url) {
      queryUrl({ params: { string: url } }).then(({ data }) => {
        if (data.code === 0) {
          if (data.data.url && data.data.url !== '') {
            window.open(data.data.url)
          }
        }
      })
    },
    // 校验是否为链接
    regExpFun(field) {
      let reg = /(http|ftp|https):\/\/([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+/
      return reg.test(field)
    },
    // checkbox匹配
    mateArray(option = [], itemValue = []) {
      let filterData = option.filter((el) => itemValue.includes(el.value))
      if (filterData.length > 0) {
        return filterData
          .map((el, idx) => {
            return `${idx}.${el.key}`
          })
          .join(';')
      }
      return ''
    },
    // 节点类型匹配
    mateOption(option = [], itemValue) {
      let filterData = option.filter((el) => String(el.value) === itemValue)
      if (filterData.length > 0) {
        return filterData[0].key
      }
      return ''
    },
    // 时间类型
    timeFormat(time) {
      return moment(time).isValid() ? moment(time).format('YYYY-MM-DD HH:mm:ss') : ''
    }
  }
}
