"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.createSession = createSession;
exports.renewSession = renewSession;
exports.getSessionId = getSessionId;

var _now = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/date/now"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _nanoid = require("nanoid");

/**
 * session管理
 * @author ddaleli
 */
// 判断是否过期 time 分钟
function hasExpired(time) {
  var sessionId = _jsCookie["default"].get('session_id_uv_pv');

  var lastRenewTime = _jsCookie["default"].get('last_renew_time');

  if (!sessionId || !lastRenewTime) {
    return true;
  }

  var diffTime = (0, _now["default"])() - Number(lastRenewTime);
  return diffTime > time * 60 * 1000;
} // 创建 session


function createSession() {
  renewSession();
} // 续期 session


function renewSession() {
  if (hasExpired(30)) {
    _jsCookie["default"].set('last_renew_time', String((0, _now["default"])()));

    _jsCookie["default"].set('session_id_uv_pv', (0, _nanoid.nanoid)(8));

    return;
  }

  _jsCookie["default"].set('last_renew_time', String((0, _now["default"])()));
} // 获取 session


function getSessionId() {
  return _jsCookie["default"].get('session_id_uv_pv');
}