"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: '1',
  name: '项目查询',
  opened: false,
  children: [{
    index: '1-1',
    // icon: 'el-icon-receiving',
    name: '任务列表',
    href: '/project/inquire',
    permission: 'DELIVER.PROJECT.SEE_LIST',
    description: ''
  }, {
    index: '1-2',
    // icon: 'el-icon-receiving',
    name: '评审列表',
    href: '/project/appraiselist',
    permission: 'DELIVER.PROJECT.SEE_LIST',
    description: ''
  }, {
    index: '1-3',
    // icon: 'el-icon-receiving',
    name: '项目列表',
    href: '/project/projectlist',
    permission: 'DELIVER.PROJECT.SEE_LIST',
    description: ''
  }]
}, {
  index: '39',
  // icon: 'el-icon-receiving',
  name: '基础维保数据',
  href: '/project/maintenance',
  permission: 'DELIVER.PROJECT.C2MATERIAL_VIEW',
  description: ''
}, {
  index: '8',
  // icon: 'el-icon-time',
  name: '工时列表',
  permission: '',
  description: '',
  children: [{
    index: '8-1',
    // icon: 'el-icon-receiving',
    name: '任务工时',
    href: '/project/man-hour-task',
    description: ''
  }, {
    index: '8-2',
    // icon: 'el-icon-receiving',
    name: '项目工时',
    href: '/project/man-hour-project',
    description: ''
  }, {
    index: '8-3',
    // icon: 'el-icon-receiving',
    name: '非项目工时',
    href: '/project/man-hour-non-project',
    description: ''
  }]
}, {
  index: '9',
  // icon: 'el-icon-tickets',
  name: '验收查询',
  href: '/project/check',
  permission: 'DELIVER.PROJECT.ACCEPTANCE.LIST',
  description: ''
}, {
  index: '10',
  // icon: 'el-icon-s-flag',
  name: '产品版本变更表',
  href: '/project/revison',
  permission: '',
  description: ''
}, {
  index: '11',
  // icon: 'el-icon-folder-opened',
  name: '变更操作日志',
  href: '/project/changelogs',
  permission: '',
  description: ''
}, {
  index: '12',
  // icon: 'el-icon-folder-opened',
  name: '客户项目信息',
  href: '/project/customer-info',
  permission: '',
  description: ''
}];
var _default = items;
exports["default"] = _default;