var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-collapse",
    {
      attrs: { accordion: _vm.accordion },
      model: {
        value: _vm.openNames,
        callback: function($$v) {
          _vm.openNames = $$v
        },
        expression: "openNames"
      }
    },
    _vm._l(_vm.data, function(item, index) {
      return _c(
        "el-collapse-item",
        { key: index, attrs: { name: item.id } },
        [
          _c(
            "template",
            { slot: "title" },
            [_c("title-bar", { attrs: { info: item } })],
            1
          ),
          _vm._v(" "),
          _c("content-table", { attrs: { data: item.fields } })
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }