"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var _default = {
  data: function data() {
    return {
      loading: false,
      dataSource: [],
      total: 0,
      page: 1,
      size: 10
    };
  }
};
exports["default"] = _default;