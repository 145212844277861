//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String
    },
    myPermissions: {
      type: Array,
      default () {
        return []
      }
    },
    permissions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      indeterminate: false
    }
  },
  /**
   * indeterminate表示已选但未全选，只需在长度>0且<总数为true即可
   */
  watch: {
    myPermissions (myPermissions) {
      this.handleIndeterminate(myPermissions);
    }
  },
  computed: {
    /**
     * 根据长度判断全选框的状态
     */
    isAllChecked () {
      const myLen = this.myPermissions && this.myPermissions.length;
      const len = this.permissions && this.permissions.length;
      return myLen === len;
    },
    /**
     * 全选时的权限所根据已分配的权限进行变化
     * 权限包括已经分配的权限(myPermissions) 和 未分配个人但分配供应商的权限(permissions);
     */
    allPermissions () {
      return this.permissions.map(permission => {
        return this.myPermissions.find(({ code }) => {
          return code === permission.code;
        }) || permission;
      });
    },
    emptyPermissions () {
      return [];
    }
  },
  methods: {
    change (value) {
      if (value) {
        this.$emit('select-all-true', this.allPermissions);
      } else {
        this.$emit('select-all-false', this.emptyPermissions);
      }
    },
    handleIndeterminate (myPermissions = this.myPermissions) {
      const myLen = myPermissions && myPermissions.length;
      const len = this.permissions && this.permissions.length;
      this.indeterminate = myLen > 0 && myLen < len;
    }
  },
  created () {
    this.handleIndeterminate();
  }
}
