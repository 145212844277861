//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { statusFigureClass, statusIconClass } from 'src/pages/project/mock'

export default {
  props: {
    info: Object
  },
  data() {
    return {
      statusFigureClass: statusFigureClass,
      statusIconClass: statusIconClass
    }
  },
  methods: {
    // 根据人名长度切割
    SplitStr(str) {
      if (str) {
        let strArr = str.split(';')
        if (strArr.length > 3) {
          let arr = strArr.filter((el, idx) => {
            return idx <= 2
          })
          return arr.join(';') + '...'
        }
        return strArr.join(';')
      }
      return ''
    }
  }
}
