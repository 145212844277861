var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-button",
        {
          class: _vm.btnClass,
          style: _vm.btnStyle,
          attrs: {
            type: _vm.btnType,
            icon: _vm.icon,
            size: _vm.btnSize,
            title: _vm.btnTitle
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.open($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.btnText))]
      ),
      _vm._v(" "),
      _vm.isRenderDialog
        ? [
            _c(
              "el-dialog",
              _vm._b(
                {
                  ref: "dialog",
                  staticClass: "edit-dialog",
                  attrs: {
                    width: _vm.width,
                    visible: _vm.isShow,
                    "append-to-body": true,
                    title: _vm.title
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.isShow = $event
                    },
                    open: _vm.opened,
                    close: _vm.close
                  }
                },
                "el-dialog",
                _vm.$attrs,
                false
              ),
              [
                _c("water-mark-component", [_vm._t("default")], 2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    attrs: { slot: "footer", flex: "main:right" },
                    slot: "footer"
                  },
                  [
                    _vm._t("extra-button"),
                    _vm._v(" "),
                    _vm.isSubmit
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              disabled: _vm.isValid,
                              effect: "dark",
                              content: _vm.tips,
                              placement: "top-start"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: !_vm.isValid,
                                  type: "primary"
                                },
                                on: { click: _vm.submit }
                              },
                              [_vm._v(_vm._s(_vm.submitText))]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-button", { on: { click: _vm.closeDialog } }, [
                      _vm._v("关闭")
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }