"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));

var _default = {
  filters: {
    mapListData: function mapListData(data) {
      var text = (0, _isArray["default"])(data) ? data.join(',') : data;
      return text || '--';
    }
  },
  methods: {
    showListData: function showListData(data) {
      var defaults = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '--';
      var text = (0, _isArray["default"])(data) ? data.join(',') : data;
      return text || defaults;
    }
  }
};
exports["default"] = _default;