"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _reduce = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reduce"));

var _context;

var _default = {
  methods: (0, _reduce["default"])(_context = ['clearSelection', 'toggleRowSelection', 'toggleAllSelection', 'toggleRowExpansion', 'setCurrentRow', 'clearSort', 'clearFilter', 'doLayout', 'sort']).call(_context, function (methods, methodName) {
    methods[methodName] = function () {
      var table = this.$refs.main.$refs.table;

      if (table && table[methodName]) {
        table[methodName].apply(table, arguments);
      }
    };

    return methods;
  }, {})
};
exports["default"] = _default;