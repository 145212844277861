//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { planType, isGoBack, trafficType, planStatus } from './filter'
export default {
  props: {
    orderInfo: Object,
    pageWhere: String
  },
  filters: {
    planType: function (val) {
      return planType(val)
    },
    isGoBack: function (val) {
      return isGoBack(val)
    },
    trafficType: function (val) {
      return trafficType(val)
    },
    planStatus: function (val) {
      return planStatus(val)
    }
  },
  methods: {
    //  根据计划类型判断详情信息是否显示
    isShowRules (sine) {
      /**
       *sine 作为出发目的城市标识
       */
      if (this.orderInfo.type !== '' && this.orderInfo.type !== 'transportation') {
        if (this.orderInfo.type !== 'subsidy') {
          return false
        } else {
          if (sine) return true
          return false
        }
      }
      return true
    }
  }
}
