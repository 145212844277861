"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.deleteAccountStatus = exports.getAccountStatus = exports.postTicketSchedulesImport = exports.postTicketSchedulesPreImport = exports.postTicketSchedules = exports.getTicketSchedulesUsers = exports.getTicketSchedules = exports.deleteAccountWorks = exports.putAccountWorks = exports.postAccountWorks = exports.getAccountWorks = exports.getDuties = exports.postAccountsAllocateUin = exports.deleteAccountGroups = exports.putAccountGroups = exports.postAccountGroups = exports.getAccountGroups = exports.postAccountsPassword = exports.deleteAccounts = exports.putAccounts = exports.postAccounts = exports.getAccount = exports.getAccounts = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context; _forEachInstanceProperty(_context = ownKeys(Object(source), true)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

// let url = 'http://permission.ctss.oa.com';
var url = '';

var getAccounts = function getAccounts() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/accounts'
  }, config));
};

exports.getAccounts = getAccounts;

var getAccount = function getAccount() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$params = _ref2.params,
      params = _ref2$params === void 0 ? {} : _ref2$params,
      _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/accounts/{id}'
  }, config));
};

exports.getAccount = getAccount;

var postAccounts = function postAccounts() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$params = _ref3.params,
      params = _ref3$params === void 0 ? {} : _ref3$params,
      _ref3$data = _ref3.data,
      data = _ref3$data === void 0 ? {} : _ref3$data,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/accounts'
  }, config));
};

exports.postAccounts = postAccounts;

var putAccounts = function putAccounts() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$params = _ref4.params,
      params = _ref4$params === void 0 ? {} : _ref4$params,
      _ref4$data = _ref4.data,
      data = _ref4$data === void 0 ? {} : _ref4$data,
      _ref4$config = _ref4.config,
      config = _ref4$config === void 0 ? {
    loading: false
  } : _ref4$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/accounts/{id}'
  }, config));
};

exports.putAccounts = putAccounts;

var deleteAccounts = function deleteAccounts() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$params = _ref5.params,
      params = _ref5$params === void 0 ? {} : _ref5$params,
      _ref5$data = _ref5.data,
      data = _ref5$data === void 0 ? {} : _ref5$data,
      _ref5$config = _ref5.config,
      config = _ref5$config === void 0 ? {
    loading: false
  } : _ref5$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'delete',
    url: url + '/admin/api/accounts/{id}'
  }, config));
};

exports.deleteAccounts = deleteAccounts;

var postAccountsPassword = function postAccountsPassword() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$params = _ref6.params,
      params = _ref6$params === void 0 ? {} : _ref6$params,
      _ref6$data = _ref6.data,
      data = _ref6$data === void 0 ? {} : _ref6$data,
      _ref6$config = _ref6.config,
      config = _ref6$config === void 0 ? {
    loading: false
  } : _ref6$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/accounts/{id}/password'
  }, config));
};

exports.postAccountsPassword = postAccountsPassword;

var getAccountGroups = function getAccountGroups() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$params = _ref7.params,
      params = _ref7$params === void 0 ? {} : _ref7$params,
      _ref7$query = _ref7.query,
      query = _ref7$query === void 0 ? {} : _ref7$query,
      _ref7$config = _ref7.config,
      config = _ref7$config === void 0 ? {
    loading: false
  } : _ref7$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/account-groups'
  }, config));
};

exports.getAccountGroups = getAccountGroups;

var postAccountGroups = function postAccountGroups() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref8$params = _ref8.params,
      params = _ref8$params === void 0 ? {} : _ref8$params,
      _ref8$data = _ref8.data,
      data = _ref8$data === void 0 ? {} : _ref8$data,
      _ref8$config = _ref8.config,
      config = _ref8$config === void 0 ? {
    loading: false
  } : _ref8$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/account-groups'
  }, config));
};

exports.postAccountGroups = postAccountGroups;

var putAccountGroups = function putAccountGroups() {
  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref9$params = _ref9.params,
      params = _ref9$params === void 0 ? {} : _ref9$params,
      _ref9$data = _ref9.data,
      data = _ref9$data === void 0 ? {} : _ref9$data,
      _ref9$config = _ref9.config,
      config = _ref9$config === void 0 ? {
    loading: false
  } : _ref9$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: "/admin/api/account-groups/".concat(params.id)
  }, config));
};

exports.putAccountGroups = putAccountGroups;

var deleteAccountGroups = function deleteAccountGroups() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref10$params = _ref10.params,
      params = _ref10$params === void 0 ? {} : _ref10$params,
      _ref10$data = _ref10.data,
      data = _ref10$data === void 0 ? {} : _ref10$data,
      _ref10$config = _ref10.config,
      config = _ref10$config === void 0 ? {
    loading: false
  } : _ref10$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'delete',
    url: "/admin/api/account-groups/".concat(params.id)
  }, config));
};

exports.deleteAccountGroups = deleteAccountGroups;

var postAccountsAllocateUin = function postAccountsAllocateUin() {
  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref11$params = _ref11.params,
      params = _ref11$params === void 0 ? {} : _ref11$params,
      _ref11$data = _ref11.data,
      data = _ref11$data === void 0 ? {} : _ref11$data,
      _ref11$config = _ref11.config,
      config = _ref11$config === void 0 ? {
    loading: false
  } : _ref11$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/accounts/allocate/uin/{id}'
  }, config));
};

exports.postAccountsAllocateUin = postAccountsAllocateUin;

var getDuties = function getDuties() {
  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref12$params = _ref12.params,
      params = _ref12$params === void 0 ? {} : _ref12$params,
      _ref12$query = _ref12.query,
      query = _ref12$query === void 0 ? {} : _ref12$query,
      _ref12$config = _ref12.config,
      config = _ref12$config === void 0 ? {
    loading: false
  } : _ref12$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/duties'
  }, config));
};

exports.getDuties = getDuties;

var getAccountWorks = function getAccountWorks() {
  var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref13$params = _ref13.params,
      params = _ref13$params === void 0 ? {} : _ref13$params,
      _ref13$query = _ref13.query,
      query = _ref13$query === void 0 ? {} : _ref13$query,
      _ref13$config = _ref13.config,
      config = _ref13$config === void 0 ? {
    loading: false
  } : _ref13$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/account-works'
  }, config));
};

exports.getAccountWorks = getAccountWorks;

var postAccountWorks = function postAccountWorks() {
  var _ref14 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref14$params = _ref14.params,
      params = _ref14$params === void 0 ? {} : _ref14$params,
      _ref14$data = _ref14.data,
      data = _ref14$data === void 0 ? {} : _ref14$data,
      _ref14$config = _ref14.config,
      config = _ref14$config === void 0 ? {
    loading: false
  } : _ref14$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/account-works'
  }, config));
};

exports.postAccountWorks = postAccountWorks;

var putAccountWorks = function putAccountWorks() {
  var _ref15 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref15$params = _ref15.params,
      params = _ref15$params === void 0 ? {} : _ref15$params,
      _ref15$data = _ref15.data,
      data = _ref15$data === void 0 ? {} : _ref15$data,
      _ref15$config = _ref15.config,
      config = _ref15$config === void 0 ? {
    loading: false
  } : _ref15$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: "/admin/api/account-works/".concat(params.id)
  }, config));
};

exports.putAccountWorks = putAccountWorks;

var deleteAccountWorks = function deleteAccountWorks() {
  var _ref16 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref16$params = _ref16.params,
      params = _ref16$params === void 0 ? {} : _ref16$params,
      _ref16$data = _ref16.data,
      data = _ref16$data === void 0 ? {} : _ref16$data,
      _ref16$config = _ref16.config,
      config = _ref16$config === void 0 ? {
    loading: false
  } : _ref16$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'delete',
    url: "/admin/api/account-works/".concat(params.id)
  }, config));
};

exports.deleteAccountWorks = deleteAccountWorks;

var getTicketSchedules = function getTicketSchedules() {
  var _ref17 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref17$params = _ref17.params,
      params = _ref17$params === void 0 ? {} : _ref17$params,
      _ref17$query = _ref17.query,
      query = _ref17$query === void 0 ? {} : _ref17$query,
      _ref17$config = _ref17.config,
      config = _ref17$config === void 0 ? {
    loading: false
  } : _ref17$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/ticket/schedules'
  }, config));
};

exports.getTicketSchedules = getTicketSchedules;

var getTicketSchedulesUsers = function getTicketSchedulesUsers() {
  var _ref18 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref18$params = _ref18.params,
      params = _ref18$params === void 0 ? {} : _ref18$params,
      _ref18$query = _ref18.query,
      query = _ref18$query === void 0 ? {} : _ref18$query,
      _ref18$config = _ref18.config,
      config = _ref18$config === void 0 ? {
    loading: false
  } : _ref18$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/ticket/schedules/users'
  }, config));
};

exports.getTicketSchedulesUsers = getTicketSchedulesUsers;

var postTicketSchedules = function postTicketSchedules() {
  var _ref19 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref19$params = _ref19.params,
      params = _ref19$params === void 0 ? {} : _ref19$params,
      _ref19$data = _ref19.data,
      data = _ref19$data === void 0 ? {} : _ref19$data,
      _ref19$config = _ref19.config,
      config = _ref19$config === void 0 ? {
    loading: false
  } : _ref19$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/ticket/schedules/{date}/{user}'
  }, config));
};

exports.postTicketSchedules = postTicketSchedules;

var postTicketSchedulesPreImport = function postTicketSchedulesPreImport() {
  var _ref20 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref20$params = _ref20.params,
      params = _ref20$params === void 0 ? {} : _ref20$params,
      _ref20$data = _ref20.data,
      data = _ref20$data === void 0 ? {} : _ref20$data,
      _ref20$config = _ref20.config,
      config = _ref20$config === void 0 ? {
    loading: false
  } : _ref20$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/ticket/schedules/pre-import'
  }, config));
};

exports.postTicketSchedulesPreImport = postTicketSchedulesPreImport;

var postTicketSchedulesImport = function postTicketSchedulesImport() {
  var _ref21 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref21$params = _ref21.params,
      params = _ref21$params === void 0 ? {} : _ref21$params,
      _ref21$data = _ref21.data,
      data = _ref21$data === void 0 ? {} : _ref21$data,
      _ref21$config = _ref21.config,
      config = _ref21$config === void 0 ? {
    loading: false
  } : _ref21$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'post',
    url: url + '/admin/api/ticket/schedules/import'
  }, config));
};

exports.postTicketSchedulesImport = postTicketSchedulesImport;

var getAccountStatus = function getAccountStatus() {
  var _ref22 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref22$params = _ref22.params,
      params = _ref22$params === void 0 ? {} : _ref22$params,
      _ref22$query = _ref22.query,
      query = _ref22$query === void 0 ? {} : _ref22$query,
      _ref22$config = _ref22.config,
      config = _ref22$config === void 0 ? {
    loading: false
  } : _ref22$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/admin/api/account-status'
  }, config));
};

exports.getAccountStatus = getAccountStatus;

var deleteAccountStatus = function deleteAccountStatus() {
  var _ref23 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref23$params = _ref23.params,
      params = _ref23$params === void 0 ? {} : _ref23$params,
      _ref23$data = _ref23.data,
      data = _ref23$data === void 0 ? {} : _ref23$data,
      _ref23$config = _ref23.config,
      config = _ref23$config === void 0 ? {
    loading: false
  } : _ref23$config;

  return _vue["default"].ajax.request(_objectSpread({
    data: data,
    method: 'delete',
    url: "/admin/api/account-status/".concat(params.id)
  }, config));
};

exports.deleteAccountStatus = deleteAccountStatus;