var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-component",
    {
      ref: "form",
      attrs: { inline: true, "is-query": true, configs: _vm.configs }
    },
    [
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("搜 索")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-refresh" },
              on: { click: _vm.resetParams }
            },
            [_vm._v("重 置")]
          ),
          _vm._v(" "),
          _vm._t("extra-buttons")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }