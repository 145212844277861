//
//
//
//
//
//
//
//
//
//
//
//
//
//

const isEmpty = (val) => {
  val = typeof val === 'string' ? val.replace(/^\s+|\s+$/g, '') : val;
  return val !== 0 && !val;
};

const SEPARATOR = ';';

export default {
  props: {
    isInputName: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default () {
        return '';
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false;
      }
    },
    singleton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      innerValue: '',
      queryString: '',
      edited: false
    };
  },
  watch: {
    value (val) {
      if (!val) {
        this.queryString = '';
      }
      this.innerValue = val;
    }
  },
  created () {
    this.resetInnerValue();
  },
  methods: {
    handleBlur (e) {
      if (this.edited) {
        this.queryString = ''
        this.$emit('input', '')
      }
    },
    handleInput (val) {
      if (!this.edited) {
        this.edited = true
      }
      if (val === '') {
        this.$emit('input', '')
      }
    },
    handleSelect (val) {
      // TODO:多选时的翻译问题
      const results = this.singleton ? [] : this.innerValue.split(SEPARATOR);
      // 只显示用户名
      this.queryString = [...results, val.user_name];
      this.queryString = this.queryString.filter(result => result).join(SEPARATOR);
      if (this.isInputName) {
        results.push(val.user_name);
      } else {
        results.push(val.user_id);
      }
      // 排除空数据
      const result = results.filter(result => result).join(SEPARATOR);
      this.$emit('input', result)
      this.edited = false
    },
    async resetInnerValue () {
      this.innerValue = this.value;
      this.queryString = this.value;
    },
    async queryUser (queryString, cb) {
      if (!this.singleton) {
        const queries = queryString.split(SEPARATOR);
        queryString = queries.pop();
        this.innerValue = queries.join(SEPARATOR);
      }
      const defaultRes = [];
      if (isEmpty(queryString)) {
        return cb(defaultRes);
      }
      try {
        const { data } = await this.requestSearchUser(queryString);
        if (data && data.code === 0) {
          cb(data.data);
        } else {
          cb(defaultRes);
        }
      } catch (error) {
      }
    },
    requestSearchUser (queryString) {
      return this.$rest.get('/ticket/api/tickets/accounts', {
        params: {
          name: queryString && queryString.trim()
        },
        handleError: true
      });
    }
  }
}
