"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _ompServiceSdk = require("@tencent/omp-service-sdk");

var _nanoid = require("nanoid");

function reportReturnCode(response) {
  if (location.host !== 'redcs.oa.com' || location.host === 'redcs.tencent.com' || response.status === 404) {
    return;
  }

  var pathArr = response.config.url.split('/');
  var requestModule = '';

  if (pathArr.length === 2) {
    requestModule = pathArr[1];
  } else {
    requestModule = pathArr[2];
  }

  var staffname = sessionStorage.getItem('staffname') || {};
  var reportData = {
    system: 'tiantu',
    // 必填，用做分系统统计
    env: 'prod',
    reportData: {
      'seq_id': response.config.headers.seqid,
      // seqid
      'staffname': staffname || '',
      // 用户名
      'request_url': response.request.responseURL,
      // 请求url
      'request_module': requestModule,
      // 请求模块
      'api_name': response.config.url,
      // 接口名称
      'track_time': response.config.requestTime,
      // 触发时间
      'timings': new Date().getTime() - response.config.requestTime,
      // 接口耗时
      'status': response.status,
      // HTTP 状态
      'status_text': response.statusText,
      // HTTP 报错信息
      'code': response.data.code,
      // 接口返回码
      'msg': response.data.message || '' // 接口报错信息

    }
  };
  (0, _ompServiceSdk.webRequestReport)(reportData);
}

var _default = {
  requestResolve: function requestResolve() {
    var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    config.headers.seqid = "tiantu-fe-".concat((0, _nanoid.nanoid)());
    return config;
  },
  responseResolve: function responseResolve(response) {
    reportReturnCode(response);
    return response;
  },
  responseReject: function responseReject(error) {
    reportReturnCode(error.response);
    return _promise["default"].reject(error);
  }
};
exports["default"] = _default;