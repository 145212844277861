"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: '7',
  icon: 'el-icon-tickets',
  name: '交付计划',
  href: '/order/delivery/plan',
  permission: 'DELIVER.PROJECT.SEE_LIST'
}];
var _default = items;
exports["default"] = _default;