"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var items = [{
  index: 'human-1',
  // icon: 'el-icon-map-location',
  name: '人力地图',
  href: '/human/map',
  permission: 'DELIVER.HUMAN.SEE_MAP'
}, {
  index: '2',
  name: '人力需求',
  opened: false,
  children: [{
    index: 'human-2',
    // icon: 'el-icon-s-finance',
    name: '人力采购需求',
    href: '/human/need',
    permission: ''
  }, {
    index: 'human-5',
    // icon: 'el-icon-s-finance',
    name: '人力招聘需求',
    href: '/human/recruit',
    permission: ''
  }]
}, {
  index: 'human-3',
  // icon: 'el-icon-s-custom',
  name: '人力信息',
  href: '/human/information',
  permission: 'DELIVER.HUMAN.SEE_LIST'
}, {
  index: 'human-4',
  // icon: 'el-icon-s-finance',
  name: 'POC标签',
  href: '/human/poc',
  permission: 'DELIVER.HUMAN.POC_APPROVAL'
}, {
  index: '10',
  // icon: 'el-icon-user-solid',
  name: '组织架构',
  href: '/react/human/employee',
  permission: ''
}];
var _default = items;
exports["default"] = _default;