var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-aside",
    { attrs: { width: _vm.asideWidth, flex: "dir:top" } },
    [
      _c("i", {
        staticClass: "switch-btn",
        class: _vm.switchBtnIcon,
        style: _vm.switchBtnLeftDistance,
        on: { click: _vm.changeVisible }
      }),
      _vm._v(" "),
      _vm.visible
        ? [
            _vm.title
              ? _c("h5", { staticClass: "aside-header", attrs: { once: "" } }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-menu",
              {
                staticClass: "page-sidebar",
                attrs: {
                  "collapse-transition": false,
                  "default-active": _vm.active,
                  "default-openeds": _vm.opened,
                  "flex-box": "1"
                },
                on: { select: _vm.handleSelectMenuItem }
              },
              [
                _vm._l(_vm.items, function(ref) {
                  var index = ref.index
                  var icon = ref.icon
                  var name = ref.name
                  var children = ref.children
                  return [
                    _vm.hasChildrenItem(children)
                      ? _c(
                          "el-submenu",
                          { key: index, attrs: { index: index } },
                          [
                            _c("template", { slot: "title" }, [
                              icon ? _c("i", { class: icon }) : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(name))]
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(children, function(ref) {
                              var cIndex = ref.index
                              var cIcon = ref.icon
                              var cName = ref.name
                              return _c(
                                "el-menu-item",
                                { key: cIndex, attrs: { index: cIndex } },
                                [
                                  cIcon ? _c("i", { class: cIcon }) : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(cName))]
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _c(
                          "el-menu-item",
                          { key: index, attrs: { index: index } },
                          [
                            _c("i", { class: icon }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(name))]
                            )
                          ]
                        )
                  ]
                })
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }