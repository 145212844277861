//
//
//
//
//
//
//
//
//
//
//

import { getTreeKey } from '../../utils/operation-permissions';

export default {
  inheritAttrs: false,
  props: {
    module: {
      type: String,
      required: true
    },
    serieses: {
      type: Array,
      default () {
        return []
      }
    },
    myTreePermissions: {
      type: Object,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
      activeCollapse: []
    }
  },
  methods: {
    initActiveCollapse () {
      this.serieses.forEach(({ code }) => {
        this.activeCollapse.push(code);
      })
    },
    getMyPermissions (module, mySeries) {
      const key = getTreeKey(module, mySeries);
      return this.myTreePermissions[key];
    }
  },
  created () {
    this.initActiveCollapse();
  }
}
