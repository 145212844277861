//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RedirectMixin from 'src/modules/mixins/page-event/redirect';
import UserPermissionMixin from 'src/modules/mixins/user-permission';

export default {
  mixins: [RedirectMixin, UserPermissionMixin],
  props: {
    permission: String,
    name: String,
    description: String,
    icon: String,
    href: String
  }
}
