"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");

var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");

var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");

var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.actionInfos = exports.queryInfos = exports.getOrderListE = exports.downloadEExcel = exports.uploadUrl = exports.getCheckOrderInfo = exports.orderChangeSubmit = exports.abandonOrderChange = exports.orderChangeLogDetail = exports.orderChangeLogList = exports.orderChangeReason = exports.orderChangeRecall = exports.orderChangeDelete = exports.orderChangeReject = exports.orderChangePass = exports.orderChangeApprovalList = exports.orderChangeDetail = exports.getOrderBCDInfo = exports.orderChangeList = exports.getOrderPrice = exports.startOrderChange = exports.downloadBCDExcel = exports.downloadAExcel = exports.getObslist = exports.getTypelist = exports.getOrderListBCD = exports.getOrderListA = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));

var _vue = _interopRequireDefault(require("src/libs/vue"));

function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); if (enumerableOnly) symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { var _context; _forEachInstanceProperty(_context = ownKeys(Object(source), true)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (_Object$getOwnPropertyDescriptors) { _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)); } else { var _context2; _forEachInstanceProperty(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } } return target; }

var url = ''; // 获取A类订单

var getOrderListA = function getOrderListA() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$query = _ref.query,
      query = _ref$query === void 0 ? {} : _ref$query,
      _ref$config = _ref.config,
      config = _ref$config === void 0 ? {
    loading: false
  } : _ref$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/order/order_list?type=A'
  }, config));
}; // 获取BCD类订单


exports.getOrderListA = getOrderListA;

var getOrderListBCD = function getOrderListBCD() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$params = _ref2.params,
      params = _ref2$params === void 0 ? {} : _ref2$params,
      _ref2$query = _ref2.query,
      query = _ref2$query === void 0 ? {} : _ref2$query,
      _ref2$config = _ref2.config,
      config = _ref2$config === void 0 ? {
    loading: false
  } : _ref2$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/order/order_list?type=BCD'
  }, config));
}; // 获取服务名称下拉数据 A类传type=A BCD类不传


exports.getOrderListBCD = getOrderListBCD;

var getTypelist = function getTypelist() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$params = _ref3.params,
      params = _ref3$params === void 0 ? {} : _ref3$params,
      _ref3$query = _ref3.query,
      query = _ref3$query === void 0 ? {} : _ref3$query,
      _ref3$config = _ref3.config,
      config = _ref3$config === void 0 ? {
    loading: false
  } : _ref3$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/order/service_type_list'
  }, config));
}; // BCD 获取成本中心下拉


exports.getTypelist = getTypelist;

var getObslist = function getObslist() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$params = _ref4.params,
      params = _ref4$params === void 0 ? {} : _ref4$params,
      _ref4$query = _ref4.query,
      query = _ref4$query === void 0 ? {} : _ref4$query,
      _ref4$config = _ref4.config,
      config = _ref4$config === void 0 ? {
    loading: false
  } : _ref4$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/order/obs_center_list'
  }, config));
}; // 导出A表格


exports.getObslist = getObslist;

var downloadAExcel = function downloadAExcel() {
  var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref5$params = _ref5.params,
      params = _ref5$params === void 0 ? {} : _ref5$params,
      _ref5$query = _ref5.query,
      query = _ref5$query === void 0 ? {} : _ref5$query,
      _ref5$config = _ref5.config,
      config = _ref5$config === void 0 ? {
    loading: false
  } : _ref5$config;

  return url + '/deliver/order/download_order';
}; // 导出BCD表格


exports.downloadAExcel = downloadAExcel;

var downloadBCDExcel = function downloadBCDExcel() {
  var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref6$params = _ref6.params,
      params = _ref6$params === void 0 ? {} : _ref6$params,
      _ref6$query = _ref6.query,
      query = _ref6$query === void 0 ? {} : _ref6$query,
      _ref6$config = _ref6.config,
      config = _ref6$config === void 0 ? {
    loading: false
  } : _ref6$config;

  return url + '/deliver/order/download_order';
};
/**
 * ######################################################################
 * 1、3、4、5、6、7、8、9、10、11、12、13、14、15
 * 2
 * ######################################################################
 */
// 1、发起订单变更字段|修改


exports.downloadBCDExcel = downloadBCDExcel;

var startOrderChange = function startOrderChange() {
  var _ref7 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref7$params = _ref7.params,
      params = _ref7$params === void 0 ? {} : _ref7$params,
      _ref7$data = _ref7.data,
      data = _ref7$data === void 0 ? {} : _ref7$data,
      _ref7$query = _ref7.query,
      query = _ref7$query === void 0 ? {} : _ref7$query,
      _ref7$config = _ref7.config,
      config = _ref7$config === void 0 ? {
    loading: false
  } : _ref7$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/tiantu/startOrderChange'
  }, config));
}; // 2、获取订单金额信息


exports.startOrderChange = startOrderChange;

var getOrderPrice = function getOrderPrice() {
  var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref8$params = _ref8.params,
      params = _ref8$params === void 0 ? {} : _ref8$params,
      _ref8$data = _ref8.data,
      data = _ref8$data === void 0 ? {} : _ref8$data,
      _ref8$query = _ref8.query,
      query = _ref8$query === void 0 ? {} : _ref8$query,
      _ref8$config = _ref8.config,
      config = _ref8$config === void 0 ? {
    loading: false
  } : _ref8$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/getOrderPrice'
  }, config));
}; // 3、我的变更列表


exports.getOrderPrice = getOrderPrice;

var orderChangeList = function orderChangeList() {
  var _ref9 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref9$params = _ref9.params,
      params = _ref9$params === void 0 ? {} : _ref9$params,
      _ref9$data = _ref9.data,
      data = _ref9$data === void 0 ? {} : _ref9$data,
      _ref9$query = _ref9.query,
      query = _ref9$query === void 0 ? {} : _ref9$query,
      _ref9$config = _ref9.config,
      config = _ref9$config === void 0 ? {
    loading: false
  } : _ref9$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/orderChangeList'
  }, config));
}; // 4、订单详情


exports.orderChangeList = orderChangeList;

var getOrderBCDInfo = function getOrderBCDInfo() {
  var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref10$params = _ref10.params,
      params = _ref10$params === void 0 ? {} : _ref10$params,
      _ref10$query = _ref10.query,
      query = _ref10$query === void 0 ? {} : _ref10$query,
      _ref10$config = _ref10.config,
      config = _ref10$config === void 0 ? {
    loading: false
  } : _ref10$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/getOrderBCDInfo'
  }, config));
}; // 5、订单变更详情


exports.getOrderBCDInfo = getOrderBCDInfo;

var orderChangeDetail = function orderChangeDetail() {
  var _ref11 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref11$params = _ref11.params,
      params = _ref11$params === void 0 ? {} : _ref11$params,
      _ref11$data = _ref11.data,
      data = _ref11$data === void 0 ? {} : _ref11$data,
      _ref11$query = _ref11.query,
      query = _ref11$query === void 0 ? {} : _ref11$query,
      _ref11$config = _ref11.config,
      config = _ref11$config === void 0 ? {
    loading: false
  } : _ref11$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'get',
    url: url + '/deliver/tiantu/orderChangeDetail'
  }, config));
}; // 6、审批列表


exports.orderChangeDetail = orderChangeDetail;

var orderChangeApprovalList = function orderChangeApprovalList() {
  var _ref12 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref12$params = _ref12.params,
      params = _ref12$params === void 0 ? {} : _ref12$params,
      _ref12$data = _ref12.data,
      data = _ref12$data === void 0 ? {} : _ref12$data,
      _ref12$query = _ref12.query,
      query = _ref12$query === void 0 ? {} : _ref12$query,
      _ref12$config = _ref12.config,
      config = _ref12$config === void 0 ? {
    loading: false
  } : _ref12$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/orderChangeApprovalList'
  }, config));
}; // 7、通过接口


exports.orderChangeApprovalList = orderChangeApprovalList;

var orderChangePass = function orderChangePass() {
  var _ref13 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref13$params = _ref13.params,
      params = _ref13$params === void 0 ? {} : _ref13$params,
      _ref13$data = _ref13.data,
      data = _ref13$data === void 0 ? {} : _ref13$data,
      _ref13$query = _ref13.query,
      query = _ref13$query === void 0 ? {} : _ref13$query,
      _ref13$config = _ref13.config,
      config = _ref13$config === void 0 ? {
    loading: false
  } : _ref13$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/tiantu/orderChangePass'
  }, config));
}; // 8、驳回接口


exports.orderChangePass = orderChangePass;

var orderChangeReject = function orderChangeReject() {
  var _ref14 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref14$params = _ref14.params,
      params = _ref14$params === void 0 ? {} : _ref14$params,
      _ref14$data = _ref14.data,
      data = _ref14$data === void 0 ? {} : _ref14$data,
      _ref14$query = _ref14.query,
      query = _ref14$query === void 0 ? {} : _ref14$query,
      _ref14$config = _ref14.config,
      config = _ref14$config === void 0 ? {
    loading: false
  } : _ref14$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/tiantu/orderChangeReject'
  }, config));
}; // 9、删除订单变更


exports.orderChangeReject = orderChangeReject;

var orderChangeDelete = function orderChangeDelete() {
  var _ref15 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref15$params = _ref15.params,
      params = _ref15$params === void 0 ? {} : _ref15$params,
      _ref15$data = _ref15.data,
      data = _ref15$data === void 0 ? {} : _ref15$data,
      _ref15$query = _ref15.query,
      query = _ref15$query === void 0 ? {} : _ref15$query,
      _ref15$config = _ref15.config,
      config = _ref15$config === void 0 ? {
    loading: false
  } : _ref15$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/tiantu/orderChangeDelete'
  }, config));
}; // 10、撤回订单变更


exports.orderChangeDelete = orderChangeDelete;

var orderChangeRecall = function orderChangeRecall() {
  var _ref16 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref16$params = _ref16.params,
      params = _ref16$params === void 0 ? {} : _ref16$params,
      _ref16$data = _ref16.data,
      data = _ref16$data === void 0 ? {} : _ref16$data,
      _ref16$query = _ref16.query,
      query = _ref16$query === void 0 ? {} : _ref16$query,
      _ref16$config = _ref16.config,
      config = _ref16$config === void 0 ? {
    loading: false
  } : _ref16$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/tiantu/orderChangeRecall'
  }, config));
}; // 11、变更原因


exports.orderChangeRecall = orderChangeRecall;

var orderChangeReason = function orderChangeReason() {
  var _ref17 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref17$params = _ref17.params,
      params = _ref17$params === void 0 ? {} : _ref17$params,
      _ref17$query = _ref17.query,
      query = _ref17$query === void 0 ? {} : _ref17$query,
      _ref17$config = _ref17.config,
      config = _ref17$config === void 0 ? {
    loading: false
  } : _ref17$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/orderChangeReason'
  }, config));
}; // 12、日志列表


exports.orderChangeReason = orderChangeReason;

var orderChangeLogList = function orderChangeLogList() {
  var _ref18 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref18$params = _ref18.params,
      params = _ref18$params === void 0 ? {} : _ref18$params,
      _ref18$query = _ref18.query,
      query = _ref18$query === void 0 ? {} : _ref18$query,
      _ref18$config = _ref18.config,
      config = _ref18$config === void 0 ? {
    loading: false
  } : _ref18$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/orderChangeLogList'
  }, config));
}; // 13、订单变更日志详情


exports.orderChangeLogList = orderChangeLogList;

var orderChangeLogDetail = function orderChangeLogDetail() {
  var _ref19 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref19$params = _ref19.params,
      params = _ref19$params === void 0 ? {} : _ref19$params,
      _ref19$query = _ref19.query,
      query = _ref19$query === void 0 ? {} : _ref19$query,
      _ref19$config = _ref19.config,
      config = _ref19$config === void 0 ? {
    loading: false
  } : _ref19$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/orderChangeLogDetail'
  }, config));
}; // 14、废弃订单


exports.orderChangeLogDetail = orderChangeLogDetail;

var abandonOrderChange = function abandonOrderChange() {
  var _ref20 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref20$params = _ref20.params,
      params = _ref20$params === void 0 ? {} : _ref20$params,
      _ref20$data = _ref20.data,
      data = _ref20$data === void 0 ? {} : _ref20$data,
      _ref20$query = _ref20.query,
      query = _ref20$query === void 0 ? {} : _ref20$query,
      _ref20$config = _ref20.config,
      config = _ref20$config === void 0 ? {
    loading: false
  } : _ref20$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/tiantu/abandonOrderChange'
  }, config));
}; // 15、订单变更提交接口


exports.abandonOrderChange = abandonOrderChange;

var orderChangeSubmit = function orderChangeSubmit() {
  var _ref21 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref21$params = _ref21.params,
      params = _ref21$params === void 0 ? {} : _ref21$params,
      _ref21$data = _ref21.data,
      data = _ref21$data === void 0 ? {} : _ref21$data,
      _ref21$query = _ref21.query,
      query = _ref21$query === void 0 ? {} : _ref21$query,
      _ref21$config = _ref21.config,
      config = _ref21$config === void 0 ? {
    loading: false
  } : _ref21$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    data: data,
    method: 'post',
    url: url + '/deliver/tiantu/orderChangeSubmit'
  }, config));
}; // 16、订单变更检测是否符合申请


exports.orderChangeSubmit = orderChangeSubmit;

var getCheckOrderInfo = function getCheckOrderInfo() {
  var _ref22 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref22$params = _ref22.params,
      params = _ref22$params === void 0 ? {} : _ref22$params,
      _ref22$query = _ref22.query,
      query = _ref22$query === void 0 ? {} : _ref22$query,
      _ref22$config = _ref22.config,
      config = _ref22$config === void 0 ? {
    loading: false
  } : _ref22$config;

  return _vue["default"].ajax.request({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/getCheckOrderInfo'
  });
}; // 文件上传


exports.getCheckOrderInfo = getCheckOrderInfo;

var uploadUrl = function uploadUrl() {
  var _ref23 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref23$params = _ref23.params,
      params = _ref23$params === void 0 ? {} : _ref23$params,
      _ref23$query = _ref23.query,
      query = _ref23$query === void 0 ? {} : _ref23$query,
      _ref23$config = _ref23.config,
      config = _ref23$config === void 0 ? {
    loading: false
  } : _ref23$config;

  return url + '/deliver/tiantu/fileUpload';
}; // 导出BCD表格


exports.uploadUrl = uploadUrl;

var downloadEExcel = function downloadEExcel() {
  var _ref24 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref24$params = _ref24.params,
      params = _ref24$params === void 0 ? {} : _ref24$params,
      _ref24$query = _ref24.query,
      query = _ref24$query === void 0 ? {} : _ref24$query,
      _ref24$config = _ref24.config,
      config = _ref24$config === void 0 ? {
    loading: false
  } : _ref24$config;

  return url + '/deliver/order/download_order';
}; // 获取E类订单


exports.downloadEExcel = downloadEExcel;

var getOrderListE = function getOrderListE() {
  var _ref25 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref25$params = _ref25.params,
      params = _ref25$params === void 0 ? {} : _ref25$params,
      _ref25$query = _ref25.query,
      query = _ref25$query === void 0 ? {} : _ref25$query,
      _ref25$config = _ref25.config,
      config = _ref25$config === void 0 ? {
    loading: false
  } : _ref25$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/order/order_list?type=E'
  }, config));
}; // 审批人变更---获取信息


exports.getOrderListE = getOrderListE;

var queryInfos = function queryInfos() {
  var _ref26 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref26$query = _ref26.query,
      query = _ref26$query === void 0 ? {} : _ref26$query,
      _ref26$config = _ref26.config,
      config = _ref26$config === void 0 ? {
    loading: false
  } : _ref26$config;

  return _vue["default"].ajax.request(_objectSpread({
    params: query,
    method: 'get',
    url: url + '/deliver/tiantu/getApprovalPerson'
  }, config));
}; // 审批人变更---修改信息


exports.queryInfos = queryInfos;

var actionInfos = function actionInfos() {
  var _ref27 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref27$data = _ref27.data,
      data = _ref27$data === void 0 ? {} : _ref27$data,
      _ref27$config = _ref27.config,
      config = _ref27$config === void 0 ? {
    loading: false
  } : _ref27$config;

  return _vue["default"].ajax.request(_objectSpread({
    method: 'post',
    data: data,
    url: url + '/deliver/tiantu/updateApprovalPerson'
  }, config));
};

exports.actionInfos = actionInfos;